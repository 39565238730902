import * as SE from "redux-saga/effects";
import { ApplicationActionType } from "../applicationState/actions";
import { OpeningHoursRuleResponse, toOpeningHoursRule } from "common";

export const makeFetchOpeningHours = (fetchOpeningHours: () => Promise<Array<OpeningHoursRuleResponse>>) => {
	return function* worker() {
		while (true) {
			try {
				yield SE.take(ApplicationActionType.OPENING_HOURS_FETCH);
				const response: Array<OpeningHoursRuleResponse> = yield SE.call(fetchOpeningHours);
				const openingHours = response.map(toOpeningHoursRule);
				yield SE.put({
					type: ApplicationActionType.OPENING_HOURS_SET_MANY,
					openingHours: openingHours,
				});
			} catch (error) {
				yield SE.put({
					type: ApplicationActionType.OPENING_HOURS_SET_ERROR,
					error: error,
				});
			}
		}
	};
};
