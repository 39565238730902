import * as SE from "redux-saga/effects";
import { ApplicationActionType, BrowserSaveToLocalStorage, BrowserScrollToElement } from "../applicationState/actions";

export const makeClearLocalStorageWorker = (clearLocalStorage: () => void) => {
	return function* worker() {
		while (true) {
			yield SE.take(ApplicationActionType.BROWSER_CLEAR_LOCAL_STORAGE);
			clearLocalStorage();
		}
	};
};

export const makeSaveToLocalStorageWorker = (saveToLocalStorage: (value: string) => void) => {
	return function* worker() {
		while (true) {
			const action: BrowserSaveToLocalStorage = yield SE.take(
				ApplicationActionType.BROWSER_SAVE_TO_LOCAL_STORAGE
			);
			saveToLocalStorage(action.value);
		}
	};
};

export const makeScrollToTop = (scrollToTop: () => void) => {
	return function* worker() {
		while (true) {
			yield SE.take(ApplicationActionType.BROWSER_SCROLL_TO_TOP);
			scrollToTop();
		}
	};
};

export const makeScrollToElement = (scrollToElement: (elementId: string) => void) => {
	return function* worker() {
		while (true) {
			const actions: BrowserScrollToElement = yield SE.take(ApplicationActionType.BROWSER_SCROLL_TO_ELEMENT);
			scrollToElement(actions.elementId);
		}
	};
};
