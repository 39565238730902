import * as React from "react";

export interface BooleanState {
	isTrue: boolean;
	isFalse: boolean;
	toggle: () => void;
	setTrue: () => void;
	setFalse: () => void;
}
export const useBooleanState = (initialState?: boolean): BooleanState => {
	const [isTrue, setState] = React.useState<boolean>(initialState || false);
	return {
		isTrue: isTrue,
		isFalse: !isTrue,
		toggle: () => setState((prev) => !prev),
		setTrue: () => setState(true),
		setFalse: () => setState(false),
	};
};
