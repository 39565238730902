import * as React from "react";
import { TextInputProps, WithClassNames } from "../models";

type Props = TextInputProps & WithClassNames & { pattern?: string };
export const TextInput: React.FC<Props> = (props) => {
	return (
		<input
			type="text"
			pattern={props.pattern}
			className={props.className}
			value={props.value}
			placeholder={props.placeholder}
			onChange={(e) => props.onChange(e.target.value)}
		/>
	);
};
