import * as common from "common";
import React from "react";
import {
	CommonPropsType,
	DatePickerProps,
	OpeningHoursRule,
	OpeningHoursType,
	Button,
	DisabledButton,
	Icon,
	makeToDatePickerProps,
	openingHoursForDate,
	DateWithoutTime,
} from "common";

interface Props {
	value?: Date;
	offsetDays: number;
	openingHoursRules: Array<OpeningHoursRule>;
	onChange: (date: Date) => void;
}

export const DatePicker: React.FC<Props> = (props) => {
	const [state, setState] = React.useState<Date>(common.DateWithoutTime.now());

	React.useEffect(() => {
		if (!!props.value) {
			setState(props.value);
		}
		// eslint-disable-next-line
	}, []);

	const viewProps = makeToDatePickerProps(
		() => {
			setState(props.value || DateWithoutTime.now());
		},
		() => {
			const currentYear = state.getFullYear();
			const nextYear = currentYear - 1;
			const newDate = DateWithoutTime.toWithoutTime(state);
			newDate.setFullYear(nextYear);
			setState(newDate);
		},
		() => {
			const currentYear = state.getFullYear();
			const nextYear = currentYear + 1;
			const newDate = DateWithoutTime.toWithoutTime(state);
			newDate.setFullYear(nextYear);
			setState(newDate);
		},
		() => {
			const currentMonth = state.getMonth();
			const nextMonth = currentMonth - 1;
			const newDate = DateWithoutTime.toWithoutTime(state);
			newDate.setMonth(nextMonth);
			setState(newDate);
		},
		() => {
			const currentMonth = state.getMonth();
			const nextMonth = currentMonth + 1;
			const newDate = DateWithoutTime.toWithoutTime(state);
			newDate.setMonth(nextMonth);
			setState(newDate);
		},
		(date) => {
			props.onChange(date);
		},
		(date) => {
			const today = DateWithoutTime.now();
			props.offsetDays > 0 && today.setDate(today.getDate() + props.offsetDays);
			const openingHours = openingHoursForDate({ date, rules: props.openingHoursRules });
			if (date <= today) {
				return true;
			} else if (openingHours === undefined) {
				return false;
			} else {
				return openingHours.type === OpeningHoursType.CLOSED;
			}
		}
	)({
		selected: props.value,
		current: state,
	});
	return <DatePickerInput {...viewProps} />;
};

export const DatePickerInput: React.FC<DatePickerProps> = (props) => {
	return (
		<div className="space-y-2">
			<div className="grid grid-cols-3">
				<div className="justify-self-start px-2">
					<Button {...props.year.prev} className="btn-2 btn-sm">
						{props.year.prev.icon && <Icon type={props.year.prev.icon} className="w-5 h-5" />}
						<span className="sr-only">{props.year.prev.title}</span>
					</Button>
				</div>
				<Button {...props.year.current} className="justify-self-center btn-2 btn-md">
					{props.year.current.title}
				</Button>
				<div className="justify-self-end px-2">
					<Button {...props.year.next} className="btn-2 btn-sm">
						{props.year.next.icon && <Icon type={props.year.next.icon} className="w-5 h-5" />}
						<span className="sr-only">{props.year.next.title}</span>
					</Button>
				</div>
			</div>
			<div className="grid grid-cols-3">
				<div className="justify-self-start px-2">
					<Button {...props.month.prev} className="btn-2 btn-sm">
						{props.month.prev.icon && <Icon type={props.month.prev.icon} className="w-5 h-5" />}
						<span className="sr-only">{props.month.prev.title}</span>
					</Button>
				</div>
				<span className="justify-self-center text-2">{props.month.current}</span>
				<div className="justify-self-end px-2">
					<Button {...props.month.next} className="btn-2 btn-sm">
						{props.month.next.icon && <Icon type={props.month.next.icon} className="w-5 h-5" />}
						<span className="sr-only">{props.month.next.title}</span>
					</Button>
				</div>
			</div>
			<ul className="grid grid-cols-7 gap-1">
				{props.weekdays.map((weekday, index) => {
					return (
						<span key={index} className="justify-self-center text-2">
							{weekday}
						</span>
					);
				})}
				<div className="col-span-7 border-b border-gray-400 dark:border-gray-600" />
				{props.days.map((buttonProps, index) => {
					switch (buttonProps.type) {
						case CommonPropsType.BUTTON:
							if (buttonProps.isSelected) {
								return (
									<div key={index} className="flex items-center justify-center">
										<Button
											{...buttonProps}
											className="w-8 h-8 rounded-md py-1 px-2 text-sm text-white dark:text-gray-100 bg-green-500 hover:text-white dark:hover:text-gray-100 hover:bg-green-600"
										>
											{buttonProps.title}
										</Button>
									</div>
								);
							} else {
								return (
									<div key={index} className="flex items-center justify-center">
										<Button
											{...buttonProps}
											className="w-8 h-8 rounded-md py-1 px-2 text-sm text-1 hover:text-white dark:hover:text-gray-100 hover:bg-green-500"
										>
											{buttonProps.title}
										</Button>
									</div>
								);
							}
						case CommonPropsType.DISABLED_BUTTON:
							return (
								<DisabledButton
									key={index}
									{...buttonProps}
									className="text-3 text-sm cursor-not-allowed"
								>
									{buttonProps.title}
								</DisabledButton>
							);
						default:
							return null;
					}
				})}
			</ul>
		</div>
	);
};
