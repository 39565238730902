import { OpeningHoursRule, OpeningHoursRuleType } from "../domain";

export interface OpeningHoursRuleResponse {
	id: number;
	fields: {
		type: number;
		/**
        monthsOfTheYear:
        JANUARY = 1,
        FEBRUARY = 2,
        MARCH = 3,
        APRIL = 4,
        MAY = 5,
        JUNE = 6,
        JULY = 7,
        AUGUST = 8,
        SEPTEMBER = 9,
        OCTOBER = 10,
        NOVEMBER = 11,
        DECEMBER = 12,
        */
		monthsOfTheYear?: Array<number>;
		dayOfTheMonths?: number;
		/**
        daysOfTheWeek:
        MONDAY = 1,
        TUESDAY = 2,
        WEDNESDAY = 3,
        THURSDAY = 4,
        FRIDAY = 5,
        SATURDAY = 6,
        SUNDAY = 7,
         */
		daysOfTheWeek?: Array<number>;
		date?: string;
		isClosed: boolean;
		message?: string;
		from?: string;
		to?: string;
		shopId: number;
	};
}

export type ToOpeningHoursRule = (response: OpeningHoursRuleResponse) => OpeningHoursRule | undefined;
export const toOpeningHoursRule: ToOpeningHoursRule = (response) => {
	if (response.fields.type === 1 /*DATE*/ && response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.CLOSED_DATE,
			date: response.fields.date,
			message: response.fields.message,
		};
	} else if (response.fields.type === 1 /*DATE*/ && !response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.DATE,
			date: response.fields.date,
			from: response.fields.from,
			to: response.fields.to,
			message: response.fields.message,
		};
	} else if (response.fields.type === 2 /*DAYS_OF_THE_WEEK*/ && response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK,
			days: response.fields.daysOfTheWeek,
			message: response.fields.message,
		};
	} else if (response.fields.type === 2 /*DAYS_OF_THE_WEEK*/ && !response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.DAYS_OF_THE_WEEK,
			days: response.fields.daysOfTheWeek,
			from: response.fields.from,
			to: response.fields.to,
			message: response.fields.message,
		};
	} else if (response.fields.type === 3 /*DAY_OF_THE_MONTHS*/ && !response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.DAY_OF_THE_MONTHS,
			months: response.fields.monthsOfTheYear,
			day: response.fields.dayOfTheMonths,
			from: response.fields.from,
			to: response.fields.to,
			message: response.fields.message,
		};
	} else if (response.fields.type === 3 /*DAY_OF_THE_MONTHS*/ && response.fields.isClosed) {
		return {
			type: OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS,
			months: response.fields.monthsOfTheYear,
			day: response.fields.dayOfTheMonths,
			message: response.fields.message,
		};
	} else {
		return undefined;
	}
};
