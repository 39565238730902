import * as SE from "redux-saga/effects";
import * as common from "common";
import { EmailSend, ApplicationActionType } from "../applicationState/actions";

export const makeSendEmailWorker = (sendEmail: (email: common.Email) => Promise<boolean>) => {
	return function* worker() {
		while (true) {
			const action: EmailSend = yield SE.take(ApplicationActionType.EMAIL_SEND);
			sendEmail(action.email);
		}
	};
};
