export * from "./models";
export * as fetchApi from "./fetchApi";
export * as ninoxApi from "./ninoxApi";
export * as vonLuckApi from "./vonLuckApi/index"; // important, we got module not found errors in online-shop
export * as sendGridApi from "./sendGridApi";

export const toJSON = <T>(value: string): T | undefined => {
	try {
		return JSON.parse(value);
	} catch (_) {
		return undefined;
	}
};
