interface Request {
	header: string;
	name: string;
	pickUpId: string;
	date: string;
	time: string;
	location: string;
	orderDetails: string;
	footer: string;
}
export const customerOrderConformationText = (req: Request): string => `
${req.header}

Sehr geehrte/r ${req.name},

Ihre Bestellung ist bei uns eingetroffen.
Wir prüfen nun, ob Ihre Bestellung von uns bedient werden kann.
Falls es Probleme geben sollte, werden wir uns bei Ihnen melden.

===============================
Abholnummer(kurz): ${req.pickUpId.slice(0, 4)}
Abholnummer(lang): ${req.pickUpId}
-------------------------------
${req.date} ${req.time} im Standort in ${req.location}
===============================

Falls Sie mit PayPal bezahlen wollen, werden wir Sie kontaktieren.
Im folgenden finden Sie nun die Details zu Ihrer Bestellung.

Mit freundlichen Grüßen,
Das vonLuck-Team

${req.orderDetails}
${req.footer}
`;
