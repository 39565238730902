import React from "react";
import { ProductProps, ProductsProps } from "../props/checkoutProps";
import { ImageView } from "common";
import { GreenButton, RedButton } from "../../../components/buttons";
import { AmountInput } from "../../../components/amountInput";

export const ProductsList: React.FC<ProductsProps> = (props) => (
	<section className="bg-white dark:bg-gray-900 shadow-lg rounded-lg text-1 divide-y divide-gray-200 dark:divide-gray-600">
		{props.products.map((productProps, index) => {
			if (productProps.amount === "0") {
				return <DisabledProductView key={index} {...productProps} index={index + 1} />;
			} else {
				return <ProductView key={index} {...productProps} index={index + 1} />;
			}
		})}
	</section>
);
const ProductView: React.FC<ProductProps & { index: number }> = (props) => (
	<div className="products-grid px-2 md:px-4 py-4">
		<span className="text-3">{props.index}.</span>
		<span className="">
			<ImageView {...props.image} className="w-12 h-12 md:w-24 md:h-24 rounded-md object-cover" />
		</span>
		<span className="w-8 text-right">{props.amount}x</span>
		<span className="col-span-2 md:col-span-1 space-y-2 overflow-hidden">
			<span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap">{props.title}</span>
			<span className="hidden md:block text-2 text-sm">{props.description}</span>
			<span className="hidden md:block text-3 text-xs">{props.ingredients}</span>
		</span>
		<div className="hidden md:block flex items-start justify-center">
			<GreenButton {...props.addOne} />
		</div>
		<div className="col-span-3 md:col-span-1 flex items-start justify-center">
			<div className="w-12 h-max">
				<AmountInput {...props.setAmount} />
			</div>
		</div>
		<div className="hidden md:block flex items-start justify-center">
			<RedButton {...props.reduceOne} />
		</div>
		<div className="hidden md:block py-1 flex items-start justify-center">{props.price}</div>
		<div className="flex items-start justify-center">
			<RedButton {...props.removeAll} />
		</div>
		<span className="col-start-2 col-span-6 inline md:hidden space-y-2">
			<span className="block text-2 text-xs md:text-sm">{props.description}</span>
			<span className="block text-3 text-xs">{props.ingredients}</span>
		</span>
		<div className="block md:hidden col-start-8 col-span-2 flex justify-start">
			<Right>{props.price}</Right>
		</div>
	</div>
);
const DisabledProductView: React.FC<ProductProps & { index: number }> = (props) => (
	<div className="products-grid px-2 md:px-4 py-4">
		<DisabledWrapper>
			<span className="text-3">{props.index}.</span>
		</DisabledWrapper>
		<DisabledWrapper>
			<span className="">
				<ImageView {...props.image} className="w-12 h-12 md:w-24 md:h-24 rounded-md object-cover" />
			</span>
		</DisabledWrapper>
		<DisabledWrapper>
			<span className="w-8 text-right">{props.amount}x</span>
		</DisabledWrapper>
		<DisabledWrapper>
			<span className="col-span-2 md:col-span-1 space-y-2 overflow-hidden">
				<span className="w-full block overflow-hidden text-ellipsis whitespace-nowrap">{props.title}</span>
				<span className="hidden md:block text-2 text-sm">{props.description}</span>
				<span className="hidden md:block text-3 text-xs">{props.ingredients}</span>
			</span>
		</DisabledWrapper>
		<div className="hidden md:block flex items-start justify-center">
			<GreenButton {...props.addOne} />
		</div>
		<div className="col-span-3 md:col-span-1 flex items-start justify-center">
			<div className="w-12 h-max">
				<AmountInput {...props.setAmount} />
			</div>
		</div>

		<div className="hidden md:block flex items-start justify-center">
			<DisabledWrapper noPointerEvents>
				<RedButton {...props.reduceOne} />
			</DisabledWrapper>
		</div>
		<div className="hidden md:block py-1 flex items-start justify-center">
			<DisabledWrapper>{props.price}</DisabledWrapper>
		</div>
		<div className="flex items-start justify-center">
			<RedButton {...props.removeAll} />
		</div>
		<span className="col-start-2 col-span-6 inline md:hidden space-y-2">
			<DisabledWrapper>
				<span className="block text-2 text-xs md:text-sm">{props.description}</span>
			</DisabledWrapper>
			<DisabledWrapper>
				<span className="block text-3 text-xs">{props.ingredients}</span>
			</DisabledWrapper>
		</span>
		<div className="block md:hidden col-start-8 col-span-2 flex justify-start">
			<DisabledWrapper>
				<Right>{props.price}</Right>
			</DisabledWrapper>
		</div>
	</div>
);
const DisabledWrapper: React.FC<{ noPointerEvents?: boolean }> = (props) => (
	<div className="opacity-50 cursor-not-allowed">
		{!!props.noPointerEvents ? <div className="pointer-events-none">{props.children}</div> : <>{props.children}</>}
	</div>
);
const Right: React.FC = (props) => <div className="flex items-center justify-end">{props.children}</div>;
