import * as React from "react";
import { IconProps } from "../../models/domain";

export default function Visa(props: IconProps) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 50 504 400">
			<polygon style={{ fill: "#3C58BF" }} points="184.8,324.4 210.4,180.4 250.4,180.4 225.6,324.4 " />
			<polygon style={{ fill: "#293688" }} points="184.8,324.4 217.6,180.4 250.4,180.4 225.6,324.4 " />
			<path
				style={{ fill: "#3C58BF" }}
				d="M370.4,182c-8-3.2-20.8-6.4-36.8-6.4c-40,0-68.8,20-68.8,48.8c0,21.6,20,32.8,36,40
	s20.8,12,20.8,18.4c0,9.6-12.8,14.4-24,14.4c-16,0-24.8-2.4-38.4-8l-5.6-2.4l-5.6,32.8c9.6,4,27.2,8,45.6,8
	c42.4,0,70.4-20,70.4-50.4c0-16.8-10.4-29.6-34.4-40c-14.4-7.2-23.2-11.2-23.2-18.4c0-6.4,7.2-12.8,23.2-12.8
	c13.6,0,23.2,2.4,30.4,5.6l4,1.6L370.4,182L370.4,182z"
			/>
			<path
				style={{ fill: "#293688" }}
				d="M370.4,182c-8-3.2-20.8-6.4-36.8-6.4c-40,0-61.6,20-61.6,48.8c0,21.6,12.8,32.8,28.8,40
	s20.8,12,20.8,18.4c0,9.6-12.8,14.4-24,14.4c-16,0-24.8-2.4-38.4-8l-5.6-2.4l-5.6,32.8c9.6,4,27.2,8,45.6,8
	c42.4,0,70.4-20,70.4-50.4c0-16.8-10.4-29.6-34.4-40c-14.4-7.2-23.2-11.2-23.2-18.4c0-6.4,7.2-12.8,23.2-12.8
	c13.6,0,23.2,2.4,30.4,5.6l4,1.6L370.4,182L370.4,182z"
			/>
			<path
				style={{ fill: "#3C58BF" }}
				d="M439.2,180.4c-9.6,0-16.8,0.8-20.8,10.4l-60,133.6h43.2l8-24h51.2l4.8,24H504l-33.6-144H439.2z
	 M420.8,276.4c2.4-7.2,16-42.4,16-42.4s3.2-8.8,5.6-14.4l2.4,13.6c0,0,8,36,9.6,44h-33.6V276.4z"
			/>
			<path
				style={{ fill: "#293688" }}
				d="M448.8,180.4c-9.6,0-16.8,0.8-20.8,10.4l-69.6,133.6h43.2l8-24h51.2l4.8,24H504l-33.6-144H448.8z
	 M420.8,276.4c3.2-8,16-42.4,16-42.4s3.2-8.8,5.6-14.4l2.4,13.6c0,0,8,36,9.6,44h-33.6V276.4z"
			/>
			<path
				style={{ fill: "#3C58BF" }}
				d="M111.2,281.2l-4-20.8c-7.2-24-30.4-50.4-56-63.2l36,128h43.2l64.8-144H152L111.2,281.2z"
			/>
			<path
				style={{ fill: "#293688" }}
				d="M111.2,281.2l-4-20.8c-7.2-24-30.4-50.4-56-63.2l36,128h43.2l64.8-144H160L111.2,281.2z"
			/>
			<path
				style={{ fill: "#FFBC00" }}
				d="M0,180.4l7.2,1.6c51.2,12,86.4,42.4,100,78.4l-14.4-68c-2.4-9.6-9.6-12-18.4-12H0z"
			/>
			<path
				style={{ fill: "#F7981D" }}
				d="M0,180.4L0,180.4c51.2,12,93.6,43.2,107.2,79.2l-13.6-56.8c-2.4-9.6-10.4-15.2-19.2-15.2L0,180.4z"
			/>
			<path
				style={{ fill: "#ED7C00" }}
				d="M0,180.4L0,180.4c51.2,12,93.6,43.2,107.2,79.2l-9.6-31.2c-2.4-9.6-5.6-19.2-16.8-23.2L0,180.4z"
			/>
			<path
				style={{ fill: "#051244" }}
				d="M151.2,276.4L124,249.2l-12.8,30.4l-3.2-20c-7.2-24-30.4-50.4-56-63.2l36,128h43.2L151.2,276.4z"
			/>
			<polygon style={{ fill: "#051244" }} points="225.6,324.4 191.2,289.2 184.8,324.4 	" />
			<path
				style={{ fill: "#051244" }}
				d="M317.6,274.8L317.6,274.8c3.2,3.2,4.8,5.6,4,8.8c0,9.6-12.8,14.4-24,14.4c-16,0-24.8-2.4-38.4-8
		l-5.6-2.4l-5.6,32.8c9.6,4,27.2,8,45.6,8c25.6,0,46.4-7.2,58.4-20L317.6,274.8z"
			/>
			<path
				style={{ fill: "#051244" }}
				d="M364,324.4h37.6l8-24h51.2l4.8,24H504L490.4,266l-48-46.4l2.4,12.8c0,0,8,36,9.6,44h-33.6
		c3.2-8,16-42.4,16-42.4s3.2-8.8,5.6-14.4"
			/>
		</svg>
	);
}
