import { dictionary, Reducer } from "common";
import { LocationsState, LocationsStateType } from "../state";
import { LocationsAction, ApplicationActionType } from "../../../applicationState/actions";

export const locationsReducer: Reducer<LocationsState, LocationsAction> = (state, action) => {
	switch (action.type) {
		case ApplicationActionType.LOCATIONS_FETCH:
			return {
				type: LocationsStateType.FETCHING,
			};
		case ApplicationActionType.LOCATIONS_SET_MANY:
			return {
				type: LocationsStateType.DATA,
				locations: dictionary.fromKeyValuePairs(
					action.locations.map((location) => {
						return {
							key: location.id,
							value: location,
						};
					})
				),
			};
		case ApplicationActionType.LOCATIONS_SET_ERROR:
			return {
				type: LocationsStateType.ERROR,
				error: action.error,
			};
		default:
			return state;
	}
};
