import { IconType } from "../../icons";
import { DatePickerProps } from "../props/datePickerProps";
import {
	addDays,
	CommonPropsType,
	getAllDatesInRange,
	getFirstDateOfTheMonth,
	getFirstDateOfTheWeek,
	getLastDateOfTheMonth,
	getLastDateOfTheWeek,
} from "../../../models";

export const makeToDatePickerProps =
	(
		goToSelectedDate: () => void,
		prevYear: () => void,
		nextYear: () => void,
		prevMonth: () => void,
		nextMonth: () => void,
		onSelectDate: (date: Date) => void,
		dateIsClosed: (date: Date) => boolean
	) =>
	(state: { selected?: Date; current: Date }): DatePickerProps => {
		const firstDateOfTheMonth = getFirstDateOfTheMonth(state.current);
		const lastDateOfTheMonth = getLastDateOfTheMonth(state.current);
		const firstDate = getFirstDateOfTheWeek(firstDateOfTheMonth);
		const lastDate = getLastDateOfTheWeek(lastDateOfTheMonth);
		const correctedLastDate = lastDate === lastDateOfTheMonth ? addDays(lastDate, 1) : lastDate;
		const allDaysForTheMonth = getAllDatesInRange(firstDate, correctedLastDate);
		return {
			year: {
				prev: {
					type: CommonPropsType.BUTTON,
					icon: IconType.CHEVRON_LEFT,
					title: "Vorheriges Jahr",
					onSelect: prevYear,
				},
				current: {
					type: CommonPropsType.BUTTON,
					title: state.current.toLocaleDateString("de-DE", {
						year: "numeric",
					}),
					onSelect: goToSelectedDate,
				},
				next: {
					type: CommonPropsType.BUTTON,
					icon: IconType.CHEVRON_RIGHT,
					title: "Nächstes Jahr",
					onSelect: nextYear,
				},
			},
			month: {
				prev: {
					type: CommonPropsType.BUTTON,
					icon: IconType.CHEVRON_LEFT,
					title: "Vorherigesr Monat",
					onSelect: prevMonth,
				},
				current: state.current.toLocaleDateString("de-DE", {
					month: "long",
				}),
				next: {
					type: CommonPropsType.BUTTON,
					icon: IconType.CHEVRON_RIGHT,
					title: "Nächster Monat",
					onSelect: nextMonth,
				},
			},
			weekdays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
			days: allDaysForTheMonth.map((date) => {
				if (date < firstDateOfTheMonth || date > lastDateOfTheMonth) {
					return {
						type: CommonPropsType.DISABLED_BUTTON,
						isClosed: false,
						title: date.toLocaleDateString("de-DE", {
							day: "numeric",
						}),
					};
				} else if (dateIsClosed(date)) {
					return {
						type: CommonPropsType.DISABLED_BUTTON,
						isClosed: true,
						title: date.toLocaleDateString("de-DE", {
							day: "numeric",
						}),
					};
				} else {
					return {
						type: CommonPropsType.BUTTON,
						isSelected: state.selected?.toDateString() === date.toDateString(),
						title: date.toLocaleDateString("de-DE", {
							day: "numeric",
						}),
						onSelect: () => {
							onSelectDate(date);
						},
					};
				}
			}),
		};
	};
