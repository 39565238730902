import { EnvironmentVariable } from "../domain";

export interface EnvironmentVariableResponse {
	id: number;
	fields: {
		key: string;
		value: string;
	};
}

export const toEnvironmentVariable = (res: EnvironmentVariableResponse): EnvironmentVariable => ({
	key: res.fields.key,
	value: res.fields.value,
});

export const toEnvironmentVariableResponse = (res: any): EnvironmentVariableResponse | undefined => {
	try {
		return {
			id: res.id,
			fields: {
				key: res.fields.key,
				value: res.fields.value,
			},
		};
	} catch (_) {
		return undefined;
	}
};
