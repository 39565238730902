import { dictionary, Reducer } from "common";
import { EnvironmentVariablesState, EnvironmentVariablesStateType } from "./state";
import { EnvVarsAction, ApplicationActionType } from "../../applicationState/actions";

export const environmentVariablesReducer: Reducer<EnvironmentVariablesState, EnvVarsAction> = (state, action) => {
	switch (action.type) {
		case ApplicationActionType.ENV_VARS_FETCH_ALL:
			return {
				...state,
				type: EnvironmentVariablesStateType.FETCHING,
			};
		case ApplicationActionType.ENV_VARS_SET_MANY:
			return {
				type: EnvironmentVariablesStateType.DATA,
				variables: dictionary.fromKeyValuePairs(action.variables),
			};
		default:
			return state;
	}
};
