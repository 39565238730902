import * as SE from "redux-saga/effects";
import { ApplicationActionType, RouterRedirect } from "../applicationState/actions";

export const makeRedirectWorker = (to: (path: string) => void) => {
	return function* worker() {
		while (true) {
			const action: RouterRedirect = yield SE.take(ApplicationActionType.ROUTER_REDIRECT);
			yield SE.call(to, action.path);
			yield SE.put({
				type: ApplicationActionType.BROWSER_SCROLL_TO_TOP,
			});
		}
	};
};
