export interface Dictionary<T> {
	[key: string]: T | undefined;
}

export interface KeyValuePair<T> {
	key: string;
	value: T;
}

export type Length = <T>(d: Dictionary<T>) => number;
export const length: Length = (d) => Object.keys(d).length;

export type ToKeyValuePairs = <T>(d: Dictionary<T>) => Array<KeyValuePair<T>>;
export const toKeyValuePairs: ToKeyValuePairs = (d) => Object.entries(d).map(([key, value]) => ({ key, value }));

export type FromKeyValuePairs = <T>(a: Array<KeyValuePair<T>>) => Dictionary<T>;
export const fromKeyValuePairs: FromKeyValuePairs = (a) => {
	return Object.fromEntries(a.map(({ key, value }) => [key, value]));
};

export type ToValues = <T>(d: Dictionary<T>) => Array<T>;
export const toValues: ToValues = (d) => Object.values(d);

export type ToKeys = (d: Dictionary<any>) => Array<string>;
export const toKeys: ToKeys = (d) => Object.keys(d);

export type FromValues = <T>(toKeyValuePair: (value: T) => KeyValuePair<T>) => (values: Array<T>) => Dictionary<T>;
export const fromValues: FromValues = (toKeyValuePair) => (values) => {
	return values.map(toKeyValuePair).reduce((products, keyValuePair) => {
		return {
			...products,
			[keyValuePair.key]: keyValuePair.value,
		};
	}, {});
};

export type RemoveKeyValuePair = (key: string) => <T>(d: Dictionary<T>) => Dictionary<T>;
export const removeKeyValuePair: RemoveKeyValuePair = (k) => (d) => {
	const values = toKeyValuePairs(d);
	const filtered = values.filter(({ key }) => key !== k);
	return fromKeyValuePairs(filtered);
};
