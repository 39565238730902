import { Product } from "common";

export const filterProductByLocationId =
	(locationId?: string) =>
	(product: Product): boolean => {
		return locationId === undefined ? true : product.shopAvailability.includes(locationId);
	};
export const filterProductBySearchString =
	(searchString: string) =>
	(product: Product): boolean => {
		const allTestStrings = searchString.toLowerCase().split(/\s+/);
		const hasMatchInName = testAgainstMultipleTestStrings(allTestStrings, product.name.toLowerCase());
		const hasMatchInDescription = testAgainstMultipleTestStrings(allTestStrings, product.description.toLowerCase());
		const hasMatchInIngredients = product.ingredients
			? testAgainstMultipleTestStrings(allTestStrings, product.ingredients.toLowerCase())
			: false;
		return hasMatchInName || hasMatchInDescription || hasMatchInIngredients;
	};
const testAgainstMultipleTestStrings = (testStrings: Array<string>, value: string): boolean => {
	return (
		testStrings.map((testString) => value.includes(testString)).filter((isAMatch) => isAMatch).length ===
		testStrings.length
	);
};
