import React from "react";
import * as common from "common";
import { ProductProps } from "../props/productProps";
import { ProductView } from "./productView";
import { IntegrationTestWrapper } from "../../../utils/integrationTests";

export const Products: React.FC<{ products: Array<ProductProps> }> = (props) => {
	return (
		<div className="pb-12 lg:pb-32">
			<div className="space-y-12">
				<ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:gap-y-12 sm:space-y-0 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8">
					{props.products.map((productProps: ProductProps, index) => {
						return (
							<li key={index + 1}>
								<IntegrationTestWrapper
									id={common.integrationTests.onlineShop.PRODUCTS_PAGE_PRODUCT_VIEW}
								>
									<ProductView {...productProps} />
								</IntegrationTestWrapper>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};
