import * as common from "common";
import * as SE from "redux-saga/effects";
import { Channel } from "redux-saga";
import { ApplicationActionType, ProductsListSetSearchString } from "../applicationState/actions";
import { PRODUCTS_LIST_ELEMENT_ID } from "../variables/environment";

export const makeFetchMoreProductsBroker = (channel: Channel<number>) => {
	return function* broker() {
		while (true) {
			yield SE.take(ApplicationActionType.PRODUCTS_FETCH_MORE);
			yield SE.call(channel.put, 0);
		}
	};
};

export const makeFetchMoreProductsWorker = (
	getMoreProducts: (page: number) => Promise<Array<common.ProductResponse>>,
	channel: Channel<number>
) => {
	return function* worker() {
		while (true) {
			try {
				const pageCount = yield SE.take(channel);
				const response = yield SE.call(getMoreProducts, pageCount);
				const products = response.filter(common.filterIncompleteRecords).map(common.toProduct);
				yield SE.put({
					type: ApplicationActionType.PRODUCTS_ADD_MANY,
					products: products,
				});

				if (products.length > 0) {
					channel.put(pageCount + 1);
				}
			} catch (error: any) {
				yield SE.put({
					type: ApplicationActionType.PRODUCTS_SET_ERROR,
					error: error,
				});
			}
		}
	};
};

export const makeScrollOnSearchWorker = () => {
	return function* worker() {
		while (true) {
			const action: ProductsListSetSearchString = yield SE.take(
				ApplicationActionType.PRODUCTS_LIST_SET_SEARCH_STRING
			);
			const isSearching = action.searchString.length > 0;
			if (isSearching) {
				const hasElement = document.getElementById(PRODUCTS_LIST_ELEMENT_ID);
				if (hasElement) {
					yield SE.put({
						type: ApplicationActionType.BROWSER_SCROLL_TO_ELEMENT,
						elementId: PRODUCTS_LIST_ELEMENT_ID,
					});
				} else {
					yield SE.put({
						type: ApplicationActionType.BROWSER_SCROLL_TO_TOP,
					});
				}
			}
		}
	};
};
