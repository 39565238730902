import { Product } from "../domain";

export interface ProductResponse {
	id: number;
	fields: {
		description: string;
		imageURL: string;
		ingredients: string;
		name: string;
		price: number;
		shopAvailability: string; // "1" | "1,2"
		state: string; // "OFFLINE" | "ONLINE"
	};
}

export const filterIncompleteRecords = (res: ProductResponse): boolean => {
	return (
		res.id !== undefined &&
		res.fields.name !== undefined &&
		// && res.fields.imageURL !== undefined
		res.fields.description !== undefined &&
		// && res.fields.ingredients !== undefined
		res.fields.price !== undefined &&
		res.fields.shopAvailability !== undefined
	);
};

export const toProductResponse = (res: any): ProductResponse | undefined => {
	try {
		return {
			id: res.id,
			fields: {
				state: res.fields.state,
				name: res.fields.name,
				imageURL: res.fields.imageURL,
				description: res.fields.description,
				ingredients: res.fields.ingredients,
				price: res.fields.price,
				shopAvailability: res.fields.shopAvailability,
			},
		};
	} catch (_) {
		return undefined;
	}
};

export const toProduct = (res: ProductResponse): Product => {
	return {
		id: String(res.id),
		name: res.fields.name,
		imageURL: res.fields.imageURL,
		description: res.fields.description,
		ingredients: res.fields.ingredients,
		price: normalizePrice(res.fields.price),
		shopAvailability: res.fields.shopAvailability.split(","),
	};
};

export const normalizePrice = (price: number): number => eurosToCents(price);

const SEPARATOR = ".";

const eurosToCents = (euros: number): number => {
	const parts = splitCurrencyIntoParts(euros);
	return parseInt(handleEuros(parts[0]) + handleCents(parts[1] ? parts[1] : ""));
};

const splitCurrencyIntoParts = (currency: number): Array<string> => currency.toString().split(SEPARATOR);

const handleEuros = (euros: string): string => {
	return euros;
};

const handleCents = (cents: string): string => {
	if (cents.length === 0) {
		return "00";
	} else if (cents.length === 1) {
		return cents + "0";
	} else {
		// if (cents.length >= 2)
		return cents.slice(0, 2);
	}
};
