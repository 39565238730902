import * as F from "fp-ts/function";
import * as common from "common";
import { ButtonProps, CommonPropsType, IconType, Location, Product, toPriceString } from "common";
import { Dispatch } from "../../../applicationState";
import { OpeningHoursState, OpeningHoursStateType } from "../../openingHours/state";
import { ApplicationActionType } from "../../../applicationState/actions";
import { CheckoutProps, ProductProps, CheckoutPropsType, OrderProps, SubmitButtonProps } from "../props/checkoutProps";
import { CheckoutState, CheckoutStateType, RequestData, ValidatedRequestData } from "../state";
import { filterProductBySearchString } from "../../../utils/filter";
import { aggregatePrice, aggregateAmount } from "../selectors/selectors";
import { PATH_PRODUCTS, VON_LUCK_HOMEPAGE_PRIVACY_URL } from "../../../variables/urls";

export interface CheckoutViewState {
	allowedPaymentMethods: Array<common.PaymentMethodType>;
	datePickerOffsetDays: number;
	paypalMinBasketValue: number;
	checkout: CheckoutState;
	searchString: string;
	selectedLocation: Location;
	openingHours: OpeningHoursState;
	products: Array<Product & { amount: number }>;
}

export const makeToCheckoutProps =
	(dispatch: Dispatch, validateRequest: (request: RequestData) => ValidatedRequestData | undefined) =>
	(state: CheckoutViewState): CheckoutProps => {
		const toGoBackProps = (): ButtonProps => {
			return {
				type: CommonPropsType.BUTTON,
				icon: IconType.CHEVRON_LEFT,
				title: "Zurück",
				onSelect: () => {
					dispatch({
						type: ApplicationActionType.ROUTER_REDIRECT,
						path: PATH_PRODUCTS,
					});
				},
			};
		};

		const toProduct = (product: Product & { amount: number }): ProductProps => ({
			image: {
				type: CommonPropsType.IMAGE,
				source: product.imageURL,
				title: product.name,
			},
			title: product.name,
			description: product.description,
			ingredients: product.ingredients || "",
			amount: String(product.amount),
			price: toPriceString(product.price * product.amount),
			setAmount: {
				type: CommonPropsType.TEXT_INPUT,
				value: String(product.amount),
				placeholder: "Anzahl",
				onChange: (value) => {
					const valueAsNumber = Number(value);
					const isANumber = !Number.isNaN(valueAsNumber);
					if (isANumber) {
						dispatch({
							type: ApplicationActionType.CHECKOUT_SET_PRODUCT,
							productId: product.id,
							amount: valueAsNumber,
						});
					}
				},
			},
			addOne: {
				type: CommonPropsType.BUTTON,
				title: "1 Hinzufügen",
				icon: IconType.ADD,
				onSelect: () => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_INC_PRODUCT,
						productId: product.id,
					});
				},
			},
			reduceOne: {
				type: CommonPropsType.BUTTON,
				title: "1 Zurücklegen",
				icon: IconType.REMOVE,
				onSelect: () => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_DEC_PRODUCT,
						productId: product.id,
					});
				},
			},
			removeAll: {
				type: CommonPropsType.BUTTON,
				title: "Löschen",
				icon: IconType.TRASH_FILL,
				onSelect: () => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_REMOVE_PRODUCT,
						productId: product.id,
					});
				},
			},
		});

		const toSubmitProps = (state: CheckoutState): SubmitButtonProps => {
			const requestIsValid = validateRequest(state.request) === undefined;
			const requestIsNotValid = !requestIsValid;
			const needsToValidate = state.type === CheckoutStateType.DEFAULT;
			if (requestIsNotValid && needsToValidate) {
				return {
					type: CommonPropsType.BUTTON,
					title: "Bestellung prüfen",
					onSelect: () => {
						dispatch({
							type: ApplicationActionType.CHECKOUT_SET_VALIDATING,
						});
					},
				};
			}
			if (requestIsValid) {
				const buttonProps: ButtonProps = {
					type: CommonPropsType.BUTTON,
					title: "Zahlungspflichtig bestellen",
					onSelect: () => {
						dispatch({
							type: ApplicationActionType.CHECKOUT_SET_SUBMITTING,
						});
					},
				};
				if (state.request.paymentMethod === common.PaymentMethodType.PAYPAL) {
					return {
						type: "PAYPAL_BUTTON_PROPS",
						props: buttonProps,
					};
				} else {
					return buttonProps;
				}
			} else {
				return {
					type: CommonPropsType.DISABLED_BUTTON,
					title: "Zahlungspflichtig bestellen",
				};
			}
		};

		const getTitleForPaymentMethodButton = (method: common.PaymentMethodType): string => {
			switch (method) {
				case common.PaymentMethodType.AT_LOCATION:
					return "Bei Abholung";
				case common.PaymentMethodType.PAYPAL:
					return "Paypal";
			}
		};

		const getIconForPaymentMethodButton = (method: common.PaymentMethodType): IconType | undefined => {
			switch (method) {
				case common.PaymentMethodType.PAYPAL:
					return IconType.PAYPAL;
				default:
					return undefined;
			}
		};

		const toOrderProps = (): OrderProps => {
			const isValidating = state.checkout.type === CheckoutStateType.VALIDATING;
			return {
				title: "Bestellung",
				location: {
					title: "Standort",
					image: {
						type: CommonPropsType.IMAGE,
						source: state.selectedLocation.bannerImage.url,
						title: state.selectedLocation.bannerImage.name,
					},
					locationName: state.selectedLocation.name,
					description: LOCATION_DESCRIPTION,
				},
				name: {
					title: "Name",
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.checkout.request.name || "",
						placeholder: "Astrid",
						validation: isValidating ? validateRequest(state.checkout.request)?.name : undefined,
						onChange: (value) => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_SET_NAME,
								name: value,
							});
						},
					},
					description: NAME_DESCRIPTION,
				},
				email: {
					title: "E-Mail",
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.checkout.request.email || "",
						placeholder: "michel@lönneberga.de",
						validation: isValidating ? validateRequest(state.checkout.request)?.email : undefined,
						onChange: (value) => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_SET_EMAIL,
								email: value,
							});
						},
					},
					description: EMAIL_DESCRIPTION,
				},
				phone: {
					title: "TelefonNr.",
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.checkout.request.phone || "",
						placeholder: "+49(0) ...",
						validation: isValidating ? validateRequest(state.checkout.request)?.phone : undefined,
						onChange: (value) => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_SET_PHONE,
								phone: value,
							});
						},
					},
					description: PHONE_DESCRIPTION,
				},
				date: {
					title: "Abholdatum",
					input: {
						value: state.checkout.request.date,
						offsetDays: state.datePickerOffsetDays,
						openingHoursRules:
							state.openingHours.type === OpeningHoursStateType.DATA
								? state.openingHours.openingHours
								: [],
						onChange: (date) => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_SET_DATE,
								date: new Date(date),
							});
						},
					},
					validation: isValidating ? validateRequest(state.checkout.request)?.date : undefined,
					description: DATE_DESCRIPTION,
				},
				time: {
					title: "Abholzeit",
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.checkout.request.time || "",
						placeholder: "08:00 - 13:00",
						validation: isValidating ? validateRequest(state.checkout.request)?.time : undefined,
						onChange: (value) => {
							if (value === "") {
								return dispatch({
									type: ApplicationActionType.CHECKOUT_SET_TIME,
									time: value,
								});
							}
							const isValidValue = /^[\d:]*$/.test(value);
							if (value === "" || isValidValue) {
								return dispatch({
									type: ApplicationActionType.CHECKOUT_SET_TIME,
									time: value,
								});
							}
						},
					},
					description: TIME_DESCRIPTION,
				},
				message: {
					title: "Nachricht",
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.checkout.request.message || "",
						placeholder: MESSAGE_DESCRIPTION,
						onChange: (value) => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_SET_MESSAGE,
								message: value,
							});
						},
					},
					description: MESSAGE_DESCRIPTION,
				},
				paymentMethod: {
					title: "Bezahlmethode",
					description: PAYMENT_METHOD_DESCRIPTION(common.toPriceString(state.paypalMinBasketValue)),
					validation: isValidating ? validateRequest(state.checkout.request)?.paymentMethod : undefined,
					options: state.allowedPaymentMethods.map((method) => {
						return {
							type: CommonPropsType.BUTTON,
							icon: getIconForPaymentMethodButton(method),
							title: getTitleForPaymentMethodButton(method),
							isSelected: state.checkout.request.paymentMethod === method,
							onSelect: () => {
								dispatch({
									type: ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD,
									method: method,
								});
							},
						};
					}),
				},
				privacy: {
					check: {
						type: CommonPropsType.BOOLEAN_INPUT,
						value: state.checkout.request.privacyCheck || false,
						placeholder: "",
						validation: isValidating ? validateRequest(state.checkout.request)?.privacyCheck : undefined,
						onToggle: () => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_TOGGLE_PRIVACY,
							});
						},
						onSetTrue: () => {},
						onSetFalse: () => {},
					},
					message: PRIVACY_MESSAGE,
					toPrivacyPage: {
						type: CommonPropsType.LINK,
						title: PRIVACY_LINK_TITLE,
						link: VON_LUCK_HOMEPAGE_PRIVACY_URL,
					},
				},
			};
		};

		interface BaseProps {
			goBack: ButtonProps;
			order: OrderProps;
			submit: SubmitButtonProps;
		}
		const baseProps: BaseProps = {
			goBack: toGoBackProps(),
			order: toOrderProps(),
			submit: toSubmitProps(state.checkout),
		};

		const isSearching = state.searchString.length > 0;
		switch (state.checkout.type) {
			case CheckoutStateType.DEFAULT:
			case CheckoutStateType.VALIDATING:
			case CheckoutStateType.SUBMITTING_ERROR: {
				const hasNoProductsInState = state.products.length === 0;
				if (hasNoProductsInState) {
					return {
						type: CheckoutPropsType.SKELETON,
						...baseProps,
						products: {
							title: "Warenkorb",
							skeleton: {
								title: "Kein Produkte Im Warenkorb",
								message: NO_PRODUCTS_IN_BASKET,
							},
						},
					};
				}
				const productsFilteredBySearchString = state.products.filter(
					filterProductBySearchString(state.searchString)
				);
				const hasNoProductsForSearchString = productsFilteredBySearchString.length === 0;
				if (hasNoProductsForSearchString) {
					return {
						type: CheckoutPropsType.SKELETON,
						...baseProps,
						products: {
							title: "Warenkorb",
							skeleton: {
								title: "Keine Ergebnisse Für Suchanfrage",
								message: NO_PRODUCT_FOUND_FOR_SEARCH_STRING,
							},
						},
					};
				}
				const productsBaseProps = {
					title: "Warenkorb",
					products: productsFilteredBySearchString.map(toProduct),
					searchAmount: isSearching
						? {
								title: 'Anzahl aller "gesuchten" Produkte im Warenkorb',
								amount: String(aggregateAmount(productsFilteredBySearchString)),
						  }
						: undefined,
					searchSum: isSearching
						? {
								title: 'Zwischensumme aller "gesuchten" Produkte im Warenkorb inkl. MwSt.',
								price: F.pipe(productsFilteredBySearchString, aggregatePrice, toPriceString),
						  }
						: undefined,
					amount: {
						title: "Anzahl aller Produkte im Warenkorb",
						amount: String(aggregateAmount(state.products)),
					},
					sum: {
						title: "Gesamtsumme inkl. MwSt.",
						price: F.pipe(state.products, aggregatePrice, toPriceString),
					},
				};
				if (state.checkout.type === CheckoutStateType.SUBMITTING_ERROR) {
					return {
						type: CheckoutPropsType.ERROR,
						...baseProps,
						products: productsBaseProps,
						errorMessage: SUBMITTING_ERROR_MESSAGE,
					};
				}
				return {
					type: CheckoutPropsType.DEFAULT,
					...baseProps,
					products: productsBaseProps,
				};
			}
			case CheckoutStateType.FETCHING:
				return {
					type: CheckoutPropsType.LOADING,
				};
			case CheckoutStateType.SUBMITTING:
				return {
					type: CheckoutPropsType.SUBMITTING,
				};
			case CheckoutStateType.FINISHED:
				return {
					type: CheckoutPropsType.FINISHED,
					goBack: toGoBackProps(),
					title: CHECKOUT_FINISHED_TITLE,
					message: CHECKOUT_FINISHED_MESSAGE,
					continueShopping: {
						type: CommonPropsType.BUTTON,
						title: "Weiter einkaufen",
						onSelect: () => {
							dispatch({
								type: ApplicationActionType.CHECKOUT_RESET,
							});
							dispatch({
								type: ApplicationActionType.ROUTER_REDIRECT,
								path: PATH_PRODUCTS,
							});
						},
					},
					localStorageWarning: {
						message: LOCAL_STORAGE_MESSAGE,
						clear: {
							type: CommonPropsType.BUTTON,
							icon: IconType.TRASH_FILL,
							title: "Daten Löschen",
							onSelect: () => {
								dispatch({
									type: ApplicationActionType.BROWSER_CLEAR_LOCAL_STORAGE,
								});
							},
						},
					},
				};
		}
	};

export const LOCATION_DESCRIPTION =
	"Der Abholort wurde bereits von Ihnen gesetzt. Wenn Sie den Abholort ändern wollen, dann müssen Sie zurück zur Produktübersicht. Bitte beachten Sie, dass wir in jedem unserer Standorte ein leicht unterschiedliches Sortiment haben. Sobald Sie den Abholort ändern, verlieren Sie ihren jetzigen Warenkorb.";
export const NAME_DESCRIPTION =
	"Wir brauchen einen Namen, um die Bestellung im Laden an die richtige Person herausgeben zu können.";
export const EMAIL_DESCRIPTION =
	"Wir brauchen eine Möglichkeit, um mit Ihnen in Kontakt treten zu können. Es wird ihnen eine Bestätigung der Bestellung via E-Mail zugesandt. Falls Probleme auftreten, wird Ihnen ebenso eine E-Mail zugesendet.";
export const PHONE_DESCRIPTION =
	"Ihre Telefonnummer ist nicht notwendig. In dringenden Fällen ist es jedoch oft einfacher Sie telefonisch zu kontaktieren. Das geht schneller. Unser Team im Laden steht oft unter Zeitdruck und hat i.d.R. nicht die Zeit eine E-Mail zu verfassen.";
export const DATE_DESCRIPTION =
	"Es muss ein Datum zur Abholung definiert werden. Das Datum sollte nicht zu weit in der Zukunft liegen. Es kann nicht an geschlossenen Tagen abgeholt werden.";
export const TIME_DESCRIPTION =
	"Es muss eine Uhrzeit zur Abholung festgelegt werden. Um Kollisionen mit sich ändernden Öffnungszeiten zu vermeiden, sollte eine Uhrzeit zwischen 8:00 und 13:00 Uhr gewählt werden.";
export const MESSAGE_DESCRIPTION =
	"Wenn Sie uns noch etwas zu ihrer Bestellung mitzuteilen haben, dann schreiben Sie uns hier noch eine kleine Nachricht.";
export const PRIVACY_MESSAGE =
	'Wir speichern Ihre Daten "temporär". Sobald der Bestellvorgang durch die Abholung der Waren abgeschlossen ist, werden Ihre Daten automatisch gelöscht. Hiermit haben Sie unsere bestellungsspezifischen Datenschutzbestimmungen gelesen und akzeptiert.';
export const PRIVACY_LINK_TITLE = "(Datenschutzbestimmungen lesen)";
export const PAYMENT_METHOD_DESCRIPTION = (minBasketValue: string) =>
	`Wir bieten momenan zwei Bezahlmethoden in unserem OnlineShop an. "Paypal" und "Bei Abholung". Wenn Sie "Bei Abholung" wählen, muss die Ware im Laden bezahlt werden. Die Bezahlmethode "Paypal" ist erst ab einem Mindestbestellwert von ${minBasketValue} möglich.`;

export const NO_PRODUCTS_IN_BASKET = "Sie haben keine Produkte in Ihrem Warenkorb.";
export const NO_PRODUCT_FOUND_FOR_SEARCH_STRING =
	"Es gibt keine Produkte die zu Ihrer Suche passen. Ändern Sie die Suchanfrage oder beenden Sie die Suche.";

export const CHECKOUT_FINISHED_TITLE = "Vielen Dank Für Ihre Bestellung";
export const CHECKOUT_FINISHED_MESSAGE = "Wir haben Ihnen eine Bestätigungs-E-mail zugesandt.";

export const LOCAL_STORAGE_MESSAGE =
	"Um Ihnen den Einkauf zu erleichtern, nutzen wir den Browser Local Storage. Dabei speichern wir Ihre Daten nicht. Falls Sie für Ihren Einkauf einen fremden Computer verwendet haben, so empfehlen wir Ihnen den Browser Local Storage jetzt zu löschen.";
export const SUBMITTING_ERROR_MESSAGE = "Es ist ein Fehler aufgetreten. Bitte versuchen sie es später noch einmal.";
