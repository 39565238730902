import * as common from "common";
import React from "react";
import { ButtonProps, Button } from "common";
import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";
import { ApplicationActionType } from "../../applicationState/actions";
import { dispatch, useAppState } from "../../applicationState";
import { selectBasketValue } from "../checkout/selectors/selectors";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { IS_DEVELOPMENT_ENVIRONMENT } from "../../variables/environment";
import { CLIENT_ID } from "./variables";

const options: PayPalScriptOptions = {
	"client-id": CLIENT_ID,
	components: "buttons",
	currency: "EUR",
	intent: "capture",
	debug: IS_DEVELOPMENT_ENVIRONMENT,
};
export const WithPaypalScript: React.FC = (props) => {
	return <PayPalScriptProvider options={options}>{props.children}</PayPalScriptProvider>;
};

export interface PaypalButtonProps {
	type: "PAYPAL_BUTTON_PROPS";
	props: ButtonProps;
}
export const PaypalButton: React.FC<PaypalButtonProps> = ({ props }) => {
	const basketValue = useAppState(selectBasketValue);
	const [{ isResolved }] = usePayPalScriptReducer();

	if (isResolved) {
		return (
			<PayPalButtons
				style={{ layout: "horizontal", height: 42 }}
				createOrder={(data, actions) => {
					return actions.order.create({
						purchase_units: [
							{
								description: "vonLuck OnlineShop",
								amount: {
									currency_code: "EUR",
									value: String(basketValue / 100),
								},
							},
						],
					});
				}}
				onCancel={() => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_ABORT_SUBMITTING,
					});
				}}
				onApprove={async (data, actions) => {
					const order = await actions.order.capture();
					dispatch({
						type: ApplicationActionType.CHECKOUT_EXTEND_MESSAGE,
						message: "(paypal-order-id: " + order.id + ")",
					});
					dispatch({
						type: ApplicationActionType.CHECKOUT_SET_PAYMENT_STATE,
						state: common.PaymentStateType.PAYED,
					});
					dispatch({
						type: ApplicationActionType.CHECKOUT_SET_SUBMITTING,
					});
				}}
				onError={() => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_SET_SUBMITTING_ERROR,
						error: Error("Paypal payment failed"),
					});
				}}
			/>
		);
	} else {
		return (
			<Button {...props} className="btn-dis btn-md">
				{props.title}
			</Button>
		);
	}
};
