import * as React from "react";
import { IconProps } from "../../models/domain";

export default function Yelp(props: IconProps) {
	return (
		<svg
			{...props}
			viewBox="-58 0 512 512.00081"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			stroke="none"
		>
			<path d="m204.445312 36.292969c-.648437-9.257813-2.164062-30.941407-28.046874-35.597657-25.597657-4.601562-91.277344 14.617188-116.382813 28.816407-10.523437 5.953125-18.199219 15.390625-20.53125 25.25-1.78125 7.511719-.441406 15.101562 3.761719 21.371093 10.6875 15.925782 97.546875 158.554688 111.601562 173.230469 8.921875 9.3125 21.488282 13.59375 33.792969 10.585938 11.730469-2.867188 20.898437-11.988281 24.53125-24.402344 5.726563-19.585937-5.746094-153.839844-8.726563-199.253906zm-20.136718 191.011719c-.378906 1.210937-1.214844 3.101562-2.792969 3.492187-1.46875.359375-3.332031-.464844-4.984375-2.1875-10.726562-11.421875-95.5-149.488281-107.4375-167.800781.808594-1.339844 2.746094-3.5 5.695312-5.171875 23.035157-13.023438 80.632813-28.222657 96.296876-25.398438 1.417968.253907 2.128906.558594 2.402343.699219.585938 1.292969.851563 5.121094 1.015625 7.449219 1.671875 22.734375 13.199219 171.742187 9.804688 188.917969zm0 0" />
			<path d="m24.3125 227.144531c-6.339844 3.394531-14.78125 10.933594-18.988281 27.554688-5.75 22.726562-7.839844 63.628906-1.1875 90.074219 4.460937 17.75 20.667969 28.328124 37.777343 24.476562 7.871094-1.773438 92.074219-28.636719 108.871094-34 15.023438-4.796875 24.398438-19.492188 23.332032-36.566406-.925782-14.789063-8.636719-25.917969-21.722657-31.335938-55.453125-22.972656-82.773437-34.457031-103.628906-41.828125-8.3125-2.941406-16.996094-2.363281-24.453125 1.625zm14.03125 26.550781c.09375.019532.230469.054688.417969.121094 21.390625 7.5625 51.320312 20.203125 102.152343 41.261719 1.601563.664063 2.984376 1.234375 3.25 5.480469.203126 3.25-.734374 5.53125-2.507812 6.097656-38.003906 12.140625-100.488281 31.921875-106.335938 33.3125-1.292968.296875-1.957031-2.046875-2.074218-2.515625-4.753906-18.898437-4.203125-54.128906 1.175781-75.390625 1.394531-5.515625 3.210937-7.820312 3.921875-8.367188zm0 0" />
			<path d="m342.316406 154.980469c-8.769531-8.125-19.527344-12.46875-29.515625-11.925781-8.167969.441406-15.347656 3.976562-20.765625 10.21875-2.113281 2.433593-21.277344 27.898437-36.363281 48.4375-35.902344 48.886718-36.460937 52.328124-37.171875 56.6875-1.914062 11.769531 2.421875 24.382812 11.320312 32.917968 9.011719 8.644532 21.328126 12.109375 33.769532 9.550782 3.765625-.773438 105.292968-26.628907 106.191406-26.863282 11.480469-3.003906 17.148438-10.027344 19.878906-15.390625 4.789063-9.398437 4.601563-21.308593-.535156-34.445312-8.878906-22.707031-29.441406-53.097657-46.808594-69.1875zm20.671875 89.71875c-.171875.074219-.433593.167969-.804687.265625-5.167969 1.351562-99.394532 25.292968-104.722656 26.519531-2.726563.539063-5.039063-.078125-6.863282-1.828125-1.433594-1.375-2.351562-3.265625-2.515625-5.035156 8.316407-14.320313 60.644531-84.21875 66.570313-91.601563 1.066406.054688 3.984375.9375 7.265625 3.976563 14.050781 13.019531 32.027343 39.628906 39.253906 58.101562 2.085937 5.335938 2.023437 8.539063 1.816406 9.601563zm0 0" />
			<path d="m365.855469 345.6875c-31.757813-10.578125-63.523438-21.128906-95.292969-31.667969-16.015625-5.316406-31.859375.28125-41.339844 14.609375-8.15625 12.324219-8.59375 26.367188-1.199218 38.527344 24.710937 40.632812 40.933593 66.417969 47.074218 76.4375 9.722656 15.855469 19.277344 31.949219 37.851563 31.949219 11.378906 0 20.933593-6.761719 27.398437-12.152344 17.902344-14.925781 39.785156-42.785156 49.816406-63.421875 6.4375-13.238281 7.65625-24.207031 3.730469-33.535156-5.578125-13.265625-19.636719-17.945313-28.039062-20.746094zm-2.683594 41.160156c-8.3125 17.09375-27.171875 41.085938-42.046875 53.488282-4.574219 3.816406-7.070312 4.832031-7.953125 5.097656-3.628906-2.402344-45.945313-71.578125-59.503906-93.871094-.984375-1.621094-1.566407-3.121094.585937-6.371094 1.617188-2.441406 3.0625-3.164062 4.503906-3.164062.777344 0 1.554688.210937 2.355469.476562 31.757813 10.539063 63.511719 21.082032 95.257813 31.660156 5.125 1.707032 8.394531 3.070313 9.738281 4.0625-.074219 1.03125-.53125 3.664063-2.9375 8.621094zm0 0" />
			<path d="m186.964844 335.265625c-14.355469-3.707031-27.324219.253906-36.523438 11.144531-55.175781 65.355469-63.570312 75.855469-72.003906 87.324219-7.097656 9.660156-7.097656 18.566406-5.84375 24.332031 2.328125 10.710938 10.769531 20 25.8125 28.394532 13.695312 7.644531 29.097656 13.96875 47.078125 19.332031 11.097656 3.3125 22.953125 6.207031 33.800781 6.207031 22.566406 0 34.734375-13.363281 34.789063-32.761719.09375-26.226562.0625-37.214843 0-110.730469-.011719-15.691406-10.871094-29.050781-27.109375-33.242187zm-3.070313 146.234375c-1.71875.683594-8.351562 1.941406-29.828125-4.46875-15.855468-4.730469-29.277344-10.21875-41.035156-16.78125-7.410156-4.132812-9.988281-6.996094-10.808594-8.148438 5.414063-8.8125 61.734375-75.175781 71.152344-86.328124 1.167969-1.382813 2.09375-2.476563 6.089844-1.445313 2.957031.761719 4.652344 2.292969 4.65625 4.203125.097656 116.484375.113281 111.589844-.226563 112.96875zm0 0" />
		</svg>
	);
}
