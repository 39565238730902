export enum OrderStateType {
	PENDING = "ORDER_STATE_TYPE/PENDING",
	APPROVED = "ORDER_STATE_TYPE/APPROVED",
	PREPARED = "ORDER_STATE_TYPE/PREPARED",
	COLLECTED = "ORDER_STATE_TYPE/COLLECTED",
	CANCELED = "ORDER_STATE_TYPE/CANCELED",
}

export enum PaymentMethodType {
	PAYPAL = "PAYMENT_METHOD/PAYPAL",
	AT_LOCATION = "PAYMENT_METHOD/AT_LOCATION",
}

export enum PaymentStateType {
	PENDING = "PENDING",
	PAYED = "PAYED",
}

export interface Order {
	id: string;
	state: OrderStateType;
	locationId: string;
	orderPickUpId: string;
	name: string;
	email: string;
	phone: string;
	date: Date;
	time: string;
	paymentMethod: PaymentMethodType;
	paymentState: PaymentStateType;
	notes: string;
	products: Array<ProductInOrder>;
	price: number;
}

export interface ProductInOrder {
	id: string;
	name: string;
	price: number;
	amount: number;
}
