import { pad } from "../../utils";

export interface CoinsAndCents {
	coins: string;
	cents: string;
}

export const toCoinsAndCents = (price: number /*as integer 100 eq 1,00*/): CoinsAndCents => {
	switch (true) {
		case 0 > price: {
			return {
				coins: "0",
				cents: "00",
			};
		}
		case 100 > price: {
			return {
				coins: "0",
				cents: pad(price, 2),
			};
		}
		default: {
			const value: string = String(price);
			const coins = value.slice(0, -2);
			const cents = value.slice(-2);
			return {
				coins: coins,
				cents: cents,
			};
		}
	}
};

export const toPriceString = (price: number /*as integer 100 eq 1,00*/): string => {
	const { coins, cents } = toCoinsAndCents(price);
	return `€ ${coins},${cents}`;
};
