import { PageWrapperFooterProps } from "../pageWrapperFooter";
import { copyright, legal, IconType, CommonPropsType } from "common";
import { VON_LUCK_HOMEPAGE_LOCATION_URL, VON_LUCK_HOMEPAGE_PRIVACY_URL } from "../../../variables/urls";

export const toFooterProps = (): PageWrapperFooterProps => ({
	copyright: copyright,
	legal: legal,
	paymentOptions: [
		IconType.VISA,
		IconType.AMEX,
		IconType.MASTERCARD,
		IconType.MAESTRO,
		IconType.JCB,
		IconType.PAYPAL,
		IconType.APPLE_PAY,
		IconType.GOOGLE_PAY,
	],
	navigation: [
		{
			type: CommonPropsType.LINK,
			link: VON_LUCK_HOMEPAGE_LOCATION_URL("1"),
			title: "vonLuck Nikolassee",
		},
		{
			type: CommonPropsType.LINK,
			link: VON_LUCK_HOMEPAGE_LOCATION_URL("5"),
			title: "vonLuck Mitte",
		},
		{
			type: CommonPropsType.LINK,
			link: VON_LUCK_HOMEPAGE_PRIVACY_URL,
			title: "Datenschutz",
		},
	],
	socialMedia: [
		{
			type: CommonPropsType.LINK,
			icon: IconType.TRIPADVISOR,
			title: "Tripadvisor",
			link: "https://www.tripadvisor.com/Restaurant_Review-g187323-d5774581-Reviews-VonLuck-Berlin.html",
		},
		{
			type: CommonPropsType.LINK,
			icon: IconType.YELP,
			title: "Yelp",
			link: "https://www.yelp.com/biz/vonluck-berlin-2",
		},
		{
			type: CommonPropsType.LINK,
			icon: IconType.TOOOGOODTOGO,
			title: "TooGoodToGo",
			link: "https://store.toogoodtogo.com/login",
		},
	],
});
