import { ButtonProps, DisabledButtonProps, ImageProps } from "common";

export type LocationsProps = LoadingLocationsProps | SkeletonLocationsProps | ErrorLocationsProps | DataLocationsProps;

export enum LocationsPropsType {
	LOADING = "LOCATIONS_PROPS_TYPE/LOADING",
	SKELETON = "LOCATIONS_PROPS_TYPE/SKELETON",
	DATA = "LOCATIONS_PROPS_TYPE/DATA",
	ERROR = "LOCATIONS_PROPS_TYPE/ERROR",
}

export interface LoadingLocationsProps {
	type: LocationsPropsType.LOADING;
	message: string;
}

export interface SkeletonLocationsProps {
	type: LocationsPropsType.SKELETON;
	title: string;
}

export interface ErrorLocationsProps {
	type: LocationsPropsType.ERROR;
	title: string;
	errorMessage: string;
}

export interface DataLocationsProps {
	type: LocationsPropsType.DATA;
	title: string;
	locations: Array<LocationProps>;
	submit: ButtonProps | DisabledButtonProps;
}

export interface LocationProps {
	image: ImageProps;
	button: ButtonProps;
}
