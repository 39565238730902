import { toPathWithQueryParameters } from "../fetchApi";
import { Email } from "../models";

export type FetchJSON = <T>(path: string, options: any) => Promise<T>;
export type FetchText = (path: string, options: any) => Promise<string>;

export interface Request {
	type: string;
}

export const getAll =
	(fetch: FetchJSON) =>
	(serverAddress: string) =>
	<T>(req: Request & { page?: string; filters?: { [key: string]: any } }): Promise<Array<T>> => {
		const url = toPathWithQueryParameters(serverAddress + "/.netlify/functions/get-all", {
			type: req.type,
			page: req.page,
			filters: req.filters === undefined ? undefined : JSON.stringify(req.filters),
		});
		return fetch(url, { method: "GET" });
	};

export const getById =
	(fetch: FetchJSON) =>
	(serverAddress: string) =>
	<T>(req: Request & { id: string }): Promise<T> => {
		const url = toPathWithQueryParameters(serverAddress + "/.netlify/functions/get-by-id", {
			type: req.type,
			id: req.id,
		});
		return fetch(url, { method: "GET" });
	};

export const set =
	(fetch: FetchJSON) =>
	(serverAddress: string) =>
	<A, B>(req: Request & { data?: Array<A> | A }): Promise<Array<B>> => {
		const url = toPathWithQueryParameters(serverAddress + "/.netlify/functions/set", {
			type: req.type,
		});
		return fetch(url, {
			method: "POST",
			body: JSON.stringify(req.data),
		});
	};

export const sendMail = (fetch: FetchText) => (serverAddress: string) => (email: Email) => {
	return fetch(serverAddress + "/.netlify/functions/send-mail", {
		method: "POST",
		body: JSON.stringify(email),
	});
};
