interface Request {
	paymentMethod: string;
	location: string;
	name: string;
	phone: string;
	email: string;
	date: string;
	time: string;
	message: string;
	products: string;
	price: string;
}
export const orderDetailsText = (req: Request): string => `
Details:
===============================
Bezahlmethode: ${req.paymentMethod}
Standort: ${req.location}
Name: ${req.name}
Tel.: ${req.phone}
E-Mail: ${req.email}

Abholdatum: ${req.date}
Abholzeit: ${req.time}

Notizen:
${req.message}

Bestellung:

${req.products}
-------------------------------
Preis inkl. MwSt    ${req.price}
===============================
`;
