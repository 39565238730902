// resource: https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=%2Fsrc%2FOutsideAlerter.js
// todo: write tests: useOutsideComponentDetection.test.js
import * as React from "react";

interface CreateUseOutsideComponentDetection {
	outsideCallback: () => void;
}

export default function useOutsideComponentDetection({ outsideCallback }: CreateUseOutsideComponentDetection) {
	const componentRef: React.RefObject<HTMLDivElement> = React.useRef(null);

	const clickHandler = React.useCallback((event: any) => {
		try {
			const refHasNoCurrent = !componentRef["current"];
			const refDoesNotContainEventTarget = !componentRef.current?.contains(event.target);

			if (refHasNoCurrent || refDoesNotContainEventTarget) {
				outsideCallback();
			}
		} catch (e) {}
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		document.addEventListener("mousedown", clickHandler);

		return () => {
			document.removeEventListener("mousedown", clickHandler);
		};
		// eslint-disable-next-line
	}, []);

	return componentRef;
}
