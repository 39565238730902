import { OpeningHours } from "./openingHours";

export interface OpeningHoursSet<T = OpeningHours> {
	[date: string]: T | undefined;
}

export type Add = (openingHours: OpeningHours) => (set: OpeningHoursSet) => OpeningHoursSet;
export const add: Add = (openingHours) => (set) => {
	const openingHoursForGivenDate = set[openingHours.date];
	return openingHoursForGivenDate === undefined
		? {
				...set,
				[openingHours.date]: openingHours,
		  }
		: set;
};
