import {
	ButtonProps,
	DisabledButtonProps,
	ImageProps,
	TextInputProps,
	BooleanInputProps,
	LinkProps,
	OpeningHoursRule,
} from "common";
import { PaypalButtonProps } from "../../paypalButton";

export type CheckoutProps =
	| DefaultCheckoutProps
	| SkeletonCheckoutProps
	| LoadingCheckoutProps
	| ValidatingCheckoutProps
	| SubmittingCheckoutProps
	| ErrorCheckoutProps
	| FinishedCheckoutProps;

export enum CheckoutPropsType {
	DEFAULT = "CHECKOUT_PROPS_TYPE/DEFAULT",
	SKELETON = "CHECKOUT_PROPS_TYPE/SKELETON",
	LOADING = "CHECKOUT_PROPS_TYPE/LOADING",
	VALIDATING = "CHECKOUT_PROPS_TYPE/VALIDATING",
	SUBMITTING = "CHECKOUT_PROPS_TYPE/SUBMITTING",
	ERROR = "CHECKOUT_PROPS_TYPE/ERROR",
	FINISHED = "CHECKOUT_PROPS_TYPE/FINISHED",
}

export interface DefaultCheckoutProps {
	type: CheckoutPropsType.DEFAULT;
	goBack: ButtonProps;
	products: ProductsProps;
	order: OrderProps;
	submit: SubmitButtonProps;
}

export interface SkeletonCheckoutProps {
	type: CheckoutPropsType.SKELETON;
	goBack: ButtonProps;
	products: {
		title: string;
		skeleton: {
			title: string;
			message: string;
		};
	};
	order: OrderProps;
}

export interface LoadingCheckoutProps {
	type: CheckoutPropsType.LOADING;
}

export interface ValidatingCheckoutProps {
	type: CheckoutPropsType.VALIDATING;
	goBack: ButtonProps;
	submit: SubmitButtonProps;
}

export interface SubmittingCheckoutProps {
	type: CheckoutPropsType.SUBMITTING;
}

export interface ErrorCheckoutProps {
	type: CheckoutPropsType.ERROR;
	goBack: ButtonProps;
	products: ProductsProps;
	order: OrderProps;
	errorMessage: string;
	submit: SubmitButtonProps;
}

export type SubmitButtonProps = PaypalButtonProps | ButtonProps | DisabledButtonProps;

export interface FinishedCheckoutProps {
	type: CheckoutPropsType.FINISHED;
	goBack: ButtonProps;
	title: string;
	message: string;
	continueShopping: ButtonProps;
	localStorageWarning: {
		message: string;
		clear: ButtonProps;
	};
}

export interface ProductsProps {
	title: string;
	products: Array<ProductProps>;
	searchAmount?: {
		title: string;
		amount: string;
	};
	searchSum?: {
		title: string;
		price: string;
	};
	amount: {
		title: string;
		amount: string;
	};
	sum: {
		title: string;
		price: string;
	};
}

export interface OrderProps {
	title: string;
	location: {
		title: string;
		image: ImageProps;
		locationName: string;
		description: string;
	};
	name: {
		title: string;
		input: TextInputProps;
		description: string;
	};
	email: {
		title: string;
		input: TextInputProps;
		description: string;
	};
	phone: {
		title: string;
		input: TextInputProps;
		description: string;
	};
	date: {
		title: string;
		input: {
			value?: Date;
			offsetDays: number;
			openingHoursRules: Array<OpeningHoursRule>;
			onChange: (date: Date) => void;
		};
		validation?: string;
		description: string;
	};
	time: {
		title: string;
		input: TextInputProps;
		description: string;
	};
	privacy: {
		check: BooleanInputProps;
		message: string;
		validation?: string;
		toPrivacyPage: LinkProps;
	};
	message: {
		title: string;
		input: TextInputProps;
		description: string;
	};
	paymentMethod: {
		title: string;
		options: Array<ButtonProps>;
		validation?: string;
		description: string;
	};
}

export interface ProductProps {
	image: ImageProps;
	title: string;
	description: string;
	ingredients: string;
	amount: string;
	price: string;
	addOne: ButtonProps;
	setAmount: TextInputProps;
	reduceOne: ButtonProps;
	removeAll: ButtonProps;
}
