import React from "react";
import { IconType, Icon } from "common";

export const MaintenanceView: React.FC = () => {
	return (
		<div className="w-screen h-screen flex items-center justify-center">
			<div className="bg-yellow-500 rounded-md overflow-hidden">
				<div className="p-4 flex flex-col items-center justify-center space-y-4">
					<Icon type={IconType.EXCLAMATION_FILL} className="w-8 h-8 text-yellow-600" />
					<h2 className="text-2xl font-bold text-yellow-800 uppercase">{MAINTENANCE_TITLE}</h2>
					<p className="w-96 text-sm text-yellow-700 text-center">{MAINTENANCE_MESSAGE}</p>
				</div>
				<div className="h-8 w-full bg-yellow-600" />
			</div>
		</div>
	);
};

const MAINTENANCE_TITLE = "Maintenance Mode";
const MAINTENANCE_MESSAGE =
	"Wir arbeiten am vonLuck Online-Shop. Der Shop ist momentan nicht verfügbar. Bitte versuchen Sie es später erneut.";
