import { EmailRequest, EmailRequestContact, EmailContact, Email } from "./models";

export const toEmailRequest = (email: Email): EmailRequest => {
	return {
		personalizations: [
			{
				to: [toEmailContactRequest(email.to)],
				subject: email.subject,
			},
		],
		from: toEmailContactRequest(email.from),
		reply_to: toEmailContactRequest(email.replyTo),
		subject: email.subject,
		content: [
			{
				type: email.content.type,
				value: email.content.content,
			},
		],
	};
};

const toEmailContactRequest = (emailContact: EmailContact): EmailRequestContact => {
	return {
		name: emailContact.name,
		email: String(emailContact.emailAddress),
	};
};
