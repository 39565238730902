import React from "react";
import { ApplicationActionType } from "../../applicationState/actions";
import { useAppState, store, selectAppState } from "../../applicationState";
import { selectLocationForId } from "../locations/selectors/selectors";
import { ProductsListView } from "./views/productsListView";
import { toProductsListViewProps } from "./toProps";
import { Loading } from "common";
import { PATH_SET_LOCATIONS } from "../../variables/urls";

export const ProductsList = () => {
	const state = useAppState(selectAppState);
	const selectedLocation = useAppState(selectLocationForId(state.checkout.request.locationId!));

	React.useEffect(() => {
		if (selectedLocation === undefined) {
			store.dispatch({
				type: ApplicationActionType.ROUTER_REDIRECT,
				path: PATH_SET_LOCATIONS,
			});
		}
		// eslint-disable-next-line
	}, []);

	if (selectedLocation === undefined) return <Loading />;

	const viewProps = toProductsListViewProps({
		productsState: state.products,
		checkoutState: state.checkout,
		productsListState: state.productsList,
		selectedLocation: selectedLocation,
	});

	return <ProductsListView {...viewProps} />;
};
