import * as common from "common";

export interface CreateOrderRequest {
	locationId: string;
	name: string;
	email: string;
	phone?: string;
	date: Date;
	time: string;
	message?: string;
	paymentMethod: common.PaymentMethodType;
	paymentState: common.PaymentStateType;
	products: Array<{
		id: string;
		name: string;
		price: number;
		amount: number;
	}>;
}

export type MakeCreateOrder = (
	hash: (value: string) => string,
	createOrder: (request: RequestOrder) => Promise<common.OrderResponse | undefined>,
	createProductsToOrder: (
		request: Array<RequestProductToOrder>
	) => Promise<Array<common.ProductToCustomerResponse> | undefined>
) => (request: CreateOrderRequest) => Promise<common.Order>;
export const makeCreateOrder: MakeCreateOrder = (hash, createOrder, createProductsToOrder) => async (request) => {
	const orderFields: RequestOrderFields = {
		state: "PENDING",
		locationId: Number(request.locationId),
		name: request.name,
		phone: request.phone || "",
		email: request.email,
		date: common.toDateString(request.date), // yyyy-mm-dd
		time: request.time,
		notes: request.message || "",
		paymentMethod: ((): string => {
			switch (request.paymentMethod) {
				case common.PaymentMethodType.AT_LOCATION:
					return "AT_LOCATION";
				case common.PaymentMethodType.PAYPAL:
					return "PAYPAL";
			}
		})(),
		paymentState: ((): string => {
			switch (request.paymentState) {
				case common.PaymentStateType.PENDING:
					return "PENDING";
				case common.PaymentStateType.PAYED:
					return "PAYED";
			}
		})(),
		price:
			request.products.reduce((sum, { price, amount }) => {
				return sum + price * amount;
			}, 0) / 100,
	};
	const orderPickUpId = hash(
		JSON.stringify({
			created: Date.now(), // to ensures the hash is unique
			...orderFields,
		})
	);

	const orderResponse = await createOrder({
		fields: {
			orderPickUpId: orderPickUpId,
			...orderFields,
		},
	});
	if (orderResponse === undefined) throw Error(CREATING_ORDER_FAILED);

	const productsResponse = await createProductsToOrder(
		request.products.map(
			(product): RequestProductToOrder => ({
				fields: {
					orderId: orderResponse.id,
					productId: Number(product.id),
					price: product.price / 100,
					amount: product.amount,
				},
			})
		)
	);
	if (productsResponse === undefined) throw Error(CREATING_PRODUCTS_FAILED);
	const products = productsResponse.map((response, index) => {
		return common.toProductInOrder(response, request.products[index]);
	});

	return common.toOrder(orderResponse, products);
};

export const CREATING_ORDER_FAILED = "Creating the Order Failed!";
export const CREATING_PRODUCTS_FAILED = "Creating the Products Failed!";

export interface RequestOrder {
	fields: {
		state: string; // 1 PENDING
		locationId: number;
		orderPickUpId: string;
		name: string;
		phone: string;
		email: string;
		date: string; // yyyy-mm-dd
		time: string;
		notes: string;
		paymentMethod: string; // 1 AT_LOCATION, 2 PAYPAL
		paymentState: string; // 1 PENDING, 2 PAYED
		price: number;
	};
}

export interface RequestProductToOrder {
	fields: {
		orderId: number;
		productId: number;
		amount: number;
		price: number;
	};
}

export interface RequestOrderFields {
	state: string; // 1 PENDING
	locationId: number;
	name: string;
	phone: string;
	email: string;
	date: string; // yyyy-mm-dd
	time: string;
	notes: string;
	paymentMethod: string; // 1 AT_LOCATION, 2 PAYPAL
	paymentState: string; // 1 PENDING, 2 PAYED
	price: number;
}
