import * as React from "react";
import { IconProps } from "../../models/domain";

export default function Mastercard(props: IconProps) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 504 504">
			<path
				fill="#FFB600"
				d="M504,252c0,83.2-67.2,151.2-151.2,151.2c-83.2,0-151.2-68-151.2-151.2l0,0
	c0-83.2,67.2-151.2,150.4-151.2C436.8,100.8,504,168.8,504,252L504,252z"
			/>
			<path
				fill="#F7981D"
				d="M352.8,100.8c83.2,0,151.2,68,151.2,151.2l0,0c0,83.2-67.2,151.2-151.2,151.2
	c-83.2,0-151.2-68-151.2-151.2"
			/>
			<path fill="#FF8500" d="M352.8,100.8c83.2,0,151.2,68,151.2,151.2l0,0c0,83.2-67.2,151.2-151.2,151.2" />
			<path
				fill="#FF5050"
				d="M149.6,100.8C67.2,101.6,0,168.8,0,252s67.2,151.2,151.2,151.2c39.2,0,74.4-15.2,101.6-39.2l0,0l0,0
	c5.6-4.8,10.4-10.4,15.2-16h-31.2c-4-4.8-8-10.4-11.2-15.2h53.6c3.2-4.8,6.4-10.4,8.8-16h-71.2c-2.4-4.8-4.8-10.4-6.4-16h83.2
	c4.8-15.2,8-31.2,8-48c0-11.2-1.6-21.6-3.2-32h-92.8c0.8-5.6,2.4-10.4,4-16h83.2c-1.6-5.6-4-11.2-6.4-16H216
	c2.4-5.6,5.6-10.4,8.8-16h53.6c-3.2-5.6-7.2-11.2-12-16h-29.6c4.8-5.6,9.6-10.4,15.2-15.2c-26.4-24.8-62.4-39.2-101.6-39.2
	C150.4,100.8,150.4,100.8,149.6,100.8z"
			/>
			<path
				fill="#E52836"
				d="M0,252c0,83.2,67.2,151.2,151.2,151.2c39.2,0,74.4-15.2,101.6-39.2l0,0l0,0
	c5.6-4.8,10.4-10.4,15.2-16h-31.2c-4-4.8-8-10.4-11.2-15.2h53.6c3.2-4.8,6.4-10.4,8.8-16h-71.2c-2.4-4.8-4.8-10.4-6.4-16h83.2
	c4.8-15.2,8-31.2,8-48c0-11.2-1.6-21.6-3.2-32h-92.8c0.8-5.6,2.4-10.4,4-16h83.2c-1.6-5.6-4-11.2-6.4-16H216
	c2.4-5.6,5.6-10.4,8.8-16h53.6c-3.2-5.6-7.2-11.2-12-16h-29.6c4.8-5.6,9.6-10.4,15.2-15.2c-26.4-24.8-62.4-39.2-101.6-39.2h-0.8"
			/>
			<path
				fill="#CB2026"
				d="M151.2,403.2c39.2,0,74.4-15.2,101.6-39.2l0,0l0,0c5.6-4.8,10.4-10.4,15.2-16h-31.2
	c-4-4.8-8-10.4-11.2-15.2h53.6c3.2-4.8,6.4-10.4,8.8-16h-71.2c-2.4-4.8-4.8-10.4-6.4-16h83.2c4.8-15.2,8-31.2,8-48
	c0-11.2-1.6-21.6-3.2-32h-92.8c0.8-5.6,2.4-10.4,4-16h83.2c-1.6-5.6-4-11.2-6.4-16H216c2.4-5.6,5.6-10.4,8.8-16h53.6
	c-3.2-5.6-7.2-11.2-12-16h-29.6c4.8-5.6,9.6-10.4,15.2-15.2c-26.4-24.8-62.4-39.2-101.6-39.2h-0.8"
			/>
			<path
				fill="#FFFFFF"
				d="M204.8,290.4l2.4-13.6c-0.8,0-2.4,0.8-4,0.8c-5.6,0-6.4-3.2-5.6-4.8l4.8-28h8.8l2.4-15.2h-8l1.6-9.6
		h-16c0,0-9.6,52.8-9.6,59.2c0,9.6,5.6,13.6,12.8,13.6C199.2,292.8,203.2,291.2,204.8,290.4z"
			/>
			<path
				fill="#FFFFFF"
				d="M210.4,264.8c0,22.4,15.2,28,28,28c12,0,16.8-2.4,16.8-2.4l3.2-15.2c0,0-8.8,4-16.8,4
		c-17.6,0-14.4-12.8-14.4-12.8H260c0,0,2.4-10.4,2.4-14.4c0-10.4-5.6-23.2-23.2-23.2C222.4,227.2,210.4,244.8,210.4,264.8z
		 M238.4,241.6c8.8,0,7.2,10.4,7.2,11.2H228C228,252,229.6,241.6,238.4,241.6z"
			/>
			<path
				fill="#FFFFFF"
				d="M340,290.4l3.2-17.6c0,0-8,4-13.6,4c-11.2,0-16-8.8-16-18.4c0-19.2,9.6-29.6,20.8-29.6
		c8,0,14.4,4.8,14.4,4.8l2.4-16.8c0,0-9.6-4-18.4-4c-18.4,0-36.8,16-36.8,46.4c0,20,9.6,33.6,28.8,33.6
		C331.2,292.8,340,290.4,340,290.4z"
			/>
			<path
				fill="#FFFFFF"
				d="M116.8,227.2c-11.2,0-19.2,3.2-19.2,3.2L95.2,244c0,0,7.2-3.2,17.6-3.2c5.6,0,10.4,0.8,10.4,5.6
		c0,3.2-0.8,4-0.8,4s-4.8,0-7.2,0c-13.6,0-28.8,5.6-28.8,24c0,14.4,9.6,17.6,15.2,17.6c11.2,0,16-7.2,16.8-7.2l-0.8,6.4H132l6.4-44
		C138.4,228,122.4,227.2,116.8,227.2z M120,263.2c0,2.4-1.6,15.2-11.2,15.2c-4.8,0-6.4-4-6.4-6.4c0-4,2.4-9.6,14.4-9.6
		C119.2,263.2,120,263.2,120,263.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M153.6,292c4,0,24,0.8,24-20.8c0-20-19.2-16-19.2-24c0-4,3.2-5.6,8.8-5.6c2.4,0,11.2,0.8,11.2,0.8
		l2.4-14.4c0,0-5.6-1.6-15.2-1.6c-12,0-24,4.8-24,20.8c0,18.4,20,16.8,20,24c0,4.8-5.6,5.6-9.6,5.6c-7.2,0-14.4-2.4-14.4-2.4
		l-2.4,14.4C136,290.4,140,292,153.6,292z"
			/>
			<path
				fill="#FFFFFF"
				d="M472.8,214.4l-3.2,21.6c0,0-6.4-8-15.2-8c-14.4,0-27.2,17.6-27.2,38.4c0,12.8,6.4,26.4,20,26.4
		c9.6,0,15.2-6.4,15.2-6.4l-0.8,5.6h16l12-76.8L472.8,214.4z M465.6,256.8c0,8.8-4,20-12.8,20c-5.6,0-8.8-4.8-8.8-12.8
		c0-12.8,5.6-20.8,12.8-20.8C462.4,243.2,465.6,247.2,465.6,256.8z"
			/>
			<path
				fill="#FFFFFF"
				d="M29.6,291.2l9.6-57.6l1.6,57.6H52l20.8-57.6L64,291.2h16.8l12.8-76.8H67.2l-16,47.2l-0.8-47.2H27.2
		l-12.8,76.8H29.6z"
			/>
			<path
				fill="#FFFFFF"
				d="M277.6,291.2c4.8-26.4,5.6-48,16.8-44c1.6-10.4,4-14.4,5.6-18.4c0,0-0.8,0-3.2,0
		c-7.2,0-12.8,9.6-12.8,9.6l1.6-8.8h-15.2L260,292h17.6V291.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M376.8,227.2c-11.2,0-19.2,3.2-19.2,3.2l-2.4,13.6c0,0,7.2-3.2,17.6-3.2c5.6,0,10.4,0.8,10.4,5.6
		c0,3.2-0.8,4-0.8,4s-4.8,0-7.2,0c-13.6,0-28.8,5.6-28.8,24c0,14.4,9.6,17.6,15.2,17.6c11.2,0,16-7.2,16.8-7.2l-0.8,6.4H392l6.4-44
		C399.2,228,382.4,227.2,376.8,227.2z M380.8,263.2c0,2.4-1.6,15.2-11.2,15.2c-4.8,0-6.4-4-6.4-6.4c0-4,2.4-9.6,14.4-9.6
		C380,263.2,380,263.2,380.8,263.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M412,291.2c4.8-26.4,5.6-48,16.8-44c1.6-10.4,4-14.4,5.6-18.4c0,0-0.8,0-3.2,0
		c-7.2,0-12.8,9.6-12.8,9.6l1.6-8.8h-15.2L394.4,292H412V291.2z"
			/>
			<path
				fill="#DCE5E5"
				d="M180,279.2c0,9.6,5.6,13.6,12.8,13.6c5.6,0,10.4-1.6,12-2.4l2.4-13.6c-0.8,0-2.4,0.8-4,0.8
		c-5.6,0-6.4-3.2-5.6-4.8l4.8-28h8.8l2.4-15.2h-8l1.6-9.6"
			/>
			<path
				fill="#DCE5E5"
				d="M218.4,264.8c0,22.4,7.2,28,20,28c12,0,16.8-2.4,16.8-2.4l3.2-15.2c0,0-8.8,4-16.8,4
		c-17.6,0-14.4-12.8-14.4-12.8H260c0,0,2.4-10.4,2.4-14.4c0-10.4-5.6-23.2-23.2-23.2C222.4,227.2,218.4,244.8,218.4,264.8z
		 M238.4,241.6c8.8,0,10.4,10.4,10.4,11.2H228C228,252,229.6,241.6,238.4,241.6z"
			/>
			<path
				fill="#DCE5E5"
				d="M340,290.4l3.2-17.6c0,0-8,4-13.6,4c-11.2,0-16-8.8-16-18.4c0-19.2,9.6-29.6,20.8-29.6
		c8,0,14.4,4.8,14.4,4.8l2.4-16.8c0,0-9.6-4-18.4-4c-18.4,0-28.8,16-28.8,46.4c0,20,1.6,33.6,20.8,33.6
		C331.2,292.8,340,290.4,340,290.4z"
			/>
			<path
				fill="#DCE5E5"
				d="M95.2,244.8c0,0,7.2-3.2,17.6-3.2c5.6,0,10.4,0.8,10.4,5.6c0,3.2-0.8,4-0.8,4s-4.8,0-7.2,0
		c-13.6,0-28.8,5.6-28.8,24c0,14.4,9.6,17.6,15.2,17.6c11.2,0,16-7.2,16.8-7.2l-0.8,6.4H132l6.4-44c0-18.4-16-19.2-22.4-19.2
		 M128,263.2c0,2.4-9.6,15.2-19.2,15.2c-4.8,0-6.4-4-6.4-6.4c0-4,2.4-9.6,14.4-9.6C119.2,263.2,128,263.2,128,263.2z"
			/>
			<path
				fill="#DCE5E5"
				d="M136,290.4c0,0,4.8,1.6,18.4,1.6c4,0,24,0.8,24-20.8c0-20-19.2-16-19.2-24c0-4,3.2-5.6,8.8-5.6
		c2.4,0,11.2,0.8,11.2,0.8l2.4-14.4c0,0-5.6-1.6-15.2-1.6c-12,0-16,4.8-16,20.8c0,18.4,12,16.8,12,24c0,4.8-5.6,5.6-9.6,5.6"
			/>
			<path
				fill="#DCE5E5"
				d="M469.6,236c0,0-6.4-8-15.2-8c-14.4,0-19.2,17.6-19.2,38.4c0,12.8-1.6,26.4,12,26.4
		c9.6,0,15.2-6.4,15.2-6.4l-0.8,5.6h16l12-76.8 M468.8,256.8c0,8.8-7.2,20-16,20c-5.6,0-8.8-4.8-8.8-12.8c0-12.8,5.6-20.8,12.8-20.8
		C462.4,243.2,468.8,247.2,468.8,256.8z"
			/>
			<path
				fill="#DCE5E5"
				d="M29.6,291.2l9.6-57.6l1.6,57.6H52l20.8-57.6L64,291.2h16.8l12.8-76.8h-20l-22.4,47.2l-0.8-47.2h-8.8
		l-27.2,76.8H29.6z"
			/>
			<path
				fill="#DCE5E5"
				d="M260.8,291.2h16.8c4.8-26.4,5.6-48,16.8-44c1.6-10.4,4-14.4,5.6-18.4c0,0-0.8,0-3.2,0
		c-7.2,0-12.8,9.6-12.8,9.6l1.6-8.8"
			/>
			<path
				fill="#DCE5E5"
				d="M355.2,244.8c0,0,7.2-3.2,17.6-3.2c5.6,0,10.4,0.8,10.4,5.6c0,3.2-0.8,4-0.8,4s-4.8,0-7.2,0
		c-13.6,0-28.8,5.6-28.8,24c0,14.4,9.6,17.6,15.2,17.6c11.2,0,16-7.2,16.8-7.2l-0.8,6.4H392l6.4-44c0-18.4-16-19.2-22.4-19.2
		 M388,263.2c0,2.4-9.6,15.2-19.2,15.2c-4.8,0-6.4-4-6.4-6.4c0-4,2.4-9.6,14.4-9.6C380,263.2,388,263.2,388,263.2z"
			/>
			<path
				fill="#DCE5E5"
				d="M395.2,291.2H412c4.8-26.4,5.6-48,16.8-44c1.6-10.4,4-14.4,5.6-18.4c0,0-0.8,0-3.2,0
		c-7.2,0-12.8,9.6-12.8,9.6l1.6-8.8"
			/>
		</svg>
	);
}
