import { dictionary, compact, PaymentStateType, PaymentMethodType } from "common";

export type CheckoutState =
	| DefaultCheckoutState
	| FetchingCheckoutState
	| ValidatingCheckoutState
	| SubmittingCheckoutState
	| SubmittingErrorCheckoutState
	| FinishedCheckoutState;

export enum CheckoutStateType {
	DEFAULT = "CHECKOUT_STATE_TYPE/DEFAULT",
	FETCHING = "CHECKOUT_STATE_TYPE/FETCHING",
	VALIDATING = "CHECKOUT_STATE_TYPE/VALIDATING",
	SUBMITTING = "CHECKOUT_STATE_TYPE/SUBMITTING",
	SUBMITTING_ERROR = "CHECKOUT_STATE_TYPE/SUBMITTING/ERROR",
	FINISHED = "CHECKOUT_STATE_TYPE/SUBMITTING/FINISHED",
}

export interface DefaultCheckoutState {
	type: CheckoutStateType.DEFAULT;
	request: RequestData;
}
export interface FetchingCheckoutState {
	type: CheckoutStateType.FETCHING;
	request: RequestData;
}
export interface ValidatingCheckoutState {
	type: CheckoutStateType.VALIDATING;
	request: RequestData;
}
export interface SubmittingCheckoutState {
	type: CheckoutStateType.SUBMITTING;
	request: RequestData;
}
export interface SubmittingErrorCheckoutState {
	type: CheckoutStateType.SUBMITTING_ERROR;
	error: Error;
	request: RequestData;
}

export interface FinishedCheckoutState {
	type: CheckoutStateType.FINISHED;
	request: RequestData;
}

export const initialState = (): DefaultCheckoutState => ({
	type: CheckoutStateType.DEFAULT,
	request: {
		products: {},
		paymentState: PaymentStateType.PENDING,
	},
});

export interface RequestData {
	locationId?: string;
	products: dictionary.Dictionary<number>;
	name?: string;
	email?: string;
	phone?: string;
	date?: Date;
	time?: string;
	message?: string;
	privacyCheck?: boolean;
	paymentMethod?: PaymentMethodType;
	paymentState: PaymentStateType;
}

export interface ValidRequestData {
	locationId: string;
	products: dictionary.Dictionary<number>;
	name: string;
	email: string;
	phone?: string;
	date: Date;
	time: string;
	message?: string;
	paymentMethod: PaymentMethodType;
	paymentState: PaymentStateType;
}

export interface ValidatedRequestData {
	locationId?: string;
	products?: string;
	name?: string;
	email?: string;
	phone?: string;
	date?: string;
	time?: string;
	message?: string;
	privacyCheck?: string;
	paymentMethod?: string;
	paymentState?: string;
}

export const validateRequest = (req: RequestData): ValidatedRequestData | undefined => {
	const validateLocationId = (locationId?: string): string | undefined => {
		if (locationId === undefined) {
			return "Standort fehlt!";
		}
	};

	const validateProducts = (products: dictionary.Dictionary<number | undefined>): string | undefined => {
		const hasNoProducts = compact(dictionary.toValues(products)).length === 0;
		if (hasNoProducts) {
			return "Keine Produkte im Warenkorb!";
		}
	};

	const validateName = (name?: string): string | undefined => {
		if (name === undefined || name.length === 0) {
			return "Name fehlt!";
		}
	};

	const validateEmail = (email?: string): string | undefined => {
		if (email === undefined || email.length === 0) {
			return "E-Mail fehlt!";
		}
	};

	const validateDate = (date?: Date): string | undefined => {
		if (date === undefined) {
			return "Datum fehlt!";
		}
	};

	const validateTime = (time?: string): string | undefined => {
		if (time === undefined) {
			return "Uhrzeit fehlt!";
		}
		if (!/^\d{2}:\d{2}$/.test(time)) {
			return "Bitte Formatierung HH:mm einhalten(z.B. 08:00)!";
		}
		const [hours, minutes] = time.split(":").map(Number);
		const given = new Date(2000, 1, 1, hours, minutes);
		const from = new Date(2000, 1, 1, 8);
		const to = new Date(2000, 1, 1, 13);
		if (given < from || given > to) {
			return "Bitte wählen Sie eine Abholzeit von 8 bis 13 Uhr!";
		}
	};

	const validatePaymentMethod = (paymentMethod?: string): string | undefined => {
		if (paymentMethod === undefined) {
			return "Bezahlmethode fehlt!";
		}
	};

	const validatePrivacy = (privacy?: boolean): string | undefined => {
		if (privacy === undefined || !privacy) {
			return "Sie müssen den vonLuck OnlineShop Datenschutzbestimmungen zustimmen!";
		}
	};

	const validatedRequest: ValidatedRequestData = {
		locationId: validateLocationId(req.locationId),
		products: validateProducts(req.products),
		name: validateName(req.name),
		email: validateEmail(req.email),
		date: validateDate(req.date),
		time: validateTime(req.time),
		paymentMethod: validatePaymentMethod(req.paymentMethod),
		privacyCheck: validatePrivacy(req.privacyCheck),
	};
	const hasNoValidationMessages = compact(Object.values(validatedRequest)).length === 0;
	if (hasNoValidationMessages) {
		return undefined;
	} else {
		return validatedRequest;
	}
};

export const validate = (request: RequestData): boolean => {
	const validatedRequest = validateRequest(request);
	return validatedRequest === undefined;
};
