import React from "react";
import { OrderProps } from "../props/checkoutProps";
import { TextInput, Button, Icon, Link } from "common";
import { DatePicker } from "../../../components/datePicker";

export const OrderView: React.FC<OrderProps> = (props) => {
	return (
		<div className="space-y-8">
			<h3 className="w-full text-1 text-3xl">{props.title}</h3>
			<section className="bg-white dark:bg-gray-900 py-4 rounded-lg shadow-lg text-1">
				<div className="order-grid order-grid-sm md:order-grid-md">
					<OrderRow {...props.location}>
						<div className="flex items-center space-x-2">
							<img
								className="w-12 h-12 flex-shrink-0 rounded-md object-cover"
								src={props.location.image.source}
								alt={props.location.image.title}
							/>
							<p className="shrink text-1">{props.location.locationName}</p>
						</div>
					</OrderRow>

					<OrderEntryDivider />

					<OrderRow {...props.name} validation={props.name.input.validation}>
						<TextInput className="input input-md" {...props.name.input} />
					</OrderRow>

					<OrderEntryDivider />

					<OrderRow {...props.email} validation={props.email.input.validation}>
						<input
							type="email"
							className="input input-md"
							value={props.email.input.value}
							placeholder={props.email.input.placeholder}
							onChange={(e) => props.email.input.onChange(e.target.value)}
						/>
					</OrderRow>

					<OrderEntryDivider />

					<OrderRow {...props.phone} validation={props.phone.input.validation}>
						<input
							type="tel"
							className="input input-md"
							value={props.phone.input.value}
							placeholder={props.phone.input.placeholder}
							onChange={(e) => props.phone.input.onChange(e.target.value)}
						/>
					</OrderRow>

					<OrderEntryDivider />

					<p className="pl-4 text-2">{props.title}</p>
					<div className="col-span-2 px-4 sm:pl-0 sm:col-span-1 sm:col-start-2 md:hidden">
						{props.date.input.value && (
							<p className="text-1">
								{props.date.input.value.toLocaleDateString("de-DE", {
									year: "numeric",
									month: "long",
									day: "numeric",
									weekday: "long",
								})}
							</p>
						)}
						{props.date.validation && <p className="text-xs text-red-500">{props.date.validation}</p>}
					</div>
					<div className="sm:hidden col-span-2 pt-2 px-4 space-y-4">
						<DatePicker {...props.date.input} />
						<p className="text-3 text-xs">{props.date.description}</p>
					</div>
					<div className="hidden sm:block md:hidden col-start-2 pt-2 pr-4 space-y-4">
						<DatePicker {...props.date.input} />
						<p className="text-3 text-xs">{props.date.description}</p>
					</div>
					<div className="hidden md:block">
						<>
							<div className="space-y-4">
								{props.date.input.value && (
									<p className="text-1">
										{props.date.input.value.toLocaleDateString("de-DE", {
											year: "numeric",
											month: "long",
											day: "numeric",
											weekday: "long",
										})}
									</p>
								)}
								<DatePicker {...props.date.input} />
							</div>
							{props.date.validation && <p className="text-xs text-red-500">{props.date.validation}</p>}
						</>
					</div>
					<p className="hidden md:block pr-4 text-3 text-xs">{props.date.description}</p>

					<OrderEntryDivider />

					<OrderRow {...props.time} validation={props.time.input.validation}>
						<input
							type="text"
							pattern="[\d:]*"
							className="input input-md"
							value={props.time.input.value}
							placeholder={props.time.input.placeholder}
							onChange={(e) => props.time.input.onChange(e.target.value)}
							onBlur={(_) => {
								if (/^\d$/.test(props.time.input.value)) {
									const newValue = "0" + props.time.input.value + ":00";
									props.time.input.onChange(newValue);
								} else if (/^\d{2}$/.test(props.time.input.value)) {
									const newValue = props.time.input.value + ":00";
									props.time.input.onChange(newValue);
								} else if (/^\d:$/.test(props.time.input.value)) {
									const newValue = "0" + props.time.input.value + "00";
									props.time.input.onChange(newValue);
								} else if (/^\d{2}:$/.test(props.time.input.value)) {
									const newValue = props.time.input.value + "00";
									props.time.input.onChange(newValue);
								} else if (/^\d{2}:\d$/.test(props.time.input.value)) {
									const newValue = props.time.input.value + "0";
									props.time.input.onChange(newValue);
								} else if (/^\d:\d{2}$/.test(props.time.input.value)) {
									const newValue = "0" + props.time.input.value;
									props.time.input.onChange(newValue);
								}
							}}
						/>
					</OrderRow>

					<OrderEntryDivider />

					<OrderRow {...props.message} description="">
						<textarea
							className="w-full h-min input input-md"
							rows={5}
							value={props.message.input.value}
							placeholder={props.message.input.placeholder}
							onChange={(e) => props.message.input.onChange(e.target.value)}
						/>
					</OrderRow>

					<OrderEntryDivider />

					<OrderRow {...props.paymentMethod} validation={props.paymentMethod.validation}>
						<div className="flex items-center space-x-4">
							{props.paymentMethod.options.map((paymentOptionProps, index) => {
								if (paymentOptionProps.isSelected) {
									return (
										<Button key={index} {...paymentOptionProps} className="btn-1 btn-md">
											{paymentOptionProps.icon && (
												<Icon type={paymentOptionProps.icon} className="h-6" />
											)}
											<span className={paymentOptionProps.icon ? "sr-only" : ""}>
												{paymentOptionProps.title}
											</span>
										</Button>
									);
								} else {
									return (
										<Button key={index} {...paymentOptionProps} className="btn-2 btn-md">
											{paymentOptionProps.icon && (
												<Icon type={paymentOptionProps.icon} className="h-6" />
											)}
											<span className={paymentOptionProps.icon ? "sr-only" : ""}>
												{paymentOptionProps.title}
											</span>
										</Button>
									);
								}
							})}
						</div>
					</OrderRow>

					<OrderEntryDivider />

					<div className="col-span-2 md:col-start-2 px-4 md:pl-0">
						<div className="flex flex-col space-y-2">
							<div className="flex space-x-4">
								<input
									className="m-w-4 flex-shrink-0 pl-2"
									type="checkbox"
									checked={props.privacy.check.value}
									onChange={() => props.privacy.check.onToggle()}
								/>
								<Description>
									<span>{props.privacy.message}</span>
									<Link {...props.privacy.toPrivacyPage} className="underline">
										{props.privacy.toPrivacyPage.title}
									</Link>
								</Description>
							</div>
							{props.privacy.check.validation && (
								<Validation>{props.privacy.check.validation}</Validation>
							)}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
const OrderRow: React.FC<{ title: string; validation?: string; description: string }> = (props) => (
	<>
		<p className="col-span-2 sm:col-span-1 px-4 sm:pr-0 text-2">{props.title}</p>
		<div className="col-span-2 space-y-2 px-4 sm:pl-0 sm:col-span-1 sm:col-start-2 md:hidden">
			<>
				{props.children}
				{props.validation && <p className="text-xs text-red-500">{props.validation}</p>}
			</>
		</div>
		<div className="sm:hidden col-span-2 pt-2 px-4">
			<p className="text-3 text-xs">{props.description}</p>
		</div>
		<div className="hidden sm:block md:hidden col-start-2 pt-2 pr-4">
			<p className="text-3 text-xs">{props.description}</p>
		</div>
		<div className="hidden md:block space-y-2">
			<>
				{props.children}
				{props.validation && <p className="text-xs text-red-500">{props.validation}</p>}
			</>
		</div>
		<p className="hidden md:block pr-4 text-3 text-xs">{props.description}</p>
	</>
);
const Validation: React.FC = (props) => <p className="text-xs text-red-500">{props.children}</p>;
const Description: React.FC = (props) => <p className="text-3 text-xs">{props.children}</p>;
const OrderEntryDivider: React.FC = () => (
	<div className="col-span-2 md:col-span-3 my-2 border-b border-gray-200 dark:border-gray-600" />
);
