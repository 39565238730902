import * as common from "common";
import React from "react";

export const GreenButton: React.FC<common.ButtonProps> = (props) => {
	return (
		<common.Button
			{...props}
			className="h-max min-w-8 p-2 rounded-md border border-gray-200 dark:border-gray-800 hover:border-green-500 hover:text-gray-100 hover:bg-green-400 dark:hover:text-gray-200 dark:hover:bg-green-600 outline-none focus:outline-none"
		>
			{props.icon && <common.Icon type={props.icon} className="w-4 h-4" />}
		</common.Button>
	);
};
export const RedButton: React.FC<common.ButtonProps> = (props) => {
	return (
		<common.Button
			{...props}
			className="h-max min-w-8 p-2 rounded-md border border-gray-200 dark:border-gray-800 hover:border-red-500 hover:text-gray-100 hover:bg-red-400 dark:hover:text-gray-200 dark:hover:bg-red-600 outline-none focus:outline-none"
		>
			{props.icon && <common.Icon type={props.icon} className="w-4 h-4" />}
		</common.Button>
	);
};
