import { dictionary, Product } from "common";

export type ProductsState = DataProductsState | FetchingProductsState | ErrorProductsState;

export enum ProductsStateType {
	DATA = "PRODUCTS_STATE_TYPE/DATA",
	FETCHING = "PRODUCTS_STATE_TYPE/FETCHING",
	ERROR = "PRODUCTS_STATE_TYPE/ERROR",
}

export interface DataProductsState {
	type: ProductsStateType.DATA;
	products: Products;
}

export interface FetchingProductsState {
	type: ProductsStateType.FETCHING;
	products?: Products;
}

export interface ErrorProductsState {
	type: ProductsStateType.ERROR;
	error: Error;
}

export const initialState = (): ProductsState => ({
	type: ProductsStateType.FETCHING,
});

export type Products = dictionary.Dictionary<Product>;
