import * as common from "common";
import React from "react";
import { LocationsStateType } from "../locations/state";
import { ApplicationActionType } from "../../applicationState/actions";
import { OpeningHoursStateType } from "../openingHours/state";
import { store, useAppState, selectAppState } from "../../applicationState";
import { LoadingView } from "../../components/loadingView";
import { IntegrationTestWrapper } from "../../utils/integrationTests";

export const ResolveState: React.FC = (props) => {
	const state = useAppState(selectAppState);

	React.useEffect(() => {
		store.dispatch({
			type: ApplicationActionType.LOCATIONS_FETCH,
		});
		store.dispatch({
			type: ApplicationActionType.PRODUCTS_FETCH_MORE,
		});
		store.dispatch({
			type: ApplicationActionType.CHECKOUT_LOAD_FROM_LOCAL_STORAGE,
		});
		store.dispatch({
			type: ApplicationActionType.OPENING_HOURS_FETCH,
		});
		// eslint-disable-next-line
	}, []);

	const locationsIsFetching = state.locations.type === LocationsStateType.FETCHING;
	const openingHoursIsFetching = state.openingHours.type === OpeningHoursStateType.FETCHING;
	const isFetching = locationsIsFetching || openingHoursIsFetching;
	if (isFetching) {
		return (
			<IntegrationTestWrapper id={common.integrationTests.onlineShop.RESOLVE_STATE_LOADING_VIEW}>
				<LoadingView />
			</IntegrationTestWrapper>
		);
	} else {
		return <>{props.children}</>;
	}
};
