export const TEAM_ID = "6GQ9TuFu28TMikLDH";
export const DATABASE_ID_PRODUCTS = "ggfycaud6738";
export const DATABASE_ID_WEBSITE = "uu2bf238tsc3";
export const TABLE_ID_LOCATIONS = "B";
export const TABLE_ID_IMAGES = "I";
export const TABLE_ID_JOBS = "A";
export const TABLE_ID_OPENING_HOURS = "J";
export const TABLE_ID_FAQ = "G";
export const TABLE_ID_ENVIRONMENT_VARIABLES = "P";
export const TABLE_ID_PRODUCTS = "A";
export const TABLE_ID_COSTUMER_ORDERS = "BB";
export const TABLE_ID_PRODUCT_TO_CUSTOMER_ORDER = "CB";

export const recordsEndpoint = (teamId: string, databaseId: string, tableId: string): string => {
	return `https://api.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}/tables/${tableId}/records`;
};

export const recordEndpoint =
	(teamId: string, databaseId: string, tableId: string) =>
	(recordId: string): string => {
		return `${recordsEndpoint(teamId, databaseId, tableId)}/${recordId}`;
	};
