import * as React from "react";
import { PageWrapperFooterProps, PageWrapperFooter } from "./pageWrapperFooter";
import { PageWrapperHeaderProps, PageWrapperHeader } from "./pageWrapperHeader";

export interface PageWrapperProps {
	pageHeader: PageWrapperHeaderProps;
	pageFooter: PageWrapperFooterProps;
}

export const PageWrapper: React.FC<PageWrapperProps> = (props) => {
	return (
		<div className="min-h-screen">
			<PageWrapperHeader {...props.pageHeader} />
			<div className="isolate w-full max-w-7xl min-h-screen mx-auto px-4 sm:px-6 pt-24 lg:px-8">
				{props.children}
			</div>
			<PageWrapperFooter {...props.pageFooter} />
		</div>
	);
};
