import * as React from "react";
import { IconProps } from "../icons/models/domain";

export const Croissant: React.FC<IconProps> = (props) => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 75 75"
			fill="currentColor"
			stroke="none"
		>
			<path
				className="animate-pulse"
				d="M 51.953 0.1462 C 51.7484 0.1461 51.5566 0.1682 51.3789 0.2146 C 49.1553 0.7933 47.7211 5.8083 47.777 12.3136 C 50.9094 9.4845 56.3314 6.1222 60.322 5.7229 C 57.1031 2.2787 53.9307 0.1476 51.9529 0.1466 Z"
			/>
			"
			<path
				className="animate-pulse"
				d="M 61.0892 8.0252 C 60.7472 8.0218 60.4265 8.0522 60.1354 8.1014 C 54.8444 8.9956 44.7444 16.0792 44.8334 21.5354 C 46.0358 20.8965 47.3962 20.32 48.9098 19.813 C 50.3748 19.3206 51.1145 21.5355 49.652 22.0255 C 46.0011 23.2498 41.4432 25.5861 40.7679 29.3677 C 51.7789 28.6048 68.2219 38.1948 71.9479 44.3977 C 74.0098 39.8672 75.7464 32.4187 74.6723 27.3247 C 73.935 23.8306 72.0689 21.6884 68.97 20.776 C 65.5324 19.7645 59.625 19.7845 54.268 20.8258 C 52.7526 21.1206 52.3054 18.8304 53.8215 18.5355 C 59.1696 17.4954 64.8815 17.418 68.7335 18.3037 C 66.933 9.7354 63.4836 8.0497 61.0894 8.0257 Z"
			/>
			<path
				className="animate-pulse"
				d="M 42.2472 31.6842 C 39.0318 31.6588 36.2022 32.4672 34.2048 34.4646 C 25.9814 42.6874 35.7189 60.7826 43.425 68.4886 C 46.169 71.2332 49.5543 72.0162 53.488 70.8162 C 62.2792 68.1336 77.44 52.6672 68.342 43.5692 C 62.9761 38.2038 51.136 31.7542 42.247 31.6842 Z"
			/>
			<path
				className="animate-pulse"
				d="M 28.7982 41.0055 C 21.1353 42.9805 18.3852 61.4905 20.6298 69.1155 C 22.5428 75.6143 33.8038 75.917 41.2828 73.3553 C 42.3569 72.9978 43.325 72.5131 44.2514 72.0919 C 43.3791 71.5685 42.5519 70.9167 41.7744 70.1393 C 35.3224 63.6873 27.8164 50.6133 28.7984 41.0053 Z"
			/>
			<path
				className="animate-pulse"
				d="M 21.3922 44.9792 C 15.9274 44.8862 8.8482 55.0022 7.9552 60.2802 C 7.5619 62.609 8.3648 66.8225 18.1572 68.8796 C 16.7402 62.726 17.7616 51.8046 21.3919 44.9796 Z"
			/>
			<path
				className="animate-pulse"
				d="M 11.5621 47.9229 C 5.354 47.9582 0.6291 49.3691 0.0691 51.5232 C -0.4251 53.4198 1.778 56.9162 5.5785 60.468 C 5.973 56.5131 9.3319 51.0626 12.1677 47.923 C 11.9644 47.9213 11.7627 47.9219 11.5625 47.923 Z"
			/>
		</svg>
	);
};
