import { pad } from "../../utils";

export type DateIsInRange = (date: Date, from: Date, to: Date) => boolean;
export const dateIsInRange: DateIsInRange = (date, from, to) => {
	return from <= date && to > date;
};

export type GetAllDatesInRange = (from: Date, to: Date) => Array<Date>;
export const getAllDatesInRange: GetAllDatesInRange = (from, to) => {
	const addOneDate = (dates: Array<Date>, to: Date): Array<Date> => {
		const lastDateFromDates: Date | undefined = dates[dates.length - 1];
		if (lastDateFromDates === undefined) return [];
		const nextDate = addDays(lastDateFromDates, 1);
		if (nextDate >= to) {
			return dates;
		} else {
			const newDates = [...dates, nextDate];
			return addOneDate(newDates, to);
		}
	};
	return addOneDate([from], to);
};

export const getFirstDateOfTheMonth = (date: Date): Date => {
	return DateWithoutTime.fromParts(date.getFullYear(), date.getMonth() + 1, 1);
};

export const getLastDateOfTheMonth = (date: Date): Date => {
	return DateWithoutTime.fromParts(date.getFullYear(), date.getMonth() + 2, 0);
};

export const getFirstDateOfTheWeek = (date: Date): Date => {
	const currentWeekDay = date.getDay();
	switch (currentWeekDay) {
		case 0:
			return subtractDays(date, 6);
		case 1:
			return date;
		default:
			return subtractDays(date, currentWeekDay - 1);
	}
};

export const getLastDateOfTheWeek = (date: Date): Date => {
	const currentWeekDay = date.getDay();
	switch (currentWeekDay) {
		case 0:
			return date;
		case 1:
			return addDays(date, 6);
		default:
			return addDays(date, 8 - currentWeekDay);
	}
};

export const getWeekDay = (date: Date): number => {
	const weekday = date.getDay();
	return weekday === 0 ? 7 : weekday;
};

export const addDays = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() + days);
	return DateWithoutTime.toWithoutTime(newDate);
};

export const subtractDays = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() - days);
	return DateWithoutTime.toWithoutTime(newDate);
};

export const getAllYearsInRange = (from: Date, to: Date): Array<number> => {
	const fromYear = from.getFullYear();
	const toYear = to.getFullYear() + 1;
	return Array(toYear - fromYear)
		.fill(null)
		.map((_, index) => {
			return fromYear + index;
		});
};

export const DateWithoutTime = {
	toWithoutTime: (value: Date): Date => {
		const date = new Date(value);
		date.setHours(0, 0, 0, 0);
		return date;
	},
	now: (): Date => {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		return date;
	},
	fromParts: (year: number, month: number, day): Date => {
		return new Date(year, month - 1, day);
	},
	fromString: (value: string): Date => {
		const date = new Date(value);
		date.setHours(0, 0, 0, 0);
		return date;
	},
};

export const toDateString = (date: Date): string => {
	return `${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}`;
};
