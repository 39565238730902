import * as React from "react";
import { IconProps } from "../../models/domain";

export default function MenuBook(props: IconProps) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 100 100"
			stroke="none"
			fill="currentColor"
		>
			<path d="M44.1,30.5c-5.1,2.3-8.5,7.2-8.7,12.9L35,55.3c-0.1,2.1,1.6,3.8,3.7,3.8h1.5l-2.7,17.5c-0.4,2.7,1.7,5.1,4.4,5.1     c2.4,0,4.4-2,4.4-4.4V31.8C46.2,30.8,45.1,30.1,44.1,30.5z" />
			<path d="M63.4,30.4c-0.9,0-1.6,0.7-1.6,1.6v11.9c0,0.4-0.4,0.8-0.8,0.8h-1c-0.4,0-0.8-0.4-0.8-0.8V31.9c0-0.9-0.7-1.6-1.6-1.6     c-0.9,0-1.6,0.7-1.6,1.6v11.9c0,0.4-0.4,0.8-0.8,0.8h-1c-0.4,0-0.8-0.4-0.8-0.8V31.9c0-0.9-0.7-1.6-1.6-1.6     c-0.9,0-1.6,0.7-1.6,1.6v13.9c0,2,1.6,3.7,3.7,3.7h0.8L53.2,77c-0.2,2.6,1.9,4.7,4.4,4.7h0.1c2.6,0,4.6-2.2,4.4-4.7l-1.7-27.5     h0.8l0,0c2,0,3.7-1.6,3.7-3.7V31.9C65,31.1,64.3,30.4,63.4,30.4z" />
			<path d="M75.9,14.6h-1.3v-4.4c0-2.4-1.1-4.6-3-6.1c-1.9-1.5-4.3-2-6.6-1.4l-42.7,11c-3.4,0.9-5.8,3.9-5.8,7.5v68.6     c0,4.3,3.5,7.7,7.7,7.7h51.8c4.2,0,7.7-3.5,7.7-7.7V22.3C83.6,18.1,80.1,14.6,75.9,14.6z M66.3,8.2c0.9-0.2,1.5,0.1,1.8,0.4     c0.5,0.4,0.8,1,0.8,1.6v4.4H41.5L66.3,8.2z M78,89.8c0,1.1-0.9,2.1-2.1,2.1H24.1c-1.1,0-2.1-0.9-2.1-2.1V21.2     c0-0.4,0.1-0.7,0.2-1h53.6c1.1,0,2.1,0.9,2.1,2.1V89.8z" />
		</svg>
	);
}
