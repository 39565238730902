import "./styles";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "common";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { store } from "./applicationState";
import { Router } from "./router";
import { PageWrapper } from "./features/pageWrapper";
import { ForceLocationIdInState } from "./features/forceLocationIdInState";
import { ProductsList } from "./features/productsList";
import { Locations } from "./features/locations";
import { Checkout } from "./features/checkout";
import { EnforceEnvironmentVariables } from "./features/environmentVariables";
import { ResolveState } from "./features/resolveState";
import { WithPaypalScript } from "./features/paypalButton";
import reportWebVitals from "./reportWebVitals";
import { PATH_SET_LOCATIONS, PATH_CHECKOUT, PATH_PRODUCTS } from "./variables/urls";

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<Router>
					<div className="m-w-[320px] w-screen min-h-screen bg pattern-moroccan">
						<EnforceEnvironmentVariables>
							<ResolveState>
								<PageWrapper>
									<Routes>
										<Route path={PATH_SET_LOCATIONS} element={<Locations />} />
										<Route
											path={PATH_PRODUCTS}
											element={
												<ForceLocationIdInState>
													<ProductsList />
												</ForceLocationIdInState>
											}
										/>
										<Route
											path={PATH_CHECKOUT}
											element={
												<ForceLocationIdInState>
													<WithPaypalScript>
														<Checkout />
													</WithPaypalScript>
												</ForceLocationIdInState>
											}
										/>
									</Routes>
								</PageWrapper>
							</ResolveState>
						</EnforceEnvironmentVariables>
					</div>
				</Router>
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
