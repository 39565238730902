import { Location } from "common";
import { LocationsStateType, LocationsState } from "../state";
import { ApplicationState } from "../../../applicationState";

export const selectLocationsState = (state: ApplicationState): LocationsState => state.locations;

export const selectLocationForId =
	(locationId: string) =>
	(state: { locations: LocationsState }): Location | undefined => {
		if (state.locations.type === LocationsStateType.DATA) {
			return state.locations.locations[locationId];
		} else {
			return undefined;
		}
	};
