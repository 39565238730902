import * as SE from "redux-saga/effects";
import { LocationResponse, ImageResponse, toLocation, toImage } from "common";
import { ApplicationActionType } from "../applicationState/actions";

export const makeFetchLocationsWorker = (
	fetchAllLocations: () => Promise<Array<LocationResponse>>,
	fetchImageById: (imageId: string) => Promise<ImageResponse>
) => {
	return function* worker() {
		while (true) {
			try {
				yield SE.take(ApplicationActionType.LOCATIONS_FETCH);
				const response: Array<LocationResponse> = yield SE.call(fetchAllLocations);
				const bannerImages: Array<ImageResponse> = yield SE.all(
					response.map((location) => {
						return fetchImageById(String(location.fields.bannerImage));
					})
				);
				const locations = response.map((locationResponse, index) =>
					toLocation({
						location: locationResponse,
						bannerImage: toImage(bannerImages[index]),
						images: [],
						openingHours: [],
					})
				);
				yield SE.put({
					type: ApplicationActionType.LOCATIONS_SET_MANY,
					locations: locations,
				});
			} catch (error) {
				yield SE.put({
					type: ApplicationActionType.LOCATIONS_SET_ERROR,
					error: error,
				});
			}
		}
	};
};
