import React from "react";
import { useAppState, store } from "../../applicationState";
import { selectEnvironmentVariables } from "./selectors";
import { MaintenanceView } from "./maintenanceView";
import { ApplicationActionType } from "../../applicationState/actions";
import { EnvironmentVariablesStateType } from "./state";
import { LoadingView } from "../../components/loadingView";

export const EnforceEnvironmentVariables: React.FC = (props) => {
	const state = useAppState(selectEnvironmentVariables);

	React.useEffect(() => {
		store.dispatch({ type: ApplicationActionType.ENV_VARS_FETCH_ALL });
	}, []);

	switch (true) {
		case state.type === EnvironmentVariablesStateType.FETCHING:
			return <LoadingView />;
		case state.variables["MODE"] === "MAINTENANCE":
			return <MaintenanceView />;
		default:
			return <>{props.children}</>;
	}
};
