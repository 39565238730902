import * as React from "react";
import { IconProps } from "../../models/domain";

export default function Maestro(props: IconProps) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 504 504">
			<path
				fill="#07A9E2"
				d="M252,364c30.4-27.2,49.6-67.2,49.6-112s-19.2-84-49.6-112c-26.4-24-62.4-39.2-100.8-39.2
	C68,100.8,0,168.8,0,252s68,150.4,151.2,150.4C189.6,403.2,225.6,388,252,364L252,364z"
			/>
			<path
				fill="#009DDD"
				d="M0,252c0,83.2,68,150.4,151.2,150.4c39.2,0,74.4-14.4,100.8-39.2l0,0c30.4-27.2,49.6-67.2,49.6-112
	S282.4,168,252,140c-26.4-24-62.4-39.2-100.8-39.2"
			/>
			<path
				fill="#0284CC"
				d="M252,140c-26.4-24-62.4-39.2-100.8-39.2c-2.4,0-4.8,0-7.2,0C96.8,135.2,66.4,190.4,66.4,252
	s30.4,116.8,76.8,150.4c2.4,0,4.8,0,7.2,0c39.2,0,74.4-14.4,100.8-39.2c30.4-27.2,49.6-67.2,49.6-112S282.4,168,252,140z"
			/>
			<path
				fill="#FF2957"
				d="M353.6,100.8c-39.2,0-74.4,14.4-101.6,39.2c-5.6,4.8-10.4,10.4-15.2,16h30.4c4,4.8,8,10.4,11.2,16
	h-53.6c-3.2,5.6-6.4,10.4-8.8,16h71.2c2.4,5.6,4.8,10.4,6.4,16h-83.2c-1.6,5.6-3.2,10.4-4.8,16h92.8c2.4,10.4,3.2,20.8,3.2,32
	c0,16.8-2.4,32.8-8,48h-83.2c1.6,5.6,4,10.4,6.4,16H288c-2.4,5.6-5.6,11.2-8.8,16h-53.6c3.2,5.6,7.2,11.2,11.2,16h30.4
	c-4.8,5.6-9.6,11.2-15.2,16c27.2,24,62.4,39.2,101.6,39.2c83.2,0,150.4-67.2,150.4-150.4C504,168.8,436.8,100.8,353.6,100.8
	L353.6,100.8z"
			/>
			<path
				fill="#ED1C2E"
				d="M353.6,100.8L353.6,100.8c-39.2,0-74.4,14.4-101.6,39.2c-5.6,4.8-10.4,10.4-15.2,16h30.4
	c4,4.8,8,10.4,11.2,16h-53.6c-3.2,5.6-6.4,10.4-8.8,16h71.2c2.4,5.6,4.8,10.4,6.4,16h-83.2c-1.6,5.6-3.2,10.4-4.8,16h92.8
	c2.4,10.4,3.2,20.8,3.2,32c0,16.8-2.4,32.8-8,48h-83.2c1.6,5.6,4,10.4,6.4,16H288c-2.4,5.6-5.6,11.2-8.8,16h-53.6
	c3.2,5.6,7.2,11.2,11.2,16h30.4c-4.8,5.6-9.6,11.2-15.2,16c27.2,24,62.4,39.2,101.6,39.2c83.2,0,150.4-67.2,150.4-150.4"
			/>
			<path fill="#0466B5" d="M252,364c30.4-27.2,49.6-67.2,49.6-112s-19.2-84-49.6-112V364L252,364z" />
			<path
				fill="#FFFFFF"
				d="M452,284.8c0-2.4,2.4-4.8,4.8-4.8s4.8,2.4,4.8,4.8s-2.4,4.8-4.8,4.8C454.4,289.6,452,287.2,452,284.8
	L452,284.8z M456.8,288.8c2.4,0,3.2-1.6,3.2-4s-1.6-3.2-3.2-3.2c-2.4,0-4,1.6-4,3.2S455.2,288.8,456.8,288.8L456.8,288.8z
	 M456.8,286.4H456v-4h1.6c0.8,0,0.8,0,0.8,0l0.8,0.8c0,0.8,0,0.8-0.8,0.8l0.8,1.6h-0.8l-0.8-1.6h-0.8L456.8,286.4L456.8,286.4z
	 M456.8,284L456.8,284c0.8,0,0.8,0,0.8,0s0,0,0-0.8l0,0h-0.8l0,0V284L456.8,284z"
			/>
			<path
				fill="#D1094B"
				d="M252,140c-5.6,4.8-10.4,10.4-15.2,16h30.4c4,4.8,8,10.4,11.2,16h-53.6c-3.2,5.6-6.4,10.4-8.8,16h71.2
	c2.4,5.6,4.8,10.4,6.4,16h-83.2c-1.6,5.6-3.2,10.4-4.8,16h92.8c2.4,10.4,3.2,20.8,3.2,32c0,16.8-2.4,32.8-8,48h-83.2
	c1.6,5.6,4,10.4,6.4,16H288c-2.4,5.6-5.6,11.2-8.8,16h-53.6c3.2,5.6,7.2,11.2,11.2,16h30.4c-4.8,5.6-9.6,11.2-15.2,16
	c27.2,24,62.4,39.2,101.6,39.2c2.4,0,4.8,0,7.2,0c46.4-33.6,76.8-88.8,76.8-150.4S407.2,136,360.8,102.4c-2.4,0-4.8,0-7.2,0
	C314.4,100.8,279.2,116,252,140z"
			/>
			<polygon
				fill="#FFFFFF"
				points="132,289.6 112.8,289.6 124,231.2 98.4,289.6 81.6,289.6 78.4,231.2 67.2,289.6 50.4,289.6
	64.8,212.8 94.4,212.8 96,260.8 116.8,212.8 147.2,212.8 "
			/>
			<path
				fill="#DCE5E5"
				d="M113.6,256l11.2-24.8l-8.8,46.4c0.8,4,1.6,8.8,3.2,12.8H132l14.4-76.8h-28
		c-3.2,12-5.6,25.6-5.6,39.2C113.6,253.6,113.6,254.4,113.6,256z"
			/>
			<path
				fill="#DCE5E5"
				d="M341.6,288.8c-5.6,1.6-8.8,2.4-13.6,2.4c-9.6,0-14.4-4.8-14.4-13.6c0-1.6,0-3.2,0.8-5.6l0.8-6.4
		l0.8-4.8l8.8-47.2h18.4l-2.4,14.4h9.6l-2.4,14.4h-9.6l-4.8,25.6c0,0.8-0.8,2.4-0.8,2.4c0,3.2,2.4,4.8,6.4,4.8c2.4,0,4,0,4.8-0.8
		L341.6,288.8L341.6,288.8z"
			/>
			<path
				fill="#DCE5E5"
				d="M396.8,228c-1.6-0.8-1.6-0.8-2.4-0.8s-1.6,0-1.6-0.8c-0.8,0-0.8,0-2.4,0c-6.4,0-10.4,2.4-16,10.4
		l1.6-9.6h-16.8L348,289.6h19.2c6.4-38.4,9.6-44.8,18.4-44.8c0.8,0,1.6,0,2.4,0l2.4,0.8L396.8,228L396.8,228z"
			/>
			<path
				fill="#DCE5E5"
				d="M267.2,247.2c0,8,4.8,13.6,14.4,16.8c8,3.2,8.8,4,8.8,7.2c0,4-3.2,5.6-11.2,5.6
		c-5.6,0-11.2-0.8-16.8-2.4l-2.4,14.4h0.8l3.2,0.8c0.8,0,3.2,0,4.8,0c4.8,0.8,8,0.8,10.4,0.8c20,0,29.6-6.4,29.6-20.8
		c0-8.8-4-13.6-13.6-17.6c-8-3.2-8.8-4-8.8-6.4c0-3.2,3.2-4.8,9.6-4.8c3.2,0,8.8,0,13.6,0.8l2.4-14.4c-4.8-0.8-12-0.8-16.8-0.8
		C274.4,226.4,267.2,236,267.2,247.2L267.2,247.2z"
			/>
			<path
				fill="#DCE5E5"
				d="M192.8,289.6h-15.2v-6.4c-4.8,4.8-11.2,7.2-20,7.2c-10.4,0-16.8-7.2-16.8-16.8c0-15.2,12-24,32.8-24
		c2.4,0,4.8,0,8,0c0.8-2.4,0.8-3.2,0.8-4c0-4-3.2-5.6-12-5.6c-5.6,0-11.2,0.8-15.2,1.6l-2.4,0.8h-1.6l2.4-13.6
		c9.6-2.4,15.2-3.2,22.4-3.2c16,0,24.8,6.4,24.8,18.4c0,3.2,0,5.6-1.6,12.8l-4,22.4l-0.8,4l-0.8,3.2v2.4L192.8,289.6L192.8,289.6z
		 M179.2,261.6c-2.4,0-3.2,0-4,0c-10.4,0-16,3.2-16,9.6c0,4,2.4,6.4,6.4,6.4C173.6,277.6,179.2,271.2,179.2,261.6L179.2,261.6z"
			/>
			<path
				fill="#DCE5E5"
				d="M254.4,288c-6.4,1.6-12.8,2.4-19.2,2.4c-20.8,0-32-9.6-32-28c0-21.6,13.6-36.8,32.8-36.8
		c15.2,0,24.8,8.8,24.8,22.4c0,4.8-0.8,8.8-2.4,15.2h-37.6c0,0.8,0,1.6,0,2.4c0,7.2,5.6,11.2,16,11.2c7.2,0,12.8-0.8,19.2-4
		L254.4,288L254.4,288z M244.8,251.2c0-0.8,0-2.4,0-3.2c0-4.8-3.2-8-8.8-8s-10.4,4-12,11.2H244.8L244.8,251.2z"
			/>
			<path
				fill="#FFFFFF"
				d="M455.2,261.6c-3.2,20.8-17.6,29.6-36.8,29.6c-20.8,0-29.6-12.8-29.6-28.8
		c0-21.6,14.4-36.8,36.8-36.8c19.2,0,29.6,12.8,29.6,28C455.2,257.6,455.2,257.6,455.2,261.6L455.2,261.6z M435.2,253.6
		c0-6.4-2.4-12-10.4-12c-9.6,0-15.2,11.2-15.2,21.6c0,8,4,13.6,10.4,13.6c4,0,12.8-5.6,14.4-15.2C435.2,258.4,435.2,256,435.2,253.6
		L435.2,253.6z"
			/>
			<path
				fill="#FFFFFF"
				d="M389.6,269.6c0.8-5.6,1.6-12,1.6-17.6c0-0.8,0-1.6,0-1.6c-0.8,4-1.6,8-1.6,12.8
		C388.8,264.8,388.8,267.2,389.6,269.6z"
			/>
		</svg>
	);
}
