import { dictionary, Product } from "common";
import { ApplicationState } from "../../../applicationState";
import { validateRequest, RequestData, ValidRequestData } from "../state";

export const selectCheckoutState = <T>(state: { checkout: T }): T => state.checkout;
export const selectCheckoutRequest = <T>(state: { checkout: { request: T } }): T => state.checkout.request;
export const selectValidCheckoutRequest = (state: {
	checkout: { request: RequestData };
}): ValidRequestData | undefined => {
	const isRequestValid = validateRequest(state.checkout.request) === undefined;
	return isRequestValid
		? {
				locationId: state.checkout.request.locationId!,
				products: state.checkout.request.products,
				name: state.checkout.request.name!,
				email: state.checkout.request.email!,
				phone: state.checkout.request.phone,
				date: state.checkout.request.date!,
				time: state.checkout.request.time!,
				message: state.checkout.request.message,
				paymentMethod: state.checkout.request.paymentMethod!,
				paymentState: state.checkout.request.paymentState,
		  }
		: undefined;
};

export const selectAmountForProductId =
	(productId: string) =>
	(state: { request: { products: { [key: string]: number | undefined } } }): number | undefined =>
		state.request.products[productId];

export interface StateWithProductsAndAmountsInCheckout<T> {
	checkout: {
		request: {
			products: { [productId: string]: number | undefined };
		};
	};
	products: {
		products?: { [productId: string]: T };
	};
}
export const selectBasketValue = (state: ApplicationState): number => {
	const allProductsWithAmount = selectProductsWithAmount(state);
	return aggregatePrice(allProductsWithAmount);
};

export const selectProductsWithAmount = (state: ApplicationState): Array<Product & { amount: number }> => {
	const products = state.products["products"] || {};
	return dictionary
		.toKeyValuePairs(state.checkout.request.products)
		.filter(({ value }) => value !== undefined)
		.reduce((allProductsWithAmount: Array<Product & { amount: number }>, { key: productId, value: amount }) => {
			const product = products[productId];
			if (product === undefined) {
				return allProductsWithAmount;
			} else {
				const productWithAmount: Product & { amount: number } = {
					...product,
					amount: amount,
				};
				return [...allProductsWithAmount, productWithAmount];
			}
		}, []);
};

export const aggregatePrice = (products: Array<{ price: number; amount: number }>): number => {
	return products.reduce((basketValue, product) => {
		return basketValue + product.amount * product.price;
	}, 0);
};

export const aggregateAmount = (products: Array<{ amount: number }>): number => {
	return products.reduce((totalAmount, product) => {
		return totalAmount + product.amount;
	}, 0);
};
