import { dictionary as D } from "../models";
import { Headers, Parameters } from "./models";

export const get =
	(fetch: any) =>
	<T>(path: string, parameters?: Parameters, headers?: Headers): Promise<T> => {
		const url = toPathWithQueryParameters(path, parameters);
		return fetch(url, {
			method: "GET",
			headers: {
				...headers,
			},
		});
	};

export const post =
	(fetch: any) =>
	<A, B>(path: string, body: A, parameters?: Parameters, headers?: Headers): Promise<B> => {
		const url = toPathWithQueryParameters(path, parameters);
		return fetch(url, {
			method: "POST",
			headers: {
				...headers,
			},
			body: JSON.stringify(body),
		});
	};

export const toPathWithQueryParameters = (path: string, parameters: Parameters | undefined) => {
	if (parameters === undefined) {
		return path;
	} else {
		const parametersWithoutUndefined = D.fromKeyValuePairs(
			D.toKeyValuePairs(parameters).filter(({ value }) => value !== undefined)
		);
		const url = new URL(path);
		url.search = new URLSearchParams(parametersWithoutUndefined).toString();
		return url.toString();
	}
};
