import { Order, OrderStateType, PaymentMethodType, PaymentStateType, ProductInOrder, Product } from "../domain";
import { normalizePrice } from "./productResponse";

export interface OrderResponse {
	id: number;
	fields: {
		/**
		 * state:
		 * - PENDING = 1
		 * - APPROVED = 2
		 * - PREPARED = 3
		 * - COLLECTED = 4
		 * - CANCELED = 5
		 */
		state: number;
		locationId: number;
		orderPickUpId: string;
		name: string;
		email: string;
		phone: string;
		date: string; // DD/MM/YYYY
		time: string; // 00:00
		/**
		 * paymentMethod:
		 * - AT_LOCATION = 1
		 * - PAYPAL = 2
		 */
		paymentMethod: number;
		/**
		 * paymentState:
		 * - PENDING = 1
		 * - PAYED = 2
		 */
		paymentState: number;
		notes: string;
		product_to_order_ids: Array<number>;
		price: number;
	};
}

export const toOrder = (response: OrderResponse, products: Array<ProductInOrder>): Order => ({
	id: String(response.id),
	name: response.fields.name,
	email: response.fields.email,
	phone: response.fields.phone,
	date: new Date(response.fields.date),
	time: response.fields.time,
	notes: response.fields.notes,
	orderPickUpId: response.fields.orderPickUpId,
	locationId: String(response.fields.locationId),
	state: ((): OrderStateType => {
		return (
			[
				OrderStateType.PENDING,
				OrderStateType.APPROVED,
				OrderStateType.PREPARED,
				OrderStateType.COLLECTED,
				OrderStateType.CANCELED,
			][response.fields.state - 1] || OrderStateType.PENDING
		);
	})(),
	paymentMethod: ((): PaymentMethodType => {
		return (
			[PaymentMethodType.AT_LOCATION, PaymentMethodType.PAYPAL][response.fields.paymentMethod - 1] ||
			PaymentMethodType.AT_LOCATION
		);
	})(),
	paymentState: ((): PaymentStateType => {
		return (
			[PaymentStateType.PENDING, PaymentStateType.PAYED][response.fields.paymentState - 1] ||
			PaymentStateType.PENDING
		);
	})(),
	price: normalizePrice(response.fields.price),
	products: products,
});

export interface ProductToCustomerResponse {
	id: number;
	fields: {
		orderId: number;
		productId: number;
		amount: number;
		price: number;
	};
}

export const toProductInOrder = (
	response: ProductToCustomerResponse,
	product: { id: string; name: string }
): ProductInOrder => ({
	id: product.id,
	name: product.name,
	price: normalizePrice(response.fields.price),
	amount: response.fields.amount,
});
