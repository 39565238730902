import React from "react";
import { ImageProps, ButtonProps, Button } from "common";

export interface HeaderProps {
	title: string;
	location: {
		image: ImageProps;
		title: string;
		message: string;
		description: string;
		setLocation: ButtonProps;
	};
	images: Array<ImageProps>;
}

export const HeaderView: React.FC<HeaderProps> = (props) => {
	// const [galleryIndex, setIndex] = React.useState<number>(0);
	return (
		<>
			<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
				<h2 className="hidden sm:block text-3xl text-gray-800 dark:text-gray-200 sm:text-4xl space-x-4">
					<span>
						<span className="font-regluar">von</span>
						<span className="font-extrabold">Luck</span>
					</span>
					<span>|</span>
					<span>{props.title}</span>
				</h2>
				{/*<p className="text-xl text-gray-500 dark:text-gray-200">*/}
				{/*	{props.description}*/}
				{/*</p>*/}
			</div>
			<div className="h-max shadow-lg rounded-md overflow-hidden bg-yellow-200 dark:bg-yellow-500 flex">
				<img src={props.location.image.source} alt={props.location.image.title} className="w-32 object-cover" />
				<div>
					<div className="p-2 sm:p-4 space-y-2">
						<h2 className="text-xl text-yellow-900">{props.location.title}</h2>
						<h3 className="space-x-2 font-bold text-md text-yellow-700">{props.location.message}</h3>
						<p className="text-yellow-800 text-xs">{props.location.description}</p>
					</div>
					<div className="p-2 bg-yellow-400 dark:bg-yellow-600 flex items-end justify-end">
						<Button {...props.location.setLocation} className="ml-auto btn-1 btn-md">
							{props.location.setLocation.title}
						</Button>
					</div>
				</div>
			</div>
			<div className="w-full h-64 hidden sm:block bg-white dark:bg-black rounded-lg overflow-hidden shadow-lg">
				{/*<div className="h-full grid grid-cols-1 sm:hidden">*/}
				{/*	{*/}
				{/*		props.images.slice(0, 1).map((imageProps) => {*/}
				{/*			return (*/}
				{/*				<img*/}
				{/*					key={imageProps.title}*/}
				{/*					src={imageProps.source}*/}
				{/*					alt={imageProps.title}*/}
				{/*					className="w-full h-full object-cover"*/}
				{/*				/>*/}
				{/*			);*/}
				{/*		})*/}
				{/*	}*/}
				{/*</div>*/}
				<div className="h-full hidden sm:grid grid-cols-3 md:hidden">
					{props.images.slice(0, 3).map((imageProps) => {
						return (
							<img
								key={imageProps.title}
								src={imageProps.source}
								alt={imageProps.title}
								className="w-full h-full object-cover"
							/>
						);
					})}
				</div>
				<div className="h-full hidden md:grid grid-cols-5 lg:hidden">
					{props.images.slice(0, 5).map((imageProps) => {
						return (
							<img
								key={imageProps.title}
								src={imageProps.source}
								alt={imageProps.title}
								className="w-full h-full object-cover"
							/>
						);
					})}
				</div>
				<div className="h-full hidden lg:grid grid-cols-10">
					{props.images.slice(0, 10).map((imageProps) => {
						return (
							<img
								key={imageProps.title}
								src={imageProps.source}
								alt={imageProps.title}
								className="w-full h-full object-cover"
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
