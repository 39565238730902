import { ProductsListState, ProductsListStateType } from "../../productsList/state";
import { Dispatch, store } from "../../../applicationState";
import { PageWrapperHeaderProps } from "../pageWrapperHeader";
import { CommonPropsType, IconType, toPriceString } from "common";
import { VON_LUCK_HOMEPAGE, ONLINE_SHOP_PRODUCTS_URL, PATH_CHECKOUT } from "../../../variables/urls";
import { ApplicationActionType } from "../../../applicationState/actions";

export interface PageWrapperHeaderViewState {
	productsList: ProductsListState;
	basketValue: number;
	withSearchBar: boolean;
}

export const makeToHeaderProps =
	(dispatch: Dispatch) =>
	(state: PageWrapperHeaderViewState): PageWrapperHeaderProps => ({
		homeLink: {
			type: CommonPropsType.LINK,
			link: VON_LUCK_HOMEPAGE,
			title: "vonLuck homepage",
		},
		navigation: [
			{
				type: CommonPropsType.LINK,
				link: ONLINE_SHOP_PRODUCTS_URL,
				title: "Produkte",
			},
		],
		search: state.withSearchBar
			? {
					icon: IconType.SEARCH,
					input: {
						type: CommonPropsType.TEXT_INPUT,
						value: state.productsList.searchString,
						placeholder: "Suche nach Produkt-Namen, -Inhaltsstoffen oder -Beschreibungen",
						onChange: (value) => {
							dispatch({
								type: ApplicationActionType.PRODUCTS_LIST_SET_SEARCH_STRING,
								searchString: value,
							});
						},
					},
					onStartSearching: () => {
						dispatch({
							type: ApplicationActionType.PRODUCTS_LIST_SET_STATE_TYPE,
							stateType: ProductsListStateType.SEARCHING,
						});
					},
					onEndSearching: () => {
						dispatch({
							type: ApplicationActionType.PRODUCTS_LIST_SET_STATE_TYPE,
							stateType: ProductsListStateType.DEFAULT,
						});
					},
			  }
			: undefined,
		basket:
			state.basketValue === 0
				? undefined
				: {
						type: CommonPropsType.BUTTON,
						icon: IconType.BASKET,
						title: toPriceString(state.basketValue),
						onSelect: () => {
							store.dispatch({
								type: ApplicationActionType.ROUTER_REDIRECT,
								path: PATH_CHECKOUT,
							});
						},
				  },
	});
