import * as SE from "redux-saga/effects";
import * as common from "common";
import { Channel, channel } from "redux-saga";
import { makeFetchMoreProductsBroker, makeFetchMoreProductsWorker, makeScrollOnSearchWorker } from "./products";
import { makeFetchLocationsWorker } from "./locations";
import { makeRedirectWorker } from "./router";
import { makeFetchEnvironmentVariables } from "./environmentVariables";
import { makeLoadFromLocalStorageWorker, makeCheckoutSideEffectsWorker, makeOrderWorker } from "./checkout";
import {
	makeClearLocalStorageWorker,
	makeScrollToTop,
	makeSaveToLocalStorageWorker,
	makeScrollToElement,
} from "./browser";
import { makeFetchOpeningHours } from "./openingHours";
import { makeSendEmailWorker } from "./emails";
import { history } from "../router";
import { createOrder } from "./ordersApi";
import { LOCAL_STORAGE_NAME_SPACE, SERVER_ADDRESS } from "../variables/environment";

const pageCountChannel: Channel<number> = channel();

export function* rootSaga() {
	yield SE.spawn(makeFetchEnvironmentVariables(fetchEnvs));
	yield SE.spawn(makeScrollOnSearchWorker());
	yield SE.spawn(makeScrollToTop(scrollToTop));
	yield SE.spawn(makeScrollToElement(scrollToElement));
	yield SE.spawn(makeSaveToLocalStorageWorker(saveToLocalStorage));
	yield SE.spawn(makeLoadFromLocalStorageWorker(loadFromLocalStorage));
	yield SE.spawn(makeCheckoutSideEffectsWorker());
	yield SE.spawn(makeFetchMoreProductsBroker(pageCountChannel));
	yield SE.spawn(makeFetchMoreProductsWorker(fetchMoreProducts, pageCountChannel));
	yield SE.spawn(makeFetchLocationsWorker(fetchLocations, fetchImageById));
	yield SE.spawn(makeRedirectWorker(history.push));
	yield SE.spawn(makeSendEmailWorker(sendMail));
	yield SE.spawn(makeOrderWorker(createOrder));
	yield SE.spawn(makeClearLocalStorageWorker(clearLocalStorage));
	yield SE.spawn(makeFetchOpeningHours(fetchOpeningHours));
}

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "auto",
	});
};
const scrollToElement = (elementId: string) => {
	const element = document.getElementById(elementId);
	if (element === null) throw Error("Element Not Found");
	const headerOffset = 100;
	const elementPosition = element.getBoundingClientRect().top;
	const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth",
	});
};
const LOCAL_STORAGE_CHECKOUT_ITEM_NAME = "CHECKOUT";
const saveToLocalStorage = (value) => {
	const key = LOCAL_STORAGE_NAME_SPACE + "__" + LOCAL_STORAGE_CHECKOUT_ITEM_NAME;
	localStorage.setItem(key, value);
};
const loadFromLocalStorage = () => {
	const key = LOCAL_STORAGE_NAME_SPACE + "__" + LOCAL_STORAGE_CHECKOUT_ITEM_NAME;
	return localStorage.getItem(key) || undefined;
};
const clearLocalStorage = () => window.localStorage.clear();
const sendMail = (email) =>
	common.vonLuckApi
		.sendMail(SERVER_ADDRESS)(email)
		.then(() => true);
const fetchEnvs = () =>
	common.vonLuckApi.getAll(SERVER_ADDRESS)<common.EnvironmentVariableResponse>({
		type: "envs",
		filters: {
			domain: 2 /*ONLINE_SHOP*/,
		},
	});
export const fetchImageById = (id: string) => {
	return common.vonLuckApi.getById(SERVER_ADDRESS)<common.ImageResponse>({ type: "images", id });
};
export const fetchLocations = () =>
	common.vonLuckApi.getAll(SERVER_ADDRESS)<common.LocationResponse>({ type: "locations" });
export const fetchMoreProducts = (page: number) =>
	common.vonLuckApi.getAll(SERVER_ADDRESS)<common.ProductResponse>({
		type: "products",
		page: "" + page,
	});
export const fetchOpeningHours = () => {
	return common.vonLuckApi.getAll(SERVER_ADDRESS)<common.OpeningHoursRuleResponse>({ type: "opening-hours" });
};
