import * as React from "react";
import { IconProps } from "../../models/domain";

export default function Cookies(props: IconProps) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 20 20"
			stroke="none"
			fill="currentColor"
		>
			<path d="M19.8,8.2c-0.1-0.7-1-1.1-1.6-0.6C16.9,8.6,15,7.7,15,6c0.1-0.8-0.6-1.4-1.3-1.1c-1.8,0.7-3.5-1.6-2.2-3.2   C12,1.1,11.6,0.1,10.8,0C5-0.5,0,4.2,0,10c0,5.5,4.5,10,10,10C16.3,20,21,14.3,19.8,8.2z M10,18c-4.4,0-8-3.6-8-8   c0-4.1,3.1-7.5,7.1-8c-0.6,2.6,1.3,5,4,4.9c0.5,2.1,2.7,3.4,4.9,2.9C18.1,14.2,14.5,18,10,18z" />
			<path d="M9.3,10.3C9.1,10.5,9,10.7,9,11c0,0.5,0.5,1,1,1c0.5,0,1-0.4,1-1C11,10.1,9.9,9.7,9.3,10.3z" />
			<path d="M6.4,10.1C5.7,9.8,5,10.3,5,11c0,0.6,0.5,1,1,1c0.5,0,1-0.4,1-1C7,10.6,6.7,10.2,6.4,10.1z" />
			<path d="M6,8c0.5,0,1-0.4,1-1c0-0.9-1.1-1.3-1.7-0.7C5.1,6.5,5,6.8,5,7C5,7.5,5.4,8,6,8z" />
			<path d="M7.3,14.3C7.1,14.5,7,14.8,7,15c0,0.5,0.5,1,1,1c0.5,0,1-0.4,1-1C9,14.1,7.9,13.7,7.3,14.3z" />
			<path d="M14.2,13c-0.6-0.1-1.2,0.4-1.2,1c0,0.6,0.5,1,1,1c0.5,0,1-0.4,1-1C15,13.5,14.6,13.1,14.2,13z" />
			<path d="M14.7,2.7l1-1c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-1,1c-0.4,0.4-0.4,1,0,1.4C13.7,3.1,14.3,3.1,14.7,2.7z" />
			<path d="M17.5,5.1l1.4-0.3c0.5-0.1,0.9-0.7,0.8-1.2c-0.1-0.5-0.7-0.9-1.2-0.8l-1.4,0.3c-0.5,0.1-0.9,0.7-0.8,1.2   C16.5,4.9,17,5.3,17.5,5.1z" />
		</svg>
	);
}
