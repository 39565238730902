import * as React from "react";
import { WithClassNames, LinkProps, DisabledLinkProps } from "../models";

export const Link: React.FC<LinkProps & WithClassNames> = (props) => {
	if (/((https?:\/\/)|tel:|mailto:)/.test(props.link)) {
		return (
			<a href={props.link} title={props.title} target="_blank" rel="noreferrer" className={props.className}>
				{props.children}
			</a>
		);
	} else {
		return (
			<a href={props.link} title={props.title} className={props.className}>
				{props.children}
			</a>
		);
	}
};

export const DisabledLink: React.FC<DisabledLinkProps & WithClassNames> = (props) => {
	return (
		<a aria-disabled href="#" title={props.title} className={props.className}>
			{props.children}
		</a>
	);
};
