import React from "react";
import { IconType, Icon } from "common";
import { ProductProps } from "../props/productProps";
import { AmountInput } from "../../../components/amountInput";
import { RedButton, GreenButton } from "../../../components/buttons";

export const ProductView: React.FC<ProductProps> = (props) => {
	const imageRef = React.useRef(null);
	const [hasImage, setHasImage] = React.useState<boolean>(false);
	return (
		<Border withBorder={props.amount !== undefined}>
			<div className="space-y-2 bg-white dark:bg-gray-900 shadow-lg rounded-lg overflow-hidden text-gray-600 dark:text-gray-400">
				<div className="relative aspect-w-3 aspect-h-2">
					<img
						ref={imageRef}
						className={"object-cover rounded-t-lg dark:opacity-80" + (hasImage ? "" : " hidden")}
						src={props.image.source}
						alt={props.image.title}
						onLoad={() => setHasImage(true)}
						onError={() => setHasImage(false)}
					/>
					<div
						className={
							"w-full h-full bg-white dark:bg-gray-800 rounded-lg flex items-center justify-center" +
							(hasImage ? " hidden" : "")
						}
					>
						<Icon type={IconType.IMAGE} className="w-12 h-12 opacity-25" />
					</div>
				</div>
				<form className="px-2 flex items-stretch space-x-2">
					{props.addOne && <GreenButton icon={IconType.ADD} {...props.addOne} />}
					<AmountInput {...props.input} />
					{props.removeOne && <RedButton icon={IconType.REMOVE} {...props.removeOne} />}
					{props.removeAll && <RedButton icon={IconType.CLOSE} {...props.removeAll} />}
				</form>
				<div className="max-h-64 p-4 rounded-b-lg flex flex-col space-y-2">
					<div className="flex items-center justify-between space-x-2 leading-6">
						<h3 className="text-lg text-gray-900 dark:text-gray-200">{props.title}</h3>
					</div>
					<p className="h-max w-full text-xs text-2">{props.description}</p>
					<p className="h-max w-full italic text-xs text-3">{props.ingredients}</p>
					<div className="flex items-center justify-end">{props.price}</div>
				</div>
			</div>
		</Border>
	);
};

const Border: React.FC<{ withBorder: boolean }> = (props) =>
	props.withBorder ? (
		<div className="rounded-xl border-4 border-green-500">{props.children}</div>
	) : (
		<div className="rounded-xl border-4 border-transparent">{props.children}</div>
	);
