import * as O from "../option";
import * as OpeningHoursSet from "./openingHoursSet";
import { OpeningHours, OpeningHoursType, ClosedHours, OpenHours } from "./openingHours";
import {
	toDateString,
	getAllYearsInRange,
	getWeekDay,
	getAllDatesInRange,
	dateIsInRange,
	DateWithoutTime,
} from "./date";

export type OpeningHoursRule =
	| DateRule
	| ClosedDateRule
	| DayOfTheMonthsRule
	| ClosedDayOfTheMonthsRule
	| DaysOfTheWeekRule
	| ClosedDaysOfTheWeekRule;

export enum OpeningHoursRuleType {
	DATE = "OPENING_HOURS_RULE_TYPE/DATE",
	CLOSED_DATE = "OPENING_HOURS_RULE_TYPE/DATE/CLOSED",
	DAYS_OF_THE_WEEK = "OPENING_HOURS_RULE_TYPE/DAYS_OF_THE_WEEK",
	CLOSED_DAYS_OF_THE_WEEK = "OPENING_HOURS_RULE_TYPE/DAYS_OF_THE_WEEK/CLOSED",
	DAY_OF_THE_MONTHS = "OPENING_HOURS_RULE_TYPE/DAY_OF_THE_MONTHS",
	CLOSED_DAY_OF_THE_MONTHS = "OPENING_HOURS_RULE_TYPE/DAY_OF_THE_MONTHS/CLOSED",
}

export interface DateRule {
	type: OpeningHoursRuleType.DATE;
	date: string;
	from: string;
	to: string;
	message?: string;
}

export interface ClosedDateRule {
	type: OpeningHoursRuleType.CLOSED_DATE;
	date: string;
	message?: string;
}

export interface DayOfTheMonthsRule {
	type: OpeningHoursRuleType.DAY_OF_THE_MONTHS;
	months: Array<number>;
	day: number;
	from: string;
	to: string;
	message?: string;
}

export interface ClosedDayOfTheMonthsRule {
	type: OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS;
	months: Array<number>;
	day: number;
	message?: string;
}

export interface DaysOfTheWeekRule {
	type: OpeningHoursRuleType.DAYS_OF_THE_WEEK;
	days: Array<number>;
	from: string;
	to: string;
	message?: string;
}

export interface ClosedDaysOfTheWeekRule {
	type: OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK;
	days: Array<number>;
	message?: string;
}

export type PrecedenceTable<A, B> = Array<[A, B, number]>;
export const precedenceTable: PrecedenceTable<OpeningHoursRuleType, OpeningHoursRuleType> = [
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.CLOSED_DATE, 0],
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.DATE, -1],
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, -1],
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.DAY_OF_THE_MONTHS, -1],
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.CLOSED_DATE, OpeningHoursRuleType.DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.CLOSED_DATE, 1],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.DATE, 0],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, -1],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.DAY_OF_THE_MONTHS, -1],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.DATE, OpeningHoursRuleType.DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DATE, 1],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.DATE, 1],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, 0],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.DAY_OF_THE_MONTHS, -1],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, OpeningHoursRuleType.DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DATE, 1],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.DATE, 1],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, 1],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.DAY_OF_THE_MONTHS, 0],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.DAY_OF_THE_MONTHS, OpeningHoursRuleType.DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DATE, 1],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.DATE, 1],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, 1],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.DAY_OF_THE_MONTHS, 1],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, 0],
	[OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, OpeningHoursRuleType.DAYS_OF_THE_WEEK, -1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DATE, 1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.DATE, 1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS, 1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.DAY_OF_THE_MONTHS, 1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK, 1],
	[OpeningHoursRuleType.DAYS_OF_THE_WEEK, OpeningHoursRuleType.DAYS_OF_THE_WEEK, 0],
];

const isPrecedent =
	<A, B>(table: PrecedenceTable<A, B>) =>
	(a: A, b: B): number => {
		return table.find((row) => row[0] === a && row[1] === b)[2] || 0;
	};

export class OpeningHoursRuleRangeError extends RangeError {
	constructor(from: Date, to: Date) {
		super(`OpeningHoursRule: Given from date is before given to date! (from: ${from}, to: ${to})`);
	}
}

export interface RequestOpeningHours {
	from: Date;
	to: Date;
	rules: Array<OpeningHoursRule>;
}

export type ToOpeningHours = (req: RequestOpeningHours) => Array<OpeningHours>;
export const toOpeningHours: ToOpeningHours = (request) => {
	if (request.from > request.to) throw new OpeningHoursRuleRangeError(request.from, request.to);

	type RuleToOpeningHours<A, B> = (from: Date, to: Date, rule: A) => B;
	const fromDateRule: RuleToOpeningHours<DateRule, OpenHours | undefined> = (from, to, rule) => {
		if (dateIsInRange(DateWithoutTime.fromString(rule.date), from, to)) {
			return O.some<OpenHours>({
				type: OpeningHoursType.OPEN,
				date: rule.date,
				from: rule.from,
				to: rule.to,
				message: rule.message || "",
			});
		} else {
			return undefined;
		}
	};

	const fromClosedDateRule: RuleToOpeningHours<ClosedDateRule, O.Option<ClosedHours>> = (from, to, rule) => {
		return dateIsInRange(DateWithoutTime.fromString(rule.date), from, to)
			? O.some<ClosedHours>({
					type: OpeningHoursType.CLOSED,
					date: rule.date,
					message: rule.message || "",
			  })
			: O.none;
	};

	const fromClosedDayOfTheMonthsRule: RuleToOpeningHours<
		ClosedDayOfTheMonthsRule,
		OpeningHoursSet.OpeningHoursSet<ClosedHours>
	> = (from, to, rule) => {
		const allYearsInRange = getAllYearsInRange(from, to);
		return allYearsInRange.reduce((newClosedHours: { [date: string]: ClosedHours }, year) => {
			const nextClosedHours = rule.months.reduce((nextClosedHours: { [date: string]: ClosedHours }, month) => {
				const date = DateWithoutTime.fromParts(year, month, rule.day);
				const dateAsString = toDateString(date);
				if (dateIsInRange(date, from, to)) {
					const nextItem: ClosedHours = {
						type: OpeningHoursType.CLOSED,
						date: dateAsString,
						message: rule.message || "",
					};
					return {
						...nextClosedHours,
						[dateAsString]: nextItem,
					};
				} else {
					return nextClosedHours;
				}
			}, {});
			return {
				...newClosedHours,
				...nextClosedHours,
			};
		}, {});
	};

	const fromDayOfTheMonthsRule: RuleToOpeningHours<DayOfTheMonthsRule, OpeningHoursSet.OpeningHoursSet<OpenHours>> = (
		from,
		to,
		rule
	) => {
		const allYearsInRange = getAllYearsInRange(from, to);
		return allYearsInRange.reduce((newHours: { [date: string]: OpenHours }, year) => {
			const nextHours = rule.months.reduce((nextHours: { [date: string]: OpenHours }, month) => {
				const date = DateWithoutTime.fromParts(year, month, rule.day);
				const dateAsString = toDateString(date);
				if (dateIsInRange(date, from, to)) {
					const nextItem: OpenHours = {
						type: OpeningHoursType.OPEN,
						date: toDateString(date),
						from: rule.from,
						to: rule.to,
						message: rule.message || "",
					};
					return {
						...nextHours,
						[dateAsString]: nextItem,
					};
				} else {
					return nextHours;
				}
			}, {});
			return {
				...newHours,
				...nextHours,
			};
		}, {});
	};

	const fromClosedDaysOfTheWeekRule: RuleToOpeningHours<
		ClosedDaysOfTheWeekRule,
		OpeningHoursSet.OpeningHoursSet<ClosedHours>
	> = (from, to, rule) => {
		const allDatesInRange = getAllDatesInRange(from, to);
		return allDatesInRange.reduce((allDatesInRange, date) => {
			const weekDay = getWeekDay(date);
			const dateIsOneOfTheWeekDays = rule.days.includes(weekDay);
			if (dateIsOneOfTheWeekDays) {
				const dataAsString = toDateString(date);
				const newClosedHours: ClosedHours = {
					type: OpeningHoursType.CLOSED,
					date: dataAsString,
					message: rule.message || "",
				};
				return {
					...allDatesInRange,
					[dataAsString]: newClosedHours,
				};
			} else {
				return allDatesInRange;
			}
		}, {});
	};

	const fromDaysOfTheWeekRule: RuleToOpeningHours<DaysOfTheWeekRule, OpeningHoursSet.OpeningHoursSet<OpenHours>> = (
		from,
		to,
		rule
	) => {
		const allDatesInRange = getAllDatesInRange(from, to);
		return allDatesInRange.reduce((allDatesInRange, date) => {
			const weekDay = getWeekDay(date);
			const dateIsOneOfTheWeekDays = rule.days.includes(weekDay);
			if (dateIsOneOfTheWeekDays) {
				const dataAsString = toDateString(date);
				const newOpenHours: OpenHours = {
					type: OpeningHoursType.OPEN,
					date: dataAsString,
					from: rule.from,
					to: rule.to,
					message: rule.message || "",
				};
				return {
					...allDatesInRange,
					[dataAsString]: newOpenHours,
				};
			} else {
				return allDatesInRange;
			}
		}, {});
	};

	const sortedRules = request.rules.sort((a, b) => {
		return isPrecedent(precedenceTable)(a.type, b.type);
	});

	return Object.values(
		sortedRules.reduce((allOpeningHours: OpeningHoursSet.OpeningHoursSet, rule) => {
			switch (rule.type) {
				case OpeningHoursRuleType.DATE: {
					const nextHours = fromDateRule(request.from, request.to, rule);
					if (O.isSome(nextHours)) {
						return OpeningHoursSet.add(nextHours)(allOpeningHours);
					} else {
						return allOpeningHours;
					}
				}
				case OpeningHoursRuleType.CLOSED_DATE: {
					const nextHours = fromClosedDateRule(request.from, request.to, rule);
					if (O.isSome(nextHours)) {
						return OpeningHoursSet.add(nextHours)(allOpeningHours);
					} else {
						return allOpeningHours;
					}
				}
				case OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS: {
					const newHours = fromClosedDayOfTheMonthsRule(request.from, request.to, rule);
					return {
						...newHours,
						...allOpeningHours,
					};
				}
				case OpeningHoursRuleType.DAY_OF_THE_MONTHS: {
					const newHours = fromDayOfTheMonthsRule(request.from, request.to, rule);
					return {
						...newHours,
						...allOpeningHours,
					};
				}
				case OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK: {
					const newHours = fromClosedDaysOfTheWeekRule(request.from, request.to, rule);
					return {
						...newHours,
						...allOpeningHours,
					};
				}
				case OpeningHoursRuleType.DAYS_OF_THE_WEEK: {
					const newHours = fromDaysOfTheWeekRule(request.from, request.to, rule);
					return {
						...newHours,
						...allOpeningHours,
					};
				}
				default:
					return allOpeningHours;
			}
		}, {})
	).sort((a, b) => {
		const dateA = DateWithoutTime.fromString(a.date);
		const dateB = DateWithoutTime.fromString(b.date);
		if (dateA < dateB) {
			return -1;
		} else if (dateA > dateB) {
			return 1;
		} else {
			return 0;
		}
	});
};

interface RequestMatch {
	date: Date;
	rules: Array<OpeningHoursRule>;
}

export const openingHoursForDate = (request: RequestMatch): OpeningHours | undefined => {
	return request.rules
		.sort((a, b) => isPrecedent(precedenceTable)(a.type, b.type))
		.reduce((openingHours: OpeningHours | undefined, rule) => {
			if (openingHours !== undefined) return openingHours;
			switch (rule.type) {
				case OpeningHoursRuleType.DATE: {
					const dateAsString = toDateString(request.date);
					const isSameDate = dateAsString === rule.date;
					if (isSameDate) {
						const openHours: OpenHours = {
							type: OpeningHoursType.OPEN,
							date: dateAsString,
							from: rule.from,
							to: rule.to,
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				case OpeningHoursRuleType.CLOSED_DATE: {
					const dateAsString = toDateString(request.date);
					const isSameDate = dateAsString === rule.date;
					if (isSameDate) {
						const openHours: ClosedHours = {
							type: OpeningHoursType.CLOSED,
							date: dateAsString,
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				case OpeningHoursRuleType.CLOSED_DAY_OF_THE_MONTHS: {
					const monthOfTheDate = request.date.getMonth() + 1;
					const dayOfTheMonth = request.date.getDate();
					const isTheSameMonth = rule.months.includes(monthOfTheDate);
					const isTheSameDay = rule.day === dayOfTheMonth;
					if (isTheSameMonth && isTheSameDay) {
						const openHours: ClosedHours = {
							type: OpeningHoursType.CLOSED,
							date: toDateString(request.date),
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				case OpeningHoursRuleType.DAY_OF_THE_MONTHS: {
					const monthOfTheDate = request.date.getMonth() + 1;
					const dayOfTheMonth = request.date.getDate();
					const isTheSameMonth = rule.months.includes(monthOfTheDate);
					const isTheSameDay = rule.day === dayOfTheMonth;
					if (isTheSameMonth && isTheSameDay) {
						const openHours: OpenHours = {
							type: OpeningHoursType.OPEN,
							date: toDateString(request.date),
							from: rule.from,
							to: rule.to,
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				case OpeningHoursRuleType.CLOSED_DAYS_OF_THE_WEEK: {
					const dayOfTheWeek = getWeekDay(request.date);
					const isTheSameWeekday = rule.days.includes(dayOfTheWeek);
					if (isTheSameWeekday) {
						const openHours: ClosedHours = {
							type: OpeningHoursType.CLOSED,
							date: toDateString(request.date),
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				case OpeningHoursRuleType.DAYS_OF_THE_WEEK: {
					const dayOfTheWeek = getWeekDay(request.date);
					const isTheSameWeekday = rule.days.includes(dayOfTheWeek);
					if (isTheSameWeekday) {
						const openHours: OpenHours = {
							type: OpeningHoursType.OPEN,
							date: toDateString(request.date),
							from: rule.from,
							to: rule.to,
							message: rule.message || "",
						};
						return openHours;
					} else {
						return undefined;
					}
				}
				default:
					return openingHours;
			}
		}, undefined);
};
