import * as common from "common";
import React from "react";
import { LocationsProps, LocationsPropsType } from "../props/locationsProps";
import { IntegrationTestWrapper } from "../../../utils/integrationTests";

export const LocationsView: React.FC<LocationsProps> = (props) => {
	switch (props.type) {
		case LocationsPropsType.SKELETON:
			return <div>no data</div>;
		case LocationsPropsType.LOADING:
			return (
				<div className="flex flex-col items-center justify-center">
					<common.Loading />
					<p className="text-gray-600 text-sm">{props.message}</p>
				</div>
			);
		case LocationsPropsType.DATA:
			return (
				<div className="pb-12 space-y-8">
					<h2 className="text-2 text-lg text-center">{props.title}</h2>
					<div className="flex flex-col sm:flex-row items-center justify-center gap-4">
						{props.locations.map((locationProps, index) => {
							return (
								<React.Fragment key={index}>
									<IntegrationTestWrapper
										id={common.integrationTests.onlineShop.SET_LOCATION_PAGE_LOCATION_VIEW}
									>
										<div
											className={
												locationProps.button.isSelected
													? "ring-green-500 ring-4 ring-offset-2 ring-offset-white dark:ring-offset-black rounded-md"
													: ""
											}
										>
											<figure
												onClick={locationProps.button.onSelect}
												className="btn-2 flex flex-col items-between justify-center rounded-md overflow-hidden"
											>
												<img
													src={locationProps.image.source}
													alt={locationProps.image.title}
													className="w-64 h-64 object-cover"
												/>
												<figcaption className="w-full p-2 text-center text-lg text-1">
													{locationProps.button.title}
												</figcaption>
											</figure>
										</div>
									</IntegrationTestWrapper>
								</React.Fragment>
							);
						})}
					</div>
					<div className="w-full flex items-center justify-center">
						{props.submit.type === common.CommonPropsType.BUTTON ? (
							<common.Button {...props.submit} className="btn-1 btn-md">
								{props.submit.title}
							</common.Button>
						) : (
							<common.DisabledButton {...props.submit} className="btn-dis btn-md">
								{props.submit.title}
							</common.DisabledButton>
						)}
					</div>
				</div>
			);
		default:
			return null;
	}
};
