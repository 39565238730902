import React from "react";
import { useAppState } from "../../applicationState";
import { LocationsView } from "./views/locationsView";
import { selectLocationsState } from "./selectors/selectors";
import { selectCheckoutState } from "../checkout/selectors/selectors";
import { toLocationsProps } from "./toProps";

export const Locations: React.FC = () => {
	const checkoutState = useAppState(selectCheckoutState);
	const locationsState = useAppState(selectLocationsState);

	const viewProps = toLocationsProps({
		checkout: checkoutState,
		locations: locationsState,
	});
	return <LocationsView {...viewProps} />;
};
