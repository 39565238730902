import { Location, Image, OpeningHoursRule } from "../domain";

export interface LocationResponse {
	id: number;
	fields: {
		bannerImage: number;
		name: string;
		established: string;
		description: string;
		phone: string;
		email: string;
		location: string;
		googleMapsLink: string;
		iosMapsLink: string;
		menu: string;
		images: Array<number>;
		reviews: Array<number>;
		jobs: Array<number>;
		openingHours: Array<number>;
	};
}

export type ToLocation = (req: {
	location: LocationResponse;
	bannerImage: Image;
	images: Array<Image>;
	openingHours: Array<OpeningHoursRule>;
}) => Location;
export const toLocation: ToLocation = (req) => ({
	id: String(req.location.id),
	bannerImage: req.bannerImage,
	name: req.location.fields.name,
	established: req.location.fields.established,
	description: req.location.fields.description,
	phone: req.location.fields.phone,
	mail: req.location.fields.email,
	location: req.location.fields.location,
	googleMapsLink: req.location.fields.googleMapsLink,
	iosMapsLink: req.location.fields.iosMapsLink,
	menuLink: req.location.fields.menu,
	images: req.images,
	openingHours: req.openingHours,
});
