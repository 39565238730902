import React from "react";
import { ProductsProps } from "../props/checkoutProps";

export const ProductsListSummary: React.FC<ProductsProps> = (props) => (
	<section className="bg-white dark:bg-gray-900 shadow-lg p-4 rounded-lg grid grid-cols-4 gap-y-4">
		{props.searchSum && props.searchAmount && (
			<>
				<p className="col-span-3 text-3 text-sm text-right">{props.searchAmount.title}</p>
				<p className="text-2 text-right">{props.searchAmount.amount}</p>
				<p className="col-span-3 text-3 text-sm text-right">{props.searchSum.title}</p>
				<p className="text-2 text-right">{props.searchSum.price}</p>
				<div className="border-b-2 border-gray-600" />
				<div className="border-b-2 border-gray-600" />
				<div className="border-b-2 border-gray-600" />
				<div className="border-b-2 border-gray-600" />
			</>
		)}
		<p className="col-span-3 text-2 text-sm text-right">{props.amount.title}</p>
		<p className="text-1 text-right">{props.amount.amount}</p>
		<p className="col-span-3 text-2 text-right">{props.sum.title}</p>
		<p className="text-1 text-xl text-right">{props.sum.price}</p>
	</section>
);
