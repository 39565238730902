import * as React from "react";

export class ErrorBoundary extends React.Component {
	state: {
		hasError: boolean;
	};

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_) {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return <h1>Something went wrong.</h1>;
		}
		return this.props.children;
	}
}
