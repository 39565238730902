import { Image } from "common";
import shuffle from "lodash/shuffle";

export enum ProductsListStateType {
	DEFAULT = "PRODUCTS_LIST_STATE_TYPE/DEFAULT",
	SEARCHING = "PRODUCTS_LIST_STATE_TYPE/SEARCHING",
}

export interface ProductsListState {
	type: ProductsListStateType;
	searchString: string;
	images: Array<Image>;
}

export const initialState = (): ProductsListState => ({
	type: ProductsListStateType.DEFAULT,
	searchString: "",
	images: shuffle(
		[
			"https://res.cloudinary.com/aricma/image/upload/v1541422753/vonLuck/UNADJUSTEDNONRAW_thumb_1ca.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1544664304/vonLuck/brezeln.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1544663511/vonLuck/croissants%20pain%20au%20chocolate.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1544663147/vonLuck/boule%20rustique%20mit%20ziegenfrischk%C3%A4se%20mit%20honig%20und%20mohn.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1544663140/vonLuck/boule%20rustique%20mit%20mozzarella%20und%20tomaten.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1541422597/vonLuck/UNADJUSTEDNONRAW_thumb_37.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1541422634/vonLuck/86HGx5NLS_yJYtHd7ggO5Q_thumb_66.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1516959540/9K0A1609_mcgwug.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1541422788/vonLuck/vlkcFPZS_G0FLYx9jxMAA_thumb_348.jpg",
			"https://res.cloudinary.com/aricma/image/upload/v1541422662/vonLuck/1JaTqKguQ_mK8xRItWUGOA_thumb_97.jpg",
		].map(
			(url, index): Image => ({
				id: url,
				name: "image-" + index,
				url: url,
			})
		)
	),
});
