import { Dispatch } from "../../../applicationState";
import { LocationsProps, LocationsPropsType } from "../props/locationsProps";
import { LocationsStateType, LocationsState } from "../state";
import { dictionary, CommonPropsType } from "common";
import { ApplicationActionType } from "../../../applicationState/actions";
import { PATH_PRODUCTS } from "../../../variables/urls";
import { CheckoutState } from "../../checkout/state";

export interface LocationsViewState {
	checkout: CheckoutState;
	locations: LocationsState;
}

export const makeToLocationsProps =
	(dispatch: Dispatch) =>
	(state: LocationsViewState): LocationsProps => {
		switch (state.locations.type) {
			case LocationsStateType.FETCHING:
				return {
					type: LocationsPropsType.LOADING,
					message: LOADING_MESSAGE,
				};
			case LocationsStateType.ERROR:
				return {
					type: LocationsPropsType.ERROR,
					title: SELECT_LOCATION_TITLE,
					errorMessage: "",
				};
			case LocationsStateType.DATA:
				const hasNoLocations = dictionary.length(state.locations.locations) === 0;
				if (hasNoLocations) {
					return {
						type: LocationsPropsType.ERROR,
						title: SELECT_LOCATION_TITLE,
						errorMessage: HAS_NO_LOCATIONS_ERROR_MESSAGE,
					};
				} else {
					const selectedLocationId = state.checkout.request.locationId;
					const hasSelectedLocationId = !!selectedLocationId;
					return {
						type: LocationsPropsType.DATA,
						title: SELECT_LOCATION_TITLE,
						locations: dictionary.toValues(state.locations.locations).map((location) => {
							return {
								image: {
									type: CommonPropsType.IMAGE,
									source: location.bannerImage.url,
									title: location.bannerImage.name,
								},
								button: {
									type: CommonPropsType.BUTTON,
									isSelected: location.id === selectedLocationId,
									title: location.name,
									onSelect: () => {
										dispatch({
											type: ApplicationActionType.CHECKOUT_SET_LOCATION,
											locationId: location.id,
										});
									},
								},
							};
						}),
						submit: hasSelectedLocationId
							? {
									type: CommonPropsType.BUTTON,
									title: "Weiter",
									onSelect: () => {
										dispatch({
											type: ApplicationActionType.ROUTER_REDIRECT,
											path: PATH_PRODUCTS,
										});
									},
							  }
							: {
									type: CommonPropsType.DISABLED_BUTTON,
									title: "Weiter",
							  },
					};
				}
		}
	};
export const LOADING_MESSAGE = "Verfügbare Standorte werden geladen...";
export const SELECT_LOCATION_TITLE = "Wähle den vonLuck Standort zur Abholung.";
export const HAS_NO_LOCATIONS_ERROR_MESSAGE =
	"Oh, es ist ein Problem aufgetreten. Bitte versuche es später erneut oder kontaktiere den vonLuck support(cafe@von-luck.de)";
