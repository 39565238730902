import { ProductsListState } from "./state";
import { Reducer } from "common";
import { ProductsListAction, ApplicationActionType, RouterRedirect } from "../../applicationState/actions";

export const productsListReducer: Reducer<ProductsListState, ProductsListAction | RouterRedirect> = (state, action) => {
	switch (action.type) {
		case ApplicationActionType.PRODUCTS_LIST_SET_STATE_TYPE:
			return {
				...state,
				type: action.stateType,
			};
		case ApplicationActionType.PRODUCTS_LIST_SET_IMAGES:
			return {
				...state,
				images: action.images,
			};
		case ApplicationActionType.PRODUCTS_LIST_SET_SEARCH_STRING:
			return {
				...state,
				searchString: action.searchString,
			};
		case ApplicationActionType.ROUTER_REDIRECT:
			return {
				...state,
				searchString: "",
			};
		default:
			return state;
	}
};
