import { dictionary, Location } from "common";

export type LocationsState = FetchingLocationsState | ErrorLocationsState | DataLocationsState;

export enum LocationsStateType {
	FETCHING = "LOCATIONS_STATE_TYPE/FETCHING",
	ERROR = "LOCATIONS_STATE_TYPE/ERROR",
	DATA = "LOCATIONS_STATE_TYPE/DATA",
}

export interface FetchingLocationsState {
	type: LocationsStateType.FETCHING;
}

export interface ErrorLocationsState {
	type: LocationsStateType.ERROR;
	error: Error;
}

export interface DataLocationsState {
	type: LocationsStateType.DATA;
	locations: dictionary.Dictionary<Location>;
}

export const initialState = (): LocationsState => ({
	type: LocationsStateType.FETCHING,
});
