import { Reducer } from "common";
import { OpeningHoursState, OpeningHoursStateType } from "./state";
import { OpeningHoursAction, ApplicationActionType } from "../../applicationState/actions";

export const openingHoursReducer: Reducer<OpeningHoursState, OpeningHoursAction> = (state, action) => {
	switch (action.type) {
		case ApplicationActionType.OPENING_HOURS_FETCH:
			return {
				type: OpeningHoursStateType.FETCHING,
			};
		case ApplicationActionType.OPENING_HOURS_SET_MANY:
			return {
				type: OpeningHoursStateType.DATA,
				openingHours: action.openingHours,
			};
		case ApplicationActionType.OPENING_HOURS_SET_ERROR:
			return {
				type: OpeningHoursStateType.ERROR,
				error: action.error,
			};
		default:
			return state;
	}
};
