import React from "react";

export const Skeleton: React.FC<{ title: string; message: string }> = (props) => {
	return (
		<section className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-lg">
			<div className="flex flex-col items-center justify-center space-y-4">
				<h3 className="text-2 text-xl text-center">{props.title}</h3>
				<p className="text-3 text-sm text-center">{props.message}</p>
			</div>
		</section>
	);
};
