import * as F from "fp-ts/function";
import { Reducer, dictionary, Product } from "common";
import { ProductsStateType, ProductsState } from "./state";
import { ApplicationActionType, ProductAction } from "../../applicationState/actions";

export const productsReducer: Reducer<ProductsState, ProductAction> = (state, action: ProductAction) => {
	switch (action.type) {
		case ApplicationActionType.PRODUCTS_FETCH_MORE:
			if (state.type === ProductsStateType.DATA) {
				return {
					type: ProductsStateType.FETCHING,
					products: state.products,
				};
			} else {
				return {
					type: ProductsStateType.FETCHING,
				};
			}
		case ApplicationActionType.PRODUCTS_SET_MANY:
			return {
				type: ProductsStateType.DATA,
				products: F.pipe(action.products, dictionary.fromValues(productToKeyValuePair)),
			};
		case ApplicationActionType.PRODUCTS_ADD_MANY: {
			const newProducts = F.pipe(action.products, dictionary.fromValues(productToKeyValuePair));
			if ("products" in state && state.products !== undefined) {
				return {
					type: ProductsStateType.DATA,
					products: {
						...state.products,
						...newProducts,
					},
				};
			} else {
				return {
					type: ProductsStateType.DATA,
					products: newProducts,
				};
			}
		}
		case ApplicationActionType.PRODUCTS_SET_ERROR:
			return {
				type: ProductsStateType.ERROR,
				error: action.error,
			};
		default:
			return state;
	}
};

const productToKeyValuePair = (product: Product) => ({ key: product.id, value: product });
