import React from "react";
import { ProductListProps, ProductListPropsType } from "../props/productListProps";
import * as common from "common";
import { Products } from "./productsView";
import { HeaderView } from "./headerView";
import { PRODUCTS_LIST_ELEMENT_ID } from "../../../variables/environment";

const LOADING_VIEW_MESSAGE = "Weitere Produkte werden geladen";

export const ProductsListView: React.FC<ProductListProps> = (props) => {
	switch (props.type) {
		case ProductListPropsType.SKELETON:
			return <div>no data</div>;
		case ProductListPropsType.LOADING:
			return (
				<div className="md:pt-12 space-y-12">
					<HeaderView {...props.header} />
					<div id={PRODUCTS_LIST_ELEMENT_ID}>
						<Products products={props.products} />
					</div>
					<div className="w-full flex flex-col items-center justify-center">
						<common.Loading />
						<p className="text-gray-500">{LOADING_VIEW_MESSAGE}</p>
					</div>
				</div>
			);
		case ProductListPropsType.DEFAULT:
			return (
				<div className="md:pt-12 space-y-12">
					<HeaderView {...props.header} />
					<div id={PRODUCTS_LIST_ELEMENT_ID}>
						<Products products={props.products} />
					</div>
				</div>
			);
		case ProductListPropsType.ERROR:
			return <div>error</div>;
	}
};
