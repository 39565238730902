import { ApplicationState } from "../../applicationState";
import { EnvironmentVariablesState } from "./state";

export const selectEnvironmentVariables = (state: ApplicationState): EnvironmentVariablesState =>
	state.environmentVariables;

export const selectPaypalMinBasketValue = (state: ApplicationState): number => {
	return toNumber(state.environmentVariables.variables["PAYPAL_MIN_BASKET_VALUE"] || "") || 0;
};

export const selectOrderOffsetDays = (state: ApplicationState): number => {
	return toNumber(state.environmentVariables.variables["ORDER_OFFSET_DAYS"] || "") || 0;
};

export const toNumber = (value: string): number | undefined => {
	const asNumber = Number(value);
	return Number.isNaN(asNumber) ? undefined : asNumber;
};
