import { FunctionComponent } from "react";
import { WithClassNames } from "../../../models";

export enum IconType {
	DEFAULT = "DEFAULT",
	EXCLAMATION_FILL = "ICON_TYPE/EXCLAMATION/FILL",
	EXCLAMATION_STROKE = "ICON_TYPE/EXCLAMATION/STROKE",
	TRASH_FILL = "ICON_TYPE/TRASH/FILL",
	TRASH_STROKE = "ICON_TYPE/TRASH/STROKE",
	SUN = "SUN",
	MOON = "MOON",
	MOON_SUN = "MOON_SUN",
	SETTINGS = "SETTINGS",
	LOCATION = "LOCATION",
	SHOPPING_BAG = "SHOPPING_BAG",
	HOME = "HOME",
	PHONE = "PHONE",
	MAIL = "MAIL",
	OPEN = "OPEN",
	CLOSED = "CLOSED",
	BASKET = "BASKET",
	ADD = "ADD",
	REMOVE = "REMOVE",
	CLOSE = "CLOSE",
	CHECK_MARK = "CHECK_MARK",
	QUESTION_MARK = "QUESTION_MARK",
	COOKIES = "COOKIES",
	RIGHT = "RIGHT",
	MENU_BOOK = "MENU_BOOK",
	MENU_FILL = "MENU_FILL",
	SEARCH = "SEARCH",
	CHEVRON_RIGHT = "CHEVRON_RIGHT",
	CHEVRON_LEFT = "CHEVRON_LEFT",
	CHEVRON_DOWN = "CHEVRON_DOWN",
	CHEVRON_UP = "CHEVRON_UP",
	SAVE = "SAVE",
	IMAGE = "IMAGE",
	TOOOGOODTOGO = "TOOOGOODTOGO",
	TRIPADVISOR = "TRIPADVISOR",
	YELP = "YELP",
	VISA = "VISA",
	APPLE_PAY = "APPLE_PAY",
	GOOGLE_PAY = "GOOGLE_PAY",
	PAYPAL = "PAYPAL",
	MASTERCARD = "MASTERCARD",
	MAESTRO = "MAESTRO",
	AMEX = "AMEX",
	JCB = "JCB",
}

export interface IconOption {
	type: IconType;
	icon: FunctionComponent<WithClassNames>;
}

export type IconProps = WithClassNames;
