import { IconType } from "../components";

export interface WithClassNames {
	className?: string;
}

export enum CommonPropsType {
	BUTTON = "COMMON_PROPS_TYPE/BUTTON",
	DISABLED_BUTTON = "COMMON_PROPS_TYPE/BUTTON/DISABLED",
	LINK = "COMMON_PROPS_TYPE/LINK",
	DISABLED_LINK = "COMMON_PROPS_TYPE/LINK/DISABLED",
	IMAGE = "COMMON_PROPS_TYPE/IMAGE",
	TEXT_INPUT = "COMMON_PROPS_TYPE/INPUT/TEXT",
	BOOLEAN_INPUT = "COMMON_PROPS_TYPE/INPUT/TEXT",
	SELECT_INPUT = "COMMON_PROPS_TYPE/INPUT/SELECT",
	DISABLED_TEXT_INPUT = "COMMON_PROPS_TYPE/INPUT/TEXT/DISABLED",
}

export interface LinkProps {
	type: CommonPropsType.LINK;
	isSelected?: boolean;
	icon?: IconType;
	title: string;
	link: string;
}

export interface DisabledLinkProps {
	type: CommonPropsType.DISABLED_LINK;
	icon?: IconType;
	title: string;
}

export interface ImageProps {
	type: CommonPropsType.IMAGE;
	title: string;
	source: string;
}

export type ButtonProps = BaseButtonProps & {
	type: CommonPropsType.BUTTON;
};

export interface BaseButtonProps {
	isSelected?: boolean;
	icon?: IconType;
	title: string;
	onSelect: () => void;
}

export type DisabledButtonProps = BaseDisabledButtonProps & {
	type: CommonPropsType.DISABLED_BUTTON;
};

export interface BaseDisabledButtonProps {
	icon?: IconType;
	title: string;
}

interface InputProps<Value> {
	label?: string;
	validation?: string;
	value: Value;
	placeholder: string;
}

export interface TextInputProps extends InputProps<string> {
	type: CommonPropsType.TEXT_INPUT;
	onChange: (value: string) => void;
}

export interface BooleanInputProps extends InputProps<boolean> {
	type: CommonPropsType.BOOLEAN_INPUT;
	onToggle: () => void;
	onSetTrue: () => void;
	onSetFalse: () => void;
}

export interface SelectInputProps extends InputProps<string> {
	type: CommonPropsType.SELECT_INPUT;
	options: Array<ButtonProps | DisabledButtonProps>;
}
