import * as common from "common";
import { customerOrderConformationText } from "./customerOrderConformationText";
import { vonLuckOrderConformationText } from "./vonLuckOrderConformationText";
import { orderDetailsText } from "./orderDetailsText";
import { productText } from "./productText";
import { headerText } from "./headerText";
import { footerText } from "./footerText";

export const SEND_GRID_API_KEY: string = process.env.REACT_APP_SEND_GRID_API_KEY!;

export const VON_LUCK_NO_REPLY_EMAIL = "noreply@von-luck.de";
export const VON_LUCK_ORDER_EMAIL = "bestellung@von-luck.de";

export const vonLuckOrderConformationSubject = (req: { name: string; paymentMethod: string }) => {
	return `Neue Bestellung: ${req.name} (Bezahlmethode: ${req.paymentMethod})`;
};
export const customerOrderConformationSubject = () => "vonLuck | Ihre Bestellung ist bei uns eingetroffen";

interface VonLuckOrderRequest {
	name: string;
	location: string;
	phone: string;
	email: string;
	date: string;
	time: string;
	price: string;
	message: string;
	paymentMethod: string;
	products: Array<{ name: string; amount: number; price: number }>;
}
export const vonLuckOrderConformation = (req: VonLuckOrderRequest) =>
	vonLuckOrderConformationText({
		header: headerText(),
		orderDetails: orderDetails(req),
		footer: footer(),
	});

interface CustomerOrderRequest {
	name: string;
	pickUpId: string;
	location: string;
	phone: string;
	email: string;
	date: string;
	time: string;
	price: string;
	message: string;
	paymentMethod: string;
	products: Array<{ name: string; amount: number; price: number }>;
}
export const customerOrderConformation = (req: CustomerOrderRequest) =>
	customerOrderConformationText({
		header: headerText(),
		name: req.name,
		pickUpId: req.pickUpId,
		date: req.date,
		time: req.time,
		location: req.location,
		orderDetails: orderDetails(req),
		footer: footer(),
	});

interface OrderDetailsRequest {
	name: string;
	location: string;
	phone: string;
	email: string;
	date: string;
	time: string;
	price: string;
	message: string;
	paymentMethod: string;
	products: Array<{ name: string; amount: number; price: number }>;
}
export const orderDetails = (req: OrderDetailsRequest) =>
	orderDetailsText({
		name: req.name,
		email: req.email,
		phone: req.phone,
		date: req.date,
		time: req.time,
		price: req.price,
		message: req.message,
		paymentMethod: req.paymentMethod,
		location: req.location,
		products: req.products
			.map((req) =>
				productText({
					name: req.name,
					amount: String(req.amount),
					price: common.toPriceString(req.price),
				})
			)
			.join("\n"),
	});

const footer = () =>
	footerText({
		legal: common.legal,
		copyright: common.copyright,
	});

export const paymentMethod = (method: common.PaymentMethodType): string => {
	switch (method) {
		case common.PaymentMethodType.AT_LOCATION:
			return "Bei Abholung";
		case common.PaymentMethodType.PAYPAL:
			return "Paypal";
	}
};
