import React from "react";
import { CommonPropsType, Button, DisabledButton } from "common";
import { SubmitButtonProps } from "../props/checkoutProps";
import { PaypalButton } from "../../paypalButton";

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
	switch (props.type) {
		case "PAYPAL_BUTTON_PROPS":
			return (
				<div className="h-[42px] shadow-lg">
					<PaypalButton {...props} />
				</div>
			);
		case CommonPropsType.BUTTON:
			return (
				<Button {...props} className="shadow-lg btn-1 btn-md lg:btn-lg">
					{props.title}
				</Button>
			);
		case CommonPropsType.DISABLED_BUTTON:
			return (
				<DisabledButton {...props} className="shadow-lg btn-dis btn-md lg:btn-lg">
					{props.title}
				</DisabledButton>
			);
		default:
			return null;
	}
};
