import React from "react";
import { IS_DEVELOPMENT_ENVIRONMENT } from "../variables/environment";

export const IntegrationTestWrapper: React.FC<{ id: string }> = (props) => {
	if (IS_DEVELOPMENT_ENVIRONMENT) {
		return <div data-test={props.id}>{props.children}</div>;
	} else {
		return <>{props.children}</>;
	}
};
