import { OpeningHoursRule } from "common";

export type OpeningHoursState = DataOpeningHoursState | FetchingOpeningHoursState | ErrorOpeningHoursState;

export enum OpeningHoursStateType {
	DATA = "OPENING_HOURS_STATE_TYPE/DATA",
	FETCHING = "OPENING_HOURS_STATE_TYPE/FETCHING",
	ERROR = "OPENING_HOURS_STATE_TYPE/ERROR",
}

export interface DataOpeningHoursState {
	type: OpeningHoursStateType.DATA;
	openingHours: Array<OpeningHoursRule>;
}

export interface FetchingOpeningHoursState {
	type: OpeningHoursStateType.FETCHING;
}

export interface ErrorOpeningHoursState {
	type: OpeningHoursStateType.ERROR;
	error: Error;
}

export const initialState = (): FetchingOpeningHoursState => ({
	type: OpeningHoursStateType.FETCHING,
});
