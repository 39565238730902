export const ONLINE_SHOP_URL = "/";
export const ONLINE_SHOP_PRODUCTS_URL = "/products";
export const VON_LUCK_HOMEPAGE = "https://von-luck.de";
export const VON_LUCK_HOMEPAGE_LOCATION_URL = (locationId: string) => `https://von-luck.de/locations/${locationId}`;
export const VON_LUCK_HOMEPAGE_PRIVACY_URL = "https://von-luck.de/privacy";

export const PATH_SET_LOCATIONS = "/set-location";
export const PATH_PRODUCTS = "/";
export const PATH_PRODUCTS_SECTION = "/#products";
export const PATH_CHECKOUT = "/checkout";
