import * as common from "common";
import SHA256 from "crypto-js/sha256";
import { RequestOrder, RequestProductToOrder, makeCreateOrder } from "./orders";
import { SERVER_ADDRESS } from "../../variables/environment";

const set = common.vonLuckApi.set(SERVER_ADDRESS);

export const createOrder = makeCreateOrder(
	(value: string): string => SHA256(value).toString(),
	(order) =>
		set<RequestOrder, common.OrderResponse>({
			type: "orders",
			data: order,
		}).then((responses) => responses[0] || undefined),
	(request) =>
		set<RequestProductToOrder, common.ProductToCustomerResponse>({
			type: "products-to-order",
			data: request,
		}).then((responses) => {
			return responses.length === request.length ? responses : undefined;
		})
);
