import * as common from "common";
import React from "react";
import { ApplicationActionType } from "../../applicationState/actions";
import { store, useAppState } from "../../applicationState";
import { CheckoutView } from "./views/checkoutView";
import { selectCheckoutState, selectProductsWithAmount, selectBasketValue } from "./selectors/selectors";
import { selectOpeningHoursState } from "../openingHours/selectors";
import { selectSearchString } from "../productsList/selectors";
import { selectLocationForId } from "../locations/selectors/selectors";
import { toCheckoutProps } from "./toProps";
import { PATH_PRODUCTS } from "../../variables/urls";
import { selectPaypalMinBasketValue, selectOrderOffsetDays } from "../environmentVariables/selectors";

export const Checkout: React.FC = () => {
	const checkoutState = useAppState(selectCheckoutState);
	const products = useAppState(selectProductsWithAmount);
	const openingHours = useAppState(selectOpeningHoursState);
	const searchString = useAppState(selectSearchString);
	const selectedLocation = useAppState(selectLocationForId(checkoutState.request.locationId!))!;
	const basketValue = useAppState(selectBasketValue);
	const paypalMinBasketValue = useAppState(selectPaypalMinBasketValue);
	const orderOffsetDays = useAppState(selectOrderOffsetDays);

	React.useEffect(() => {
		const hasNoProductsInBasket = products.length === 0;
		if (hasNoProductsInBasket) {
			store.dispatch({
				type: ApplicationActionType.ROUTER_REDIRECT,
				path: PATH_PRODUCTS,
			});
		}
		// eslint-disable-next-line
	}, []);

	const viewProps = toCheckoutProps({
		allowedPaymentMethods: [
			common.PaymentMethodType.AT_LOCATION,
			...(basketValue < paypalMinBasketValue ? [] : [common.PaymentMethodType.PAYPAL]),
		],
		paypalMinBasketValue: paypalMinBasketValue,
		datePickerOffsetDays: orderOffsetDays,
		checkout: checkoutState,
		products: products,
		openingHours: openingHours,
		searchString: searchString,
		selectedLocation: selectedLocation,
	});

	return <CheckoutView {...viewProps} />;
};
