import * as React from "react";
import { Croissant } from "./croissant";

export const Loading: React.FC = () => {
	return (
		<div className="w-full h-full p-10 flex flex-col items-center justify-center space-y-3">
			<Croissant className="w-36 text-gray-400" />
		</div>
	);
};
