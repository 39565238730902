import { ProductsState, ProductsStateType } from "../../products/state";
import { ProductsListState } from "../state";
import { CheckoutState } from "../../checkout/state";
import { Dispatch } from "../../../applicationState";
import { ProductProps } from "../props/productProps";
import { ProductListProps, ProductListPropsType } from "../props/productListProps";
import { HeaderProps } from "../views/headerView";
import { CommonPropsType, Location, Product, dictionary } from "common";
import { selectAmountForProductId } from "../../checkout/selectors/selectors";
import { ApplicationActionType } from "../../../applicationState/actions";
import { PATH_SET_LOCATIONS } from "../../../variables/urls";
import { filterProductBySearchString, filterProductByLocationId } from "../../../utils/filter";

interface ProductsListViewState {
	productsState: ProductsState;
	productsListState: ProductsListState;
	checkoutState: CheckoutState;
	selectedLocation: Location;
}

export const makeToProductsListViewProps =
	(dispatch: Dispatch, toProductProps: (state: Product & { amount?: number }) => ProductProps) =>
	(state: ProductsListViewState): ProductListProps => {
		const toHeaderProps = (): HeaderProps => {
			return {
				title: "Online Shop",
				images: state.productsListState.images.map((image) => ({
					type: CommonPropsType.IMAGE,
					title: image.name,
					source: image.url,
				})),
				location: {
					image: {
						type: CommonPropsType.IMAGE,
						source: state.selectedLocation.bannerImage.url,
						title: state.selectedLocation.bannerImage.name,
					},
					title: "Standort wählen",
					message: `Sie haben den Standort: ${state.selectedLocation.name} gewählt`,
					description:
						'Ihre Bestellung wird am gewählten Standort gepackt und kann nur am gewählten Standort abgeholt werden. Um den Standort zu ändern clicken Sie auf den Knopf mit dem Schriftzug "Wählen". vonLuck Standorte haben unterschiedliche Warenangebote. Bevor Sie jegliche Waren in den Warenkorb legen, müssen Sie den vonLuck Standort zur Abholung gewählt haben. Wenn Sie den Standort ändern wird Ihr Warenkorb geleert.',
					setLocation: {
						type: CommonPropsType.BUTTON,
						title: "Wählen",
						onSelect: () => {
							dispatch({
								type: ApplicationActionType.ROUTER_REDIRECT,
								path: PATH_SET_LOCATIONS,
							});
						},
					},
				},
			};
		};

		const toProducts = (): Array<ProductProps> => {
			const products: Array<Product> = dictionary.toValues(state.productsState["products"] || {});
			return products
				.filter(filterProductByLocationId(state.checkoutState.request.locationId))
				.filter(filterProductBySearchString(state.productsListState.searchString))
				.map((product) =>
					toProductProps({
						...product,
						amount: selectAmountForProductId(product.id)(state.checkoutState),
					})
				);
		};

		const headerProps = toHeaderProps();
		const productsProps = toProducts();

		switch (state.productsState.type) {
			case ProductsStateType.FETCHING:
				return {
					type: ProductListPropsType.LOADING,
					header: headerProps,
					products: productsProps,
				};
			case ProductsStateType.DATA: {
				const hasNoProducts = dictionary.length(state.productsState["products"] || {}) === 0;
				if (hasNoProducts) {
					return {
						type: ProductListPropsType.SKELETON,
						header: headerProps,
					};
				} else {
					return {
						type: ProductListPropsType.DEFAULT,
						header: headerProps,
						products: productsProps,
					};
				}
			}
			case ProductsStateType.ERROR:
				return {
					type: ProductListPropsType.ERROR,
					header: headerProps,
					errorMessage: "",
				};
		}
	};
