import * as React from "react";
import { useAppState } from "../../applicationState";
import { useMatch } from "react-router-dom";
import { PageWrapper as Component } from "./pageWrapper";
import { selectProductsListState } from "../productsList/selectors";
import { selectBasketValue } from "../checkout/selectors/selectors";
import { toHeaderProps, toFooterProps } from "./toProps";
import { PATH_SET_LOCATIONS } from "../../variables/urls";

export const PageWrapper: React.FC = (props) => {
	const SetLocationMatch = useMatch(PATH_SET_LOCATIONS);
	const productsListState = useAppState(selectProductsListState);
	const basketValue = useAppState(selectBasketValue);

	const isWithSearchBar = SetLocationMatch === null;
	const headerProps = toHeaderProps({ withSearchBar: isWithSearchBar, productsList: productsListState, basketValue });
	const footerProps = toFooterProps();

	return (
		<Component pageHeader={headerProps} pageFooter={footerProps}>
			{props.children}
		</Component>
	);
};
