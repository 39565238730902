import * as common from "common";
import * as SE from "redux-saga/effects";
import { ApplicationActionType } from "../applicationState/actions";

export const makeFetchEnvironmentVariables = (fetch: () => Promise<Array<common.EnvironmentVariableResponse>>) => {
	return function* worker() {
		while (true) {
			try {
				yield SE.take(ApplicationActionType.ENV_VARS_FETCH_ALL);
				const response: Array<common.EnvironmentVariableResponse> = yield SE.call(fetch);
				const variables = response.map(common.toEnvironmentVariable);
				yield SE.put({
					type: ApplicationActionType.ENV_VARS_SET_MANY,
					variables: variables,
				});
			} catch (_) {
				yield SE.put({
					type: ApplicationActionType.ENV_VARS_SET_MANY,
					variables: [],
				});
			}
		}
	};
};
