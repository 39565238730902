import * as React from "react";
import throttle from "lodash/throttle";
import { LinkProps, ButtonProps, IconType, TextInputProps, Link, Icon, Button, DisabledButton } from "common";

export interface PageWrapperHeaderProps {
	homeLink: LinkProps;
	navigation: Array<LinkProps>;
	search?: SearchbarProps;
	basket?: ButtonProps;
}

export const PageWrapperHeader: React.FC<PageWrapperHeaderProps> = (props) => {
	return (
		<div className="fixed z-10 w-screen bg-white dark:bg-black shadow dark:border-b-4 dark:border-gray-900">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="h-20 flex justify-between items-center space-x-2">
					<div className="flex-grow flex-shrink-0 h-20 mr-2 flex items-center">
						<Link
							{...props.homeLink}
							className="font-sans text-gray-900 text-xl sm:text-2xl dark:text-gray-200 cursor-pointer"
						>
							<span className="font-regluar">von</span>
							<span className="font-extrabold">Luck</span>
						</Link>
					</div>
					<div className="min-w-0 shrink">{props.search && <SearchBar {...props.search} />}</div>
					{!!props.basket && (
						<div data-test-id="basket" className="flex-shrink-0 min-w-max pl-2 flex items-center">
							<Basket {...props.basket} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export interface SearchbarProps {
	icon: IconType.SEARCH;
	input: TextInputProps;
	onStartSearching: () => void;
	onEndSearching: () => void;
	button?: ButtonProps;
}

const SearchBar: React.FC<SearchbarProps> = (props) => {
	const [searchString, setSearchString] = React.useState<string>("");

	const submitValue = () => props.input.onChange(searchString);

	React.useEffect(() => {
		setSearchString(props.input.value);
		// eslint-disable-next-line
	}, [props.input.value]);

	React.useEffect(() => {
		throttle(props.input.onChange, 500)(searchString);
		// eslint-disable-next-line
	}, [searchString]);

	return (
		<div className="group flex items-center space-x-2">
			<div className="input-group input-md md:flex md:space-x-2 overflow-hidden">
				<Icon
					type={props.icon}
					className="hidden md:block w-5 h-5 text-gray-600 dark:text-gray-200 flex-shrink-0"
				/>
				<input
					type="text"
					className="w-full text-ellipsis bg-white dark:bg-gray-800"
					value={searchString}
					placeholder={props.input.placeholder}
					onChange={(e) => setSearchString(e.target.value)}
					onKeyPress={(e) => {
						const pressedEnter = e.key === "Enter";
						if (pressedEnter) {
							e.preventDefault();
							submitValue();
						}
					}}
					onBlur={submitValue}
				/>
			</div>
			{props.input.value.length > 0 && (
				<Button title="Suche Abbrechen" onSelect={() => setSearchString("")} className="btn-2 btn-sm">
					<Icon type={IconType.CLOSE} className="w-6 h-6" />
				</Button>
			)}
			{searchString.length > 0 ? (
				<>
					<div className="sm:hidden">
						<Button title="Suchen" onSelect={submitValue} className="btn-1 btn-sm">
							<Icon type={props.icon} className="w-6 h-6" />
						</Button>
					</div>
					<div className="hidden sm:block">
						<Button title="Suchen" onSelect={submitValue} className="btn-1 btn-md">
							Suchen
						</Button>
					</div>
				</>
			) : (
				<>
					<div className="sm:hidden">
						<DisabledButton title="Suchen" className="sm:hidden btn-dis btn-sm">
							<Icon type={props.icon} className="w-6 h-6" />
						</DisabledButton>
					</div>
					<div className="hidden sm:block">
						<DisabledButton title="Suchen" className="hidden sm:block btn-dis btn-md">
							Suchen
						</DisabledButton>
					</div>
				</>
			)}
		</div>
	);
};

const Basket: React.FC<ButtonProps> = (props) => {
	return (
		<Button {...props} className="sm:p-2 text-gray-900 dark:text-gray-100 flex space-x-2">
			<span className="text-base font-medium font-sans sm:text-lg md:text-xl">{props.title}</span>
			{props.icon && <Icon type={props.icon} className="w-6 h-6" />}
		</Button>
	);
};
