export type OpeningHours = OpenHours | ClosedHours;

export enum OpeningHoursType {
	OPEN = "OPENING_HOURS_TYPE/OPEN",
	CLOSED = "OPENING_HOURS_TYPE/CLOSED",
}

export interface OpenHours {
	type: OpeningHoursType.OPEN;
	date: string;
	from: string;
	to: string;
	message: string;
}

export interface ClosedHours {
	type: OpeningHoursType.CLOSED;
	date: string;
	message: string;
}
