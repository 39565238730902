import { Image } from "../domain";

export interface ImageResponse {
	id: number;
	fields: {
		name: string;
		url: string;
		shopId?: number;
	};
}

export const toImage = (response: ImageResponse): Image => ({
	id: String(response.id),
	name: response.fields.name,
	url: response.fields.url,
});
