import React from "react";
import { Button, Icon } from "common";
import { CheckoutProps, CheckoutPropsType } from "../props/checkoutProps";
import { PRODUCTS_LIST_ELEMENT_ID, SUBMITTING_ERROR_ELEMENT_ID } from "../../../variables/environment";
import { LoadingView } from "../../../components/loadingView";
import { OrderView } from "./orderView";
import { ProductsListSummary } from "./productsListSummary";
import { SubmitButton } from "./submitButton";
import { ProductsList } from "./productsList";
import { Skeleton } from "./skeleton";
import { GoBackButton } from "./goBackButton";

export const CheckoutView: React.FC<CheckoutProps> = (props) => {
	switch (props.type) {
		case CheckoutPropsType.DEFAULT:
			return (
				<form className="w-full pb-12 space-y-8">
					<GoBackButton {...props.goBack} />
					<div id={PRODUCTS_LIST_ELEMENT_ID} className="pt-12 space-y-8">
						<h3 className="w-full text-1 text-3xl">{props.products.title}</h3>
						<ProductsList {...props.products} />
						<ProductsListSummary {...props.products} />
					</div>
					<OrderView {...props.order} />
					<div className="flex items-center justify-center sm:justify-end">
						<SubmitButton {...props.submit} />
					</div>
				</form>
			);
		case CheckoutPropsType.SKELETON:
			return (
				<form className="w-full pb-12 space-y-8">
					<GoBackButton {...props.goBack} />
					<div id={PRODUCTS_LIST_ELEMENT_ID} className="pt-12 space-y-8">
						<h3 className="w-full text-1 text-3xl">{props.products.title}</h3>
						<Skeleton {...props.products.skeleton} />
					</div>
					<OrderView {...props.order} />
				</form>
			);
		case CheckoutPropsType.LOADING:
		case CheckoutPropsType.SUBMITTING:
			return <LoadingView />;
		case CheckoutPropsType.ERROR:
			return (
				<form className="w-full pb-12 space-y-8">
					<GoBackButton {...props.goBack} />
					<div id={PRODUCTS_LIST_ELEMENT_ID} className="pt-12 space-y-8">
						<h3 className="w-full text-1 text-3xl">{props.products.title}</h3>
						<ProductsList {...props.products} />
						<ProductsListSummary {...props.products} />
					</div>
					<OrderView {...props.order} />
					<div
						id={SUBMITTING_ERROR_ELEMENT_ID}
						className="bg-red-900 border-2 p-4 border-red-700 rounded-md flex items-center justify-center"
					>
						<p className="text-red-500 text-sm">{props.errorMessage}</p>
					</div>
					<div className="flex items-center justify-center sm:justify-end">
						<SubmitButton {...props.submit} />
					</div>
				</form>
			);
		case CheckoutPropsType.FINISHED:
			return (
				<div className="w-full h-full flex flex-col items-center justify-center space-y-8">
					<h2 className="text-2 text-xl">{props.title}</h2>
					<p className="text-3 text-sm">{props.message}</p>
					<Button {...props.continueShopping} className="btn-1 btn-md">
						{props.continueShopping.title}
					</Button>
					<div className="flex items-center space-x-4">
						<p className="text-xs text-3">{props.localStorageWarning.message}</p>
						<Button
							{...props.localStorageWarning.clear}
							className="flex-shrink-0 btn-2 btn-sm flex space-x-2"
						>
							{props.localStorageWarning.clear.icon && (
								<Icon type={props.localStorageWarning.clear.icon} className="w-5 h-5" />
							)}
							<span className="sr-only sm:not-sr-only">{props.localStorageWarning.clear.title}</span>
						</Button>
					</div>
				</div>
			);
		default:
			return null;
	}
};
