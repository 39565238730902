import { toReduxReducer } from "common";
import * as EnvVars from "../features/environmentVariables/state";
import * as ProductsState from "../features/products/state";
import * as CheckoutState from "../features/checkout/state";
import * as ProductsListState from "../features/productsList/state";
import * as LocationsState from "../features/locations/state";
import * as OpeningHoursState from "../features/openingHours/state";
import { combineReducers } from "redux";
import { productsReducer } from "../features/products/productsReducer";
import { checkoutReducer } from "../features/checkout/reducers/checkoutReducer";
import { locationsReducer } from "../features/locations/reducer/locationsReducer";
import { productsListReducer } from "../features/productsList/productsListReducer";
import { environmentVariablesReducer } from "../features/environmentVariables/environmentVariablesReducer";
import { openingHoursReducer } from "../features/openingHours/openingHoursReducer";

export const rootReducer = combineReducers({
	environmentVariables: toReduxReducer(environmentVariablesReducer, EnvVars.initialState()),
	locations: toReduxReducer(locationsReducer, LocationsState.initialState()),
	openingHours: toReduxReducer(openingHoursReducer, OpeningHoursState.initialState()),
	checkout: toReduxReducer(checkoutReducer, CheckoutState.initialState()),
	productsList: toReduxReducer(productsListReducer, ProductsListState.initialState()),
	products: toReduxReducer(productsReducer, ProductsState.initialState()),
});
