import { dictionary } from "common";

export enum EnvironmentVariablesStateType {
	FETCHING = "ENVIRONMENT_VARIABLES_STATE_TYPE/FETCHING",
	DATA = "ENVIRONMENT_VARIABLES_STATE_TYPE/DATA",
}

export interface EnvironmentVariablesState {
	type: EnvironmentVariablesStateType;
	variables: dictionary.Dictionary<string>;
}

export const initialState = (): EnvironmentVariablesState => ({
	type: EnvironmentVariablesStateType.FETCHING,
	variables: {},
});
