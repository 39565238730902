import * as common from "common";
import * as SE from "redux-saga/effects";
import * as emails from "../variables/emails";
import { ApplicationActionType, CheckoutAction } from "../applicationState/actions";
import { CheckoutState, ValidRequestData } from "../features/checkout/state";
import { CreateOrderRequest } from "./ordersApi/orders";
import {
	selectCheckoutState,
	selectBasketValue,
	selectValidCheckoutRequest,
	selectProductsWithAmount,
} from "../features/checkout/selectors/selectors";
import { selectPaypalMinBasketValue } from "../features/environmentVariables/selectors";
import { SUBMITTING_ERROR_ELEMENT_ID } from "../variables/environment";

interface RequestDataInLocalStorage {
	products: { [key: string]: number | undefined };
	locationId?: string;
	name?: string;
	email?: string;
	phone?: string;
	date?: string;
	time?: string;
	message?: string;
	paymentMethod?: string;
}

export const makeCheckoutSideEffectsWorker = () => {
	return function* worker() {
		while (true) {
			try {
				const action: CheckoutAction = yield SE.take([
					ApplicationActionType.CHECKOUT_SET_REQUEST,
					ApplicationActionType.CHECKOUT_SET_LOCATION,
					ApplicationActionType.CHECKOUT_INC_PRODUCT,
					ApplicationActionType.CHECKOUT_DEC_PRODUCT,
					ApplicationActionType.CHECKOUT_REMOVE_PRODUCT,
					ApplicationActionType.CHECKOUT_SET_PRODUCT,
					ApplicationActionType.CHECKOUT_SET_NAME,
					ApplicationActionType.CHECKOUT_SET_EMAIL,
					ApplicationActionType.CHECKOUT_SET_PHONE,
					ApplicationActionType.CHECKOUT_SET_DATE,
					ApplicationActionType.CHECKOUT_SET_TIME,
					ApplicationActionType.CHECKOUT_SET_MESSAGE,
					ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD,
					ApplicationActionType.CHECKOUT_SET_FINISHED,
				]);
				const state: CheckoutState = yield SE.select(selectCheckoutState);
				const basketValue: number = yield SE.select(selectBasketValue);
				const paypalMinBasketValue: number = yield SE.select(selectPaypalMinBasketValue);
				switch (action.type) {
					case ApplicationActionType.CHECKOUT_SET_REQUEST:
					case ApplicationActionType.CHECKOUT_INC_PRODUCT:
					case ApplicationActionType.CHECKOUT_DEC_PRODUCT:
					case ApplicationActionType.CHECKOUT_REMOVE_PRODUCT:
					case ApplicationActionType.CHECKOUT_SET_PRODUCT:
						const hasSomePaymentMethod = state.request.paymentMethod !== undefined;
						if (hasSomePaymentMethod && basketValue < paypalMinBasketValue) {
							yield SE.put({
								type: ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD,
								method: common.PaymentMethodType.AT_LOCATION,
							});
						}
						break;
				}
				switch (action.type) {
					case ApplicationActionType.CHECKOUT_SET_LOCATION:
					case ApplicationActionType.CHECKOUT_INC_PRODUCT:
					case ApplicationActionType.CHECKOUT_DEC_PRODUCT:
					case ApplicationActionType.CHECKOUT_REMOVE_PRODUCT:
					case ApplicationActionType.CHECKOUT_SET_PRODUCT:
					case ApplicationActionType.CHECKOUT_SET_NAME:
					case ApplicationActionType.CHECKOUT_SET_EMAIL:
					case ApplicationActionType.CHECKOUT_SET_PHONE:
					case ApplicationActionType.CHECKOUT_SET_DATE:
					case ApplicationActionType.CHECKOUT_SET_TIME:
					case ApplicationActionType.CHECKOUT_SET_MESSAGE:
					case ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD: {
						const data: RequestDataInLocalStorage = {
							products: state.request.products,
							locationId: state.request.locationId,
							name: state.request.name,
							email: state.request.email,
							phone: state.request.phone,
							date: state.request.date ? state.request.date.toJSON() : undefined,
							time: state.request.time,
							message: state.request.message,
							paymentMethod: state.request.paymentMethod,
						};
						const value: string = JSON.stringify(data);
						yield SE.put({
							type: ApplicationActionType.BROWSER_SAVE_TO_LOCAL_STORAGE,
							value: value,
						});
						break;
					}
					case ApplicationActionType.CHECKOUT_SET_FINISHED:
						const data: RequestDataInLocalStorage = {
							products: {},
							locationId: state.request.locationId,
							name: state.request.name,
							email: state.request.email,
							phone: state.request.phone,
						};
						const value: string = JSON.stringify(data);
						yield SE.put({
							type: ApplicationActionType.BROWSER_SAVE_TO_LOCAL_STORAGE,
							value: value,
						});
						break;
				}
			} catch (_) {}
		}
	};
};

export const makeLoadFromLocalStorageWorker = (loadFromLocalStorage: () => string | undefined) => {
	return function* worker() {
		while (true) {
			try {
				yield SE.take(ApplicationActionType.CHECKOUT_LOAD_FROM_LOCAL_STORAGE);
				const value: string | undefined = loadFromLocalStorage();
				if (value === undefined) {
					yield SE.put({
						// load nothing
						type: ApplicationActionType.CHECKOUT_SET_REQUEST,
						request: {
							products: {},
						},
					});
				} else {
					const data: RequestDataInLocalStorage = JSON.parse(value);
					yield SE.put({
						type: ApplicationActionType.CHECKOUT_SET_REQUEST,
						request: {
							...data,
							date: data.date ? new Date(data.date) : undefined,
						},
					});
				}
			} catch (_) {}
		}
	};
};

export const makeOrderWorker = (createOrder: (request: CreateOrderRequest) => Promise<common.Order>) => {
	return function* worker() {
		while (true) {
			try {
				yield SE.take(ApplicationActionType.CHECKOUT_SET_SUBMITTING);
				yield SE.put({
					type: ApplicationActionType.BROWSER_SCROLL_TO_TOP,
				});
				const basketValue: number = yield SE.select(selectBasketValue);
				const productsWithAmount: Array<common.Product & { amount: number }> = yield SE.select(
					selectProductsWithAmount
				);
				const request: ValidRequestData | undefined = yield SE.select(selectValidCheckoutRequest);
				if (request === undefined) {
					yield SE.put({
						type: ApplicationActionType.CHECKOUT_SET_SUBMITTING_ERROR,
						error: Error("Request is not valid!"),
					});
					yield SE.call(common.sleep, 300);
					yield SE.put({
						type: ApplicationActionType.BROWSER_SCROLL_TO_ELEMENT,
						elementId: SUBMITTING_ERROR_ELEMENT_ID,
					});
				} else {
					const order: common.Order = yield SE.call(createOrder, {
						...request,
						products: productsWithAmount,
					});
					const vonLuckNoReplyContact: common.EmailContact = {
						name: "vonLuck No Reply",
						emailAddress: emails.VON_LUCK_NO_REPLY_EMAIL,
					};
					const emailToVonLuck: common.Email = {
						from: vonLuckNoReplyContact,
						to: {
							name: "vonLuck",
							emailAddress: emails.VON_LUCK_ORDER_EMAIL,
						},
						replyTo: vonLuckNoReplyContact,
						subject: emails.vonLuckOrderConformationSubject({
							name: request.name,
							paymentMethod: emails.paymentMethod(request.paymentMethod),
						}),
						content: {
							type: "text/plain",
							content: emails.vonLuckOrderConformation({
								name: request.name,
								email: request.email,
								phone: request.phone || "",
								date: common.toDateString(request.date),
								time: request.time,
								message: request.message || "",
								location: request.locationId === "1" ? "Berlin Nikolassee" : "Berlin Mitte",
								paymentMethod: emails.paymentMethod(request.paymentMethod),
								price: common.toPriceString(basketValue),
								products: productsWithAmount,
							}),
						},
					};
					yield SE.put({
						type: ApplicationActionType.EMAIL_SEND,
						email: emailToVonLuck,
					});
					const emailToCustomer: common.Email = {
						from: vonLuckNoReplyContact,
						to: {
							name: request.name,
							emailAddress: request.email,
						},
						replyTo: vonLuckNoReplyContact,
						subject: emails.customerOrderConformationSubject(),
						content: {
							type: "text/plain",
							content: emails.customerOrderConformation({
								pickUpId: order.orderPickUpId,
								name: request.name,
								email: request.email,
								phone: request.phone || "Keine Angaben",
								date: common.toDateString(request.date),
								time: request.time,
								message: request.message || "Keine Angaben",
								location: request.locationId === "1" ? "Berlin Nikolassee" : "Berlin Mitte",
								paymentMethod: emails.paymentMethod(request.paymentMethod),
								price: common.toPriceString(basketValue),
								products: productsWithAmount,
							}),
						},
					};
					yield SE.put({
						type: ApplicationActionType.EMAIL_SEND,
						email: emailToCustomer,
					});
					yield SE.put({
						type: ApplicationActionType.CHECKOUT_SET_FINISHED,
					});
					yield SE.put({
						type: ApplicationActionType.BROWSER_SCROLL_TO_TOP,
					});
				}
			} catch (error) {
				yield SE.put({
					type: ApplicationActionType.CHECKOUT_SET_SUBMITTING_ERROR,
					error: error,
				});
				yield SE.call(common.sleep, 300);
				yield SE.put({
					type: ApplicationActionType.BROWSER_SCROLL_TO_ELEMENT,
					elementId: SUBMITTING_ERROR_ELEMENT_ID,
				});
			}
		}
	};
};
