import React from "react";
import { TextInputProps } from "common";

export const AmountInput: React.FC<TextInputProps> = (props) => {
	const [state, setState] = React.useState<string>("0");

	const submitValue = () => {
		const stateAsNumber = Number.isNaN(state);
		const isValidState = !Number.isNaN(stateAsNumber);
		if (isValidState) {
			props.onChange(state);
		}
	};

	React.useEffect(() => {
		setState(props.value);
		// eslint-disable-next-line
	}, [props.value]);

	return (
		<input
			type="number"
			pattern="\d*"
			className="bg-gray-100 dark:bg-gray-800 h-full p-2 border border-gray-200 dark:border-gray-800 rounded-md min-w-0 w-full text-xs text-right outline-none focus:ring focus:ring-green-400"
			value={state}
			placeholder={props.placeholder}
			onChange={(event) => {
				const value = event.target.value;
				setState((prevState) => {
					const isValidValue = /\d*/.test(value);
					if (isValidValue) {
						return value;
					} else {
						return prevState;
					}
				});
			}}
			onKeyPress={(e) => {
				const pressedEnter = e.key === "Enter";
				if (pressedEnter) {
					e.preventDefault();
					submitValue();
				}
			}}
			onBlur={submitValue}
		/>
	);
};
