import * as React from "react";
import useOutsideComponentDetection from "../hooks/useOutsideComponentDetection";
import { useBooleanState } from "../hooks/useBooleanState";

export enum MenuAlignmentType {
	LEFT = "MENU_ALIGNMENT_TYPE/LEFT",
	RIGHT = "MENU_ALIGNMENT_TYPE/RIGHT",
	CENTER = "MENU_ALIGNMENT_TYPE/CENTER",
}

export interface MenuComponentProps {
	showMenu: boolean;
	toggleMenu: () => void;
}

interface MenuProps {
	alignment?: MenuAlignmentType;
	header: React.FC<MenuComponentProps>;
	content: React.FC<MenuComponentProps>;
}

export const Menu: React.FC<MenuProps> = (props) => {
	const { isTrue: showMenu, toggle: toggleMenu, setFalse: closeMenu } = useBooleanState();
	const ref = useOutsideComponentDetection({
		outsideCallback: closeMenu,
	});
	return (
		<div ref={ref} className="relative w-full">
			<props.header showMenu={showMenu} toggleMenu={toggleMenu} />
			{showMenu && (
				<MenuContentContainer alignment={props.alignment}>
					<props.content showMenu={showMenu} toggleMenu={toggleMenu} />
				</MenuContentContainer>
			)}
		</div>
	);
};

const MenuContentContainer: React.FC<{ alignment?: MenuAlignmentType }> = (props) => {
	switch (props.alignment) {
		case MenuAlignmentType.LEFT:
			return (
				<div
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex={-1}
					className="z-10 origin-top-left absolute left-0"
				>
					{props.children}
				</div>
			);
		case MenuAlignmentType.CENTER:
			return (
				<div
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex={-1}
					className="z-10 origin-top absolute top-0"
				>
					{props.children}
				</div>
			);
		default:
			return (
				<div
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex={-1}
					className="z-10 origin-top-right absolute right-0"
				>
					{props.children}
				</div>
			);
	}
};
