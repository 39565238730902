import { ProductProps } from "./productProps";
import { HeaderProps } from "../views/headerView";

export type ProductListProps =
	| LoadingProductListProps
	| SkeletonProductListProps
	| DefaultProductListProps
	| ErrorProductListProps;

export enum ProductListPropsType {
	LOADING = "PRODUCT_LIST_TYPE/LOADING",
	SKELETON = "PRODUCT_LIST_TYPE/SKELETON",
	DEFAULT = "PRODUCT_LIST_TYPE/DEFAULT",
	ERROR = "PRODUCT_LIST_TYPE/ERROR",
}

export interface LoadingProductListProps {
	type: ProductListPropsType.LOADING;
	header: HeaderProps;
	products: Array<ProductProps>;
}

export interface SkeletonProductListProps {
	type: ProductListPropsType.SKELETON;
	header: HeaderProps;
}

export interface DefaultProductListProps {
	type: ProductListPropsType.DEFAULT;
	header: HeaderProps;
	products: Array<ProductProps>;
}

export interface ErrorProductListProps {
	type: ProductListPropsType.ERROR;
	header: HeaderProps;
	errorMessage: string;
}
