import * as React from "react";
import { BaseButtonProps, WithClassNames, BaseDisabledButtonProps } from "../models";

export const Button: React.FC<BaseButtonProps & WithClassNames> = (props) => {
	return (
		<button type="button" onClick={props.onSelect} className={props.className}>
			{props.children}
		</button>
	);
};

export const DisabledButton: React.FC<BaseDisabledButtonProps & WithClassNames> = (props) => {
	return (
		<button type="button" disabled onClick={() => {}} className={props.className}>
			{props.children}
		</button>
	);
};
