import * as common from "common";
import { Action, Image, Location, OpeningHoursRule, Product } from "common";
import { ProductsListStateType } from "../features/productsList/state";
import { Variable } from "../features/environmentVariables/models";

export enum ApplicationActionType {
	ROUTER_REDIRECT = "APPLICATION_ACTION_TYPE/ROUTER/REDIRECT",

	ENV_VARS_FETCH_ALL = "APPLICATION_ACTION_TYPE/ENV_VARS/FETCH_ALL",
	ENV_VARS_SET_MANY = "APPLICATION_ACTION_TYPE/ENV_VARS/SET_MANY",

	PRODUCTS_FETCH_MORE = "APPLICATION_ACTION_TYPE/PRODUCTS/FETCH_MORE",
	PRODUCTS_SET_MANY = "APPLICATION_ACTION_TYPE/PRODUCTS/SET_MANY",
	PRODUCTS_ADD_MANY = "APPLICATION_ACTION_TYPE/PRODUCTS/ADD_MANY",
	PRODUCTS_SET_ERROR = "APPLICATION_ACTION_TYPE/PRODUCTS/SET_ERROR",

	CHECKOUT_SET_LOCATION = "APPLICATION_ACTION_TYPE/CHECKOUT/LOCATION/SET",
	CHECKOUT_SET_NAME = "APPLICATION_ACTION_TYPE/CHECKOUT/NAME/SET",
	CHECKOUT_SAVE_REQUEST_TO_LOCAL_STORAGE = "APPLICATION_ACTION_TYPE/CHECKOUT/SAVE_REQUEST_TO_LOCAL_STORAGE",
	CHECKOUT_LOAD_FROM_LOCAL_STORAGE = "APPLICATION_ACTION_TYPE/CHECKOUT/LOAD_FROM_LOCAL_STORAGE",
	CHECKOUT_SET_EMAIL = "APPLICATION_ACTION_TYPE/CHECKOUT/EMAIL/SET",
	CHECKOUT_SET_PHONE = "APPLICATION_ACTION_TYPE/CHECKOUT/PHONE/SET",
	CHECKOUT_SET_DATE = "APPLICATION_ACTION_TYPE/CHECKOUT/DATE/SET",
	CHECKOUT_SET_TIME = "APPLICATION_ACTION_TYPE/CHECKOUT/TIME/SET",
	CHECKOUT_SET_MESSAGE = "APPLICATION_ACTION_TYPE/CHECKOUT/MESSAGE/SET",
	CHECKOUT_EXTEND_MESSAGE = "APPLICATION_ACTION_TYPE/CHECKOUT/MESSAGE/EXTEND",
	CHECKOUT_SET_PAYMENT_METHOD = "APPLICATION_ACTION_TYPE/CHECKOUT/PAYMENT_METHOD/SET",
	CHECKOUT_SET_PAYMENT_STATE = "APPLICATION_ACTION_TYPE/CHECKOUT/PAYMENT_STATE/SET",
	CHECKOUT_TOGGLE_PRIVACY = "APPLICATION_ACTION_TYPE/CHECKOUT/PRIVACY/TOGGLE",
	CHECKOUT_SET_REQUEST = "APPLICATION_ACTION_TYPE/CHECKOUT/SET",
	CHECKOUT_SET_PRODUCT = "APPLICATION_ACTION_TYPE/CHECKOUT/PRODUCT/SET",
	CHECKOUT_INC_PRODUCT = "APPLICATION_ACTION_TYPE/CHECKOUT/PRODUCT/ADD",
	CHECKOUT_DEC_PRODUCT = "APPLICATION_ACTION_TYPE/CHECKOUT/PRODUCT/REDUCE",
	CHECKOUT_REMOVE_PRODUCT = "APPLICATION_ACTION_TYPE/CHECKOUT/PRODUCT/REMOVE",
	CHECKOUT_ABORT_SUBMITTING = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/ABORT_SUBMITTING",
	CHECKOUT_SET_SUBMITTING = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/SET_SUBMITTING",
	CHECKOUT_SET_VALIDATING = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/SET_VALIDATING",
	CHECKOUT_SET_SUBMITTING_ERROR = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/SET_SUBMITTING_ERROR",
	CHECKOUT_SET_FINISHED = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/SET_FINISHED",
	CHECKOUT_RESET = "APPLICATION_ACTION_TYPE/CHECKOUT/STATE/RESET",

	PRODUCTS_LIST_SET_SEARCH_STRING = "APPLICATION_ACTION_TYPE/PRODUCTS_LIST/SEARCH/SEARCH_STRING/SET",
	PRODUCTS_LIST_SET_IMAGES = "APPLICATION_ACTION_TYPE/PRODUCTS_LIST/IMAGES/SET",
	PRODUCTS_LIST_SET_STATE_TYPE = "APPLICATION_ACTION_TYPE/PRODUCTS_LIST/STATE_TYPE/SET",

	LOCATIONS_FETCH = "APPLICATION_ACTION_TYPE/LOCATIONS/FETCH",
	LOCATIONS_SET_MANY = "APPLICATION_ACTION_TYPE/LOCATIONS/SET_MANY",
	LOCATIONS_SET_ERROR = "APPLICATION_ACTION_TYPE/LOCATIONS/ERROR/SET",

	OPENING_HOURS_FETCH = "APPLICATION_ACTION_TYPE/OPENING_HOURS/FETCH",
	OPENING_HOURS_SET_MANY = "APPLICATION_ACTION_TYPE/OPENING_HOURS/SET_MANY",
	OPENING_HOURS_SET_ERROR = "APPLICATION_ACTION_TYPE/OPENING_HOURS/ERROR/SET",

	EMAIL_SEND = "APPLICATION_ACTION_TYPE/EMAIL/SEND",

	BROWSER_SAVE_TO_LOCAL_STORAGE = "APPLICATION_ACTION_TYPE/BROWSER/LOCAL_STORAGE/SAVE",
	BROWSER_LOAD_FROM_LOCAL_STORAGE = "APPLICATION_ACTION_TYPE/BROWSER/LOCAL_STORAGE/LOAD",
	BROWSER_CLEAR_LOCAL_STORAGE = "APPLICATION_ACTION_TYPE/BROWSER/LOCAL_STORAGE/CLEAR",
	BROWSER_SCROLL_TO_TOP = "APPLICATION_ACTION_TYPE/BROWSER/SCROLL/TO_TOP",
	BROWSER_SCROLL_TO_ELEMENT = "APPLICATION_ACTION_TYPE/BROWSER/SCROLL/TO_ELEMENT",
}

export type ApplicationAction =
	| EnvVarsAction
	| RouterAction
	| ProductAction
	| CheckoutAction
	| LocationsAction
	| OpeningHoursAction
	| ProductsListAction
	| EmailAction
	| BrowserAction;

export type EnvVarsAction = EnvVarsFetchAll | EnvVarsSetMany;
export type EnvVarsFetchAll = Action<ApplicationActionType.ENV_VARS_FETCH_ALL>;
export type EnvVarsSetMany = Action<ApplicationActionType.ENV_VARS_SET_MANY, { variables: Array<Variable> }>;

export type ProductAction = ProductsFetchMore | ProductsSetMany | ProductsAddMany | ProductsSetError;
export type ProductsFetchMore = Action<ApplicationActionType.PRODUCTS_FETCH_MORE>;
export type ProductsSetMany = Action<ApplicationActionType.PRODUCTS_SET_MANY, { products: Array<Product> }>;
export type ProductsAddMany = Action<ApplicationActionType.PRODUCTS_ADD_MANY, { products: Array<Product> }>;
export type ProductsSetError = Action<ApplicationActionType.PRODUCTS_SET_ERROR, { error: Error }>;

export type CheckoutAction =
	| CheckoutSetRequest
	| CheckoutSetLocation
	| CheckoutSetName
	| CheckoutSetEmail
	| CheckoutSetPhone
	| CheckoutSetDate
	| CheckoutSetTime
	| CheckoutSetMessage
	| CheckoutExtendMessage
	| CheckoutSetPaymentMethod
	| CheckoutSetPaymentState
	| CheckoutTogglePrivacy
	| CheckoutSetProduct
	| CheckoutAddProduct
	| CheckoutReduceProduct
	| CheckoutRemoveProduct
	| CheckoutSaveRequestToLocalStorage
	| CheckoutLoadFromLocalStorage
	| CheckoutSetStateSubmitting
	| CheckoutAbortSubmitting
	| CheckoutSetStateValidating
	| CheckoutSetStateSubmittingError
	| CheckoutSetStateFinished
	| CheckoutReset;
export type CheckoutSetRequest = Action<ApplicationActionType.CHECKOUT_SET_REQUEST, { request: any }>;
export type CheckoutSetLocation = Action<ApplicationActionType.CHECKOUT_SET_LOCATION, { locationId: string }>;
export type CheckoutSetName = Action<ApplicationActionType.CHECKOUT_SET_NAME, { name: string }>;
export type CheckoutSetEmail = Action<ApplicationActionType.CHECKOUT_SET_EMAIL, { email: string }>;
export type CheckoutSetPhone = Action<ApplicationActionType.CHECKOUT_SET_PHONE, { phone: string }>;
export type CheckoutSetDate = Action<ApplicationActionType.CHECKOUT_SET_DATE, { date: Date }>;
export type CheckoutSetTime = Action<ApplicationActionType.CHECKOUT_SET_TIME, { time: string }>;
export type CheckoutSetMessage = Action<ApplicationActionType.CHECKOUT_SET_MESSAGE, { message: string }>;
export type CheckoutExtendMessage = Action<ApplicationActionType.CHECKOUT_EXTEND_MESSAGE, { message: string }>;
export type CheckoutSetPaymentMethod = Action<
	ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD,
	{ method: common.PaymentMethodType }
>;
export type CheckoutSetPaymentState = Action<
	ApplicationActionType.CHECKOUT_SET_PAYMENT_STATE,
	{ state: common.PaymentStateType }
>;
export type CheckoutTogglePrivacy = Action<ApplicationActionType.CHECKOUT_TOGGLE_PRIVACY>;
export type CheckoutSetProduct = Action<
	ApplicationActionType.CHECKOUT_SET_PRODUCT,
	{ productId: string; amount: number }
>;
export type CheckoutAddProduct = Action<ApplicationActionType.CHECKOUT_INC_PRODUCT, { productId: string }>;
export type CheckoutReduceProduct = Action<ApplicationActionType.CHECKOUT_DEC_PRODUCT, { productId: string }>;
export type CheckoutRemoveProduct = Action<ApplicationActionType.CHECKOUT_REMOVE_PRODUCT, { productId: string }>;
export type CheckoutSaveRequestToLocalStorage = Action<ApplicationActionType.CHECKOUT_SAVE_REQUEST_TO_LOCAL_STORAGE>;
export type CheckoutLoadFromLocalStorage = Action<ApplicationActionType.CHECKOUT_LOAD_FROM_LOCAL_STORAGE>;
export type CheckoutAbortSubmitting = Action<ApplicationActionType.CHECKOUT_ABORT_SUBMITTING>;
export type CheckoutSetStateSubmitting = Action<ApplicationActionType.CHECKOUT_SET_SUBMITTING>;
export type CheckoutSetStateValidating = Action<ApplicationActionType.CHECKOUT_SET_VALIDATING>;
export type CheckoutSetStateSubmittingError = Action<
	ApplicationActionType.CHECKOUT_SET_SUBMITTING_ERROR,
	{ error: Error }
>;
export type CheckoutSetStateFinished = Action<ApplicationActionType.CHECKOUT_SET_FINISHED>;
export type CheckoutReset = Action<ApplicationActionType.CHECKOUT_RESET>;

export type ProductsListAction = ProductsListSetStateType | ProductsListSetImages | ProductsListSetSearchString;
export type ProductsListSetStateType = Action<
	ApplicationActionType.PRODUCTS_LIST_SET_STATE_TYPE,
	{ stateType: ProductsListStateType }
>;
export type ProductsListSetImages = Action<ApplicationActionType.PRODUCTS_LIST_SET_IMAGES, { images: Array<Image> }>;
export type ProductsListSetSearchString = Action<
	ApplicationActionType.PRODUCTS_LIST_SET_SEARCH_STRING,
	{ searchString: string }
>;

export type LocationsAction = LocationsFetch | LocationsSetMany | LocationsSetError;
export type LocationsFetch = Action<ApplicationActionType.LOCATIONS_FETCH>;
export type LocationsSetMany = Action<ApplicationActionType.LOCATIONS_SET_MANY, { locations: Array<Location> }>;
export type LocationsSetError = Action<ApplicationActionType.LOCATIONS_SET_ERROR, { error: Error }>;

export type OpeningHoursAction = OpeningHoursFetch | OpeningHoursSetMany | OpeningHoursError;
export type OpeningHoursFetch = Action<ApplicationActionType.OPENING_HOURS_FETCH>;
export type OpeningHoursSetMany = Action<
	ApplicationActionType.OPENING_HOURS_SET_MANY,
	{ openingHours: Array<OpeningHoursRule> }
>;
export type OpeningHoursError = Action<ApplicationActionType.OPENING_HOURS_SET_ERROR, { error: Error }>;

export type EmailAction = EmailSend;
export type EmailSend = Action<ApplicationActionType.EMAIL_SEND, { email: common.Email }>;

export type RouterAction = RouterRedirect;
export type RouterRedirect = Action<ApplicationActionType.ROUTER_REDIRECT, { path: string }>;

export type BrowserAction =
	| BrowserClearLocalStorage
	| BrowserSaveToLocalStorage
	| BrowserLoadFromLocalStorage
	| BrowserScrollToTop;
export type BrowserClearLocalStorage = Action<ApplicationActionType.BROWSER_CLEAR_LOCAL_STORAGE>;
export type BrowserSaveToLocalStorage = Action<ApplicationActionType.BROWSER_SAVE_TO_LOCAL_STORAGE, { value: string }>;
export type BrowserLoadFromLocalStorage = Action<ApplicationActionType.BROWSER_LOAD_FROM_LOCAL_STORAGE>;
export type BrowserScrollToTop = Action<ApplicationActionType.BROWSER_SCROLL_TO_TOP>;
export type BrowserScrollToElement = Action<ApplicationActionType.BROWSER_SCROLL_TO_TOP, { elementId: string }>;
