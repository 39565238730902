import React from "react";
import { ApplicationActionType } from "../../applicationState/actions";
import { useAppState, store } from "../../applicationState";
import { PATH_SET_LOCATIONS } from "../../variables/urls";
import { selectCheckoutState } from "../checkout/selectors/selectors";
import { LoadingView } from "../../components/loadingView";

export const ForceLocationIdInState: React.FC = (props) => {
	const checkoutState = useAppState(selectCheckoutState);

	React.useEffect(() => {
		const hasNoLocationIdInState = checkoutState.request.locationId === undefined;
		if (hasNoLocationIdInState) {
			store.dispatch({
				type: ApplicationActionType.ROUTER_REDIRECT,
				path: PATH_SET_LOCATIONS,
			});
		}
		// eslint-disable-next-line
	}, [checkoutState.request.locationId]);

	const hasNoLocationIdInState = checkoutState.request.locationId === undefined;
	if (hasNoLocationIdInState) {
		// eslint-disable-next-line
		console.error(Error("ForceLocationIdInState: has rendered with no locationId in state"));
		return <LoadingView />;
	} else {
		return <>{props.children}</>;
	}
};
