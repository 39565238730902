import { CommonPropsType, IconType, Product, toPriceString, truncate } from "common";
import { ProductProps } from "../props/productProps";
import { Dispatch } from "../../../applicationState";
import { ApplicationActionType } from "../../../applicationState/actions";

export type ProductPropsViewState = Product & {
	amount?: number;
};
export const makeToProductProps =
	(dispatch: Dispatch) =>
	(state: ProductPropsViewState): ProductProps => {
		return {
			amount: state.amount !== undefined && state.amount > 0 ? String(state.amount) : undefined,
			image: {
				type: CommonPropsType.IMAGE,
				source: state.imageURL,
				title: state.name,
			},
			title: state.name,
			description: truncate(state.description, 100),
			ingredients: truncate(state.ingredients || "", 50),
			price: toPriceString(state.price),
			input: {
				type: CommonPropsType.TEXT_INPUT,
				value: state.amount ? String(state.amount) : "",
				placeholder: "Anzahl",
				onChange: (value) => {
					const valueAsNumber = Number(value);
					const isANumber = !Number.isNaN(valueAsNumber);
					if (isANumber) {
						dispatch({
							type: ApplicationActionType.CHECKOUT_SET_PRODUCT,
							productId: state.id,
							amount: valueAsNumber,
						});
					}
				},
			},
			addOne: {
				type: CommonPropsType.BUTTON,
				title: "1 Hinzufügen",
				icon: IconType.ADD,
				onSelect: () => {
					dispatch({
						type: ApplicationActionType.CHECKOUT_INC_PRODUCT,
						productId: state.id,
					});
				},
			},
			removeOne:
				state.amount === undefined || state.amount === 0
					? undefined
					: {
							type: CommonPropsType.BUTTON,
							title: "1 Entfernen",
							icon: IconType.REMOVE,
							onSelect: () => {
								dispatch({
									type: ApplicationActionType.CHECKOUT_DEC_PRODUCT,
									productId: state.id,
								});
							},
					  },
			removeAll:
				state.amount === undefined
					? undefined
					: {
							type: CommonPropsType.BUTTON,
							title: "Alle Entfernen",
							icon: IconType.CLOSE,
							onSelect: () => {
								dispatch({
									type: ApplicationActionType.CHECKOUT_REMOVE_PRODUCT,
									productId: state.id,
								});
							},
					  },
		};
	};
