interface Request {
	header: string;
	orderDetails: string;
	footer: string;
}
export const vonLuckOrderConformationText = (req: Request): string => `
${req.header}

Neue Bestellung


${req.orderDetails}


${req.footer}
`;
