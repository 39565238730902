import { Reducer } from "../models";

export type Pad = (num: number, size: number) => string;
export const pad: Pad = (num, size) => {
	let asString = num.toString();
	while (asString.length < size) asString = "0" + asString;
	return asString;
};

export const trace =
	(name: string) =>
	<T>(data: T): T => {
		console.log(name, data);
		return data;
	};

export const truncate = (text: string, length: number): string => {
	if (text.length > length) {
		return text.slice(0, length - 3) + "...";
	} else {
		return text;
	}
};

export type Compact = <T>(list: Array<T | undefined>) => Array<T>;
export const compact: Compact = (list) => list.filter((x) => x !== undefined);

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface ReducerExpectationTestCase<State, Action> {
	state: State;
	action: Action;
	expectedState: State;
}
export const reducerExpectation =
	<State>(reducer: Reducer<State, any>) =>
	<Action>(testCase: ReducerExpectationTestCase<State, Action>) => {
		expect(reducer(testCase.state, testCase.action)).toEqual(testCase.expectedState);
	};
