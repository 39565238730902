import * as React from "react";
import { IconType } from "../models/domain";
import { WithClassNames } from "../../../models";
import DefaultIcon from "./icons/defaultIcon";
import * as Exclamation from "./icons/exclamation";
import * as Trash from "./icons/trash";
import Location from "./icons/location";
import Phone from "./icons/phone";
import Mail from "./icons/mail";
import Open from "./icons/open";
import Closed from "./icons/closed";
import Basket from "./icons/basket";
import Add from "./icons/add";
import Remove from "./icons/remove";
import Close from "./icons/close";
import CheckMark from "./icons/checkmark";
import QuestionMark from "./icons/questionmark";
import Cookies from "./icons/cookies";
import Right from "./icons/right";
import MenuBook from "./icons/menuBook";
import Search from "./icons/search";
import ChevronRight from "./icons/chevronRight";
import { ChevronLeft } from "./icons/chevronLeft";
import { ChevronDown } from "./icons/chevronDown";
import ChevronUp from "./icons/chevronUp";
import Save from "./icons/save";
import MenuFill from "./icons/menu";
import Image from "./icons/image";
import Visa from "./icons/visa";
import Amex from "./icons/amex";
import Mastercard from "./icons/mastercard";
import Maestro from "./icons/maestro";
import JCB from "./icons/jcb";
import PayPal from "./icons/paypal";
import ApplePay from "./icons/applePay";
import GooglePay from "./icons/googlePay";
import Tripadvisor from "./icons/tripadvisor";
import TooGoodToGo from "./icons/tooGoodToGo";
import Yelp from "./icons/yelp";

interface WithIconType {
	type: IconType;
}

export const Icon: React.FC<WithIconType & WithClassNames> = (props) => {
	const { type, ...iconProps } = props;
	switch (type) {
		case IconType.EXCLAMATION_FILL:
			return <Exclamation.Fill {...iconProps} />;
		case IconType.EXCLAMATION_STROKE:
			return <Exclamation.Stroke {...iconProps} />;
		case IconType.TRASH_FILL:
			return <Trash.Fill {...iconProps} />;
		case IconType.TRASH_STROKE:
			return <Trash.Stroke {...iconProps} />;
		case IconType.LOCATION:
			return <Location {...iconProps} />;
		case IconType.PHONE:
			return <Phone {...iconProps} />;
		case IconType.MAIL:
			return <Mail {...iconProps} />;
		case IconType.OPEN:
			return <Open {...iconProps} />;
		case IconType.CLOSED:
			return <Closed {...iconProps} />;
		case IconType.BASKET:
			return <Basket {...iconProps} />;
		case IconType.ADD:
			return <Add {...iconProps} />;
		case IconType.REMOVE:
			return <Remove {...iconProps} />;
		case IconType.CLOSE:
			return <Close {...iconProps} />;
		case IconType.CHECK_MARK:
			return <CheckMark {...iconProps} />;
		case IconType.QUESTION_MARK:
			return <QuestionMark {...iconProps} />;
		case IconType.COOKIES:
			return <Cookies {...iconProps} />;
		case IconType.RIGHT:
			return <Right {...iconProps} />;
		case IconType.MENU_BOOK:
			return <MenuBook {...iconProps} />;
		case IconType.MENU_FILL:
			return <MenuFill {...iconProps} />;
		case IconType.SEARCH:
			return <Search {...iconProps} />;
		case IconType.CHEVRON_RIGHT:
			return <ChevronRight {...iconProps} />;
		case IconType.CHEVRON_LEFT:
			return <ChevronLeft {...iconProps} />;
		case IconType.CHEVRON_DOWN:
			return <ChevronDown {...iconProps} />;
		case IconType.CHEVRON_UP:
			return <ChevronUp {...iconProps} />;
		case IconType.SAVE:
			return <Save {...iconProps} />;
		case IconType.IMAGE:
			return <Image {...iconProps} />;
		case IconType.TOOOGOODTOGO:
			return <TooGoodToGo {...iconProps} />;
		case IconType.TRIPADVISOR:
			return <Tripadvisor {...iconProps} />;
		case IconType.YELP:
			return <Yelp {...iconProps} />;
		case IconType.VISA:
			return <Visa {...iconProps} />;
		case IconType.APPLE_PAY:
			return <ApplePay {...iconProps} />;
		case IconType.GOOGLE_PAY:
			return <GooglePay {...iconProps} />;
		case IconType.PAYPAL:
			return <PayPal {...iconProps} />;
		case IconType.MASTERCARD:
			return <Mastercard {...iconProps} />;
		case IconType.MAESTRO:
			return <Maestro {...iconProps} />;
		case IconType.AMEX:
			return <Amex {...iconProps} />;
		case IconType.JCB:
			return <JCB {...iconProps} />;
		default:
			return <DefaultIcon {...iconProps} />;
	}
};
